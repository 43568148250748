import './styles.css'

const ButtonShare = ({ onClick }) => (
    <button className="button-share" onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18">
            <path d="M14.502 10.9999C13.9345 11.0001 13.3757 11.1383 12.8736 11.4026C12.3715 11.6669 11.9412 12.0494 11.6199 12.5171L6.74519 10.316C7.08705 9.47647 7.08973 8.53706 6.75269 7.69562L11.6229 5.48401C12.0978 6.17302 12.804 6.66899 13.6132 6.88193C14.4225 7.09486 15.2813 7.01067 16.0338 6.64461C16.7864 6.27856 17.3827 5.65489 17.7148 4.88676C18.0469 4.11863 18.0926 3.25692 17.8437 2.45797C17.5948 1.65901 17.0677 0.975737 16.3582 0.5321C15.6486 0.0884643 14.8036 -0.0861429 13.9763 0.0399559C13.149 0.166055 12.3943 0.584506 11.8492 1.21939C11.304 1.85428 11.0045 2.66355 11.0049 3.50038C11.0049 3.69943 11.0217 3.89812 11.0552 4.09434L5.88724 6.4402C5.38904 5.97555 4.76607 5.6664 4.09472 5.55065C3.42337 5.4349 2.73284 5.51758 2.1078 5.78857C1.48277 6.05956 0.950399 6.50706 0.575989 7.0762C0.201579 7.64534 0.00140191 8.31138 7.33665e-06 8.99263C-0.00138724 9.67388 0.196061 10.3407 0.568138 10.9114C0.940215 11.4821 1.47075 11.9317 2.09467 12.2053C2.71859 12.4788 3.40878 12.5643 4.08059 12.4513C4.75241 12.3383 5.37664 12.0317 5.87674 11.5691L11.0514 13.9067C10.9298 14.614 11.0285 15.3415 11.3342 15.9908C11.6399 16.6401 12.1377 17.1797 12.7603 17.5366C13.3829 17.8934 14.1002 18.0503 14.8149 17.9859C15.5297 17.9215 16.2073 17.6389 16.7561 17.1765C17.3049 16.7141 17.6983 16.0942 17.883 15.4007C18.0677 14.7073 18.0347 13.9738 17.7886 13.2997C17.5425 12.6256 17.0952 12.0434 16.5071 11.632C15.9191 11.2207 15.2196 11 14.502 10.9999Z" />
        </svg>
    </button>
)

export default ButtonShare
