import { StarWrapper } from '../RangButton/star-wrapper'
import './styles.css'

const HistoryItem = ({ rang, stars, price, date, time, action }) => {
    return (
        <div className="history__item">
            <div className="history__item-logo">
                <img
                    src={`/img/rang_${rang.toLowerCase()}.png`}
                    alt="historyimg"
                />
                <div className="history__starswrapper">
                    <StarWrapper starAmmouth={stars} />
                </div>
            </div>

            <p className="history__item-price">{`${price} BOOST`}</p>
            <p className="history__item-action">{action}</p>

            <div className="history__item-date">
                {time} <br /> {date}
            </div>
        </div>
    )
}

export default HistoryItem
