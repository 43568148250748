export default (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
        <path
            d="M19.375 5C11.4367 5 5 11.4367 5 19.375C5 27.3133 11.4367 33.75 19.375 33.75C27.3133 33.75 33.75 27.3133 33.75 19.375C33.75 11.4367 27.3133 5 19.375 5Z"
            stroke={color}
            strokeWidth="2.5"
            strokeMiterlimit="10"
        />
        <path
            d="M17.1875 17.1875H19.6875V26.25"
            stroke={color}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.25 26.5625H23.125"
            stroke={color}
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M19.375 10.1562C18.9733 10.1562 18.5805 10.2754 18.2465 10.4986C17.9125 10.7218 17.6521 11.039 17.4984 11.4102C17.3446 11.7813 17.3044 12.1898 17.3828 12.5838C17.4612 12.9778 17.6546 13.3397 17.9387 13.6238C18.2228 13.9079 18.5847 14.1013 18.9787 14.1797C19.3727 14.2581 19.7812 14.2179 20.1523 14.0641C20.5235 13.9104 20.8407 13.65 21.0639 13.316C21.2871 12.982 21.4063 12.5892 21.4063 12.1875C21.4063 11.6488 21.1922 11.1321 20.8113 10.7512C20.4304 10.3703 19.9137 10.1562 19.375 10.1562Z"
            fill={color}
        />
    </svg>
)
