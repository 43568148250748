import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import WalletReplenishCard from '../../components/WalletReplenishCard'
import ProfileBar from '../../components/ProfileBar'
import { useGet } from '../../api/request'
import './styles.css'
import './../Wallet/walletGhost.css'

const WalletReplenish = () => {
    const user = useSelector((state) => state.auth.user)
    const [walletAddress, setWalletAddress] = useState('')
    const [qrCode, setQrCode] = useState('')
    const [course, setCourse] = useState(0)
    const navigate = useNavigate()
    const get = useGet()

    const fetchCourse = () => {
        get('wallet/course')
            .then((course) => {
                setCourse(Number(course.data.course.value))
            })
            .catch(console.log)
    }

    useEffect(() => {
        if (!user.decimal_wallet) {
            navigate('/wallet')
            return
        }
        fetchRequisites()
        fetchCourse()
    }, [])

    const fetchRequisites = () => {
        get('wallet/requisites')
            .then((response) => {
                setWalletAddress(response.data.wallet_address.value)
                setQrCode(response.data.qrCode.value)
            })
            .catch(console.log)
    }

    return (
        <div className="wallet-replenish">
            <Header text="Кошелек" />
            <div className="wallet-replenish__wrap-profile-bar">
                <ProfileBar />
            </div>
            <WalletReplenishCard
                background="url('/img/wallet_replenish_cryg_bg_1.png') 12px center/92px no-repeat, url('/img/wallet_replenish_cryg_bg_2.png') right 10px/107px no-repeat, linear-gradient(302.16deg, #011C50 -15.95%, #5E58D0 146.28%)"
                url={qrCode}
                address={walletAddress}
            />
            <div className="wallet-replenish__wrap-rate">
                <div className="wallet-replenish__rate">
                    {course ? (
                        <>1 CRYG = {1 / course} DEL</>
                    ) : (
                        <>
                            <div className="wallet__ghost"></div>
                        </>
                    )}
                </div>
            </div>
            <div className="wallet-replenish__wrap-info">
                <p className="wallet-replenish__info">
                    На данном этапе, чтобы приобрести токен GRYG внесите DEL по
                    указаному адресу.
                    <br />
                    Ваш баланс CRYG будет автоматически пополнен по курсу
                    указанному выше.
                </p>
            </div>
        </div>
    )
}

export default WalletReplenish
