import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentCryptocurrency: 'CRYG',
}

const currentCryptocurrencySlice = createSlice({
    name: 'currentCryptocurrency',
    initialState,
    reducers: {
        setCurrentCryptocurrencyRedux: (state, action) => {
            return {
                ...state,
                currentCryptocurrency: action.payload,
            }
        },
    },
})

export const {
    reducer: currentCryptocurrencyReducer,
    actions: { setCurrentCryptocurrencyRedux },
} = currentCryptocurrencySlice
