import React, { useEffect, useState } from 'react'
import './styles.css'
import b1 from './button1'
import b2 from './button2'
import WalletStyler from '../WalletStyler/WalletStyler'
import ButtonFilledMin from '../ButtonFilledMin'
import { useDebounce } from '../../Hooks/Hooks'
import { useDispatch, useSelector } from 'react-redux'

import WalletReplenishCard from '../WalletReplenishCard'
import { setCurrentCryptocurrencyRedux } from '../../redux/currentCryptocurrency'
import Loader from '../Loader'

import { setDecimalWallet } from '../../redux/auth'
import { useAlert } from '../AlertNotification'

const WalletCard = ({
    title,
    text,
    subtext,
    onClickBtn1,
    handleModalWallet,
    decimalWallet,
    disableBtns,
    get,
    post,
    course,
    showReplenish,
    showWithdrawal,
    setShowWithdrawal,
}) => {
    const user = useSelector((state) => state?.auth?.user)
    const [adress, setAdress] = useState('')
    const [sum, setSum] = useState('')
    const [crygToDelCourse, setCrygToDelCourse] = useState(0)
    const alert = useAlert()
    const { debounce } = useDebounce(sum, 300)
    const dispatch = useDispatch()
    const amount = useSelector(
        (state) => state?.auth?.user?.wallet?.balance?.ammouth
    )
    const [styleState, setStyleState] = useState('design1')

    const { currentCryptocurrency } = useSelector(
        (state) => state.currentCryptocurrency
    )
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentCryptocurrency === 'DEL') {
            setStyleState('design2')
        } else {
            setStyleState('design1')
        }
    }, [currentCryptocurrency])

    const CreateDecimalWallet = () => {
        setLoading(true)
        post('users/decimal_wallet')
            .then((res) => {
                dispatch(setDecimalWallet(res.data.user.decimal_wallet))
                alert({
                    status: 'success',
                    title: 'Создание кошелька',
                    message: 'Кошелек зарегестрирван успешно!',
                })
            })
            .catch((e) => {
                alert({
                    status: 'error',
                    title: 'Создание кошелька',
                    message: 'Произошла ошибка',
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getCrygToDell(debounce)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce])

    const getCrygToDell = (cryg) => {
        if (+cryg > 0) {
            get(`./wallet/wellDell?amount=${cryg}`).then((data) => {
                if (data?.data?.course) {
                    setCrygToDelCourse(data.data.course)
                }
            })
        } else {
            setCrygToDelCourse(0)
        }
    }

    const getConclusion = () => {
        //---------------------------------!!!!
        console.log('AMAUNT')
        post('./wallet/conclusion', {
            amount: sum,
            walletToUrl: adress,
        })
            .then((data) => {
                if (data.status === 'success') {
                    alert({ status: 'success', title: data?.message })
                    setSum(0)
                    setShowWithdrawal(false)
                }
            })
            .catch((e) => {
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message: e.res?.data?.message,
                })
                console.log('ERROR', e.res?.data?.message)
            })
            .finally((data) => {
                console.log('FINALLY', data)
            })
    }

    const handleWithdrawal = () => {
        setShowWithdrawal(true)
        if (styleState !== 'design2') setStyleState('design1__action')
    }

    return Boolean(loading) ? (
        <Loader />
    ) : (
        <div
            className="wallet-card"
            style={
                currentCryptocurrency === 'DEL'
                    ? {
                          background:
                              'linear-gradient(136deg, rgba(1, 252, 173, 0.80) 0%, rgba(1, 252, 173, 0.80) 0.01%, rgba(62, 116, 242, 0.80) 36.98%, rgba(108, 75, 246, 0.80) 71.35%, rgba(137, 49, 245, 0.80) 100%)',
                      }
                    : currentCryptocurrency === 'CRYG'
                      ? {
                            background:
                                'linear-gradient(315deg, #011C50 0%, #5E58D0 100%)',
                        }
                      : {}
            }
        >
            <WalletStyler
                state={currentCryptocurrency}
                showReplenish={showReplenish}
                showWithdrawal={showWithdrawal}
                styleState={styleState}
            >
                {!(showWithdrawal | showReplenish) && (
                    <>
                        <p
                            className="wallet-card__title"
                            style={
                                currentCryptocurrency === 'DEL'
                                    ? { marginTop: '30px' }
                                    : {}
                            }
                        >
                            {title}
                        </p>
                        <p className="wallet-card__text">{text}</p>
                        <p className="wallet-card__subtext">{subtext}</p>
                        <div className="wallet-card__wrap-btn">
                            {currentCryptocurrency === 'CRYG' ? (
                                <>
                                    <button
                                        className="wallet-card__btn1"
                                        onClick={onClickBtn1}
                                        style={
                                            disableBtns ? { opacity: 0.3 } : {}
                                        }
                                        disabled={disableBtns}
                                    >
                                        <p className="wallet-card__btn1-text">
                                            Пополнить
                                        </p>
                                    </button>
                                    <button
                                        className="wallet-card__btn2"
                                        onClick={handleWithdrawal}
                                        style={
                                            disableBtns ? { opacity: 0.3 } : {}
                                        }
                                        disabled={disableBtns}
                                    >
                                        <p className="wallet-card__btn2-text">
                                            Вывести
                                        </p>
                                    </button>
                                    <button
                                        className="wallet-card__btn3"
                                        onClick={handleModalWallet}
                                    >
                                        <p className="wallet-card__btn3-text">
                                            Изменить
                                        </p>
                                    </button>
                                </>
                            ) : (currentCryptocurrency === 'DEL' &&
                                  decimalWallet) ||
                              (currentCryptocurrency === 'CAPSULA' &&
                                  decimalWallet) ? (
                                <>
                                    <button
                                        className="wallet-card__btn1"
                                        onClick={onClickBtn1}
                                        style={
                                            disableBtns ? { opacity: 0.3 } : {}
                                        }
                                        disabled={disableBtns}
                                    >
                                        <p className="wallet-card__btn1-text">
                                            Пополнить
                                        </p>
                                    </button>
                                    <button
                                        className="wallet-card__btn2"
                                        onClick={handleWithdrawal}
                                        style={
                                            disableBtns ? { opacity: 0.3 } : {}
                                        }
                                        disabled={disableBtns}
                                    >
                                        <p className="wallet-card__btn2-text">
                                            Вывести
                                        </p>
                                    </button>
                                    <button
                                        className="wallet-card__btn3"
                                        onClick={handleModalWallet}
                                    >
                                        <p className="wallet-card__btn3-text">
                                            Изменить
                                        </p>
                                    </button>
                                </>
                            ) : (
                                <button
                                    className="wallet-card__btn2"
                                    style={disableBtns ? { opacity: 0.3 } : {}}
                                    disabled={disableBtns}
                                >
                                    <p
                                        className="wallet-card__btn2-text"
                                        onClick={CreateDecimalWallet}
                                    >
                                        Создать кошелек
                                    </p>
                                </button>
                            )}
                        </div>
                    </>
                )}

                {showWithdrawal && (
                    <>
                        <p className="title">Вывести</p>
                        <div className="input-wrap">
                            <input
                                type={'text'}
                                className="modal-input"
                                placeholder="Адрес"
                                value={adress}
                                onChange={(event) =>
                                    setAdress(event.target.value)
                                }
                            />
                            {/* <img src={process.env.PUBLIC_URL+'/img/foto.png'} className='input-img' style={{top:'33%',transform:'translateY(-50%)'}}/> */}
                        </div>
                        <div className="input-wrap">
                            <input
                                type={'number'}
                                className="modal-input"
                                placeholder="Сумма"
                                value={sum}
                                onChange={(event) => setSum(event.target.value)}
                            />
                            <p
                                className="input-img"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 12,
                                    zIndex: 10,
                                    textDecorationLine: 'underline',
                                    color: 'rgba(255, 255, 255, 0.5)',
                                }}
                                onClick={() => setSum(Math.round(amount))}
                            >
                                Макс
                            </p>
                        </div>
                        <div className="withdrawal-course">
                            {currentCryptocurrency === 'CRYG' ||
                            currentCryptocurrency === 'CRYG__action'
                                ? `1 CRYG = ${1 / course} DEL`
                                : currentCryptocurrency === 'CAPSULA'
                                  ? '1 CAPSULA = 100 CRYG'
                                  : `1 DEL = 10 CRYG`}
                        </div>
                        <div className="buttons-wrap">
                            <ButtonFilledMin
                                text={'Отмена'}
                                onClick={() => {
                                    setShowWithdrawal(false)
                                    setSum('')
                                    if (currentCryptocurrency !== 'DEL') {
                                        setStyleState('design1')
                                    }
                                }}
                                bg="1"
                            />
                            <ButtonFilledMin
                                text={`Вывести ${
                                    sum > 0
                                        ? crygToDelCourse.toFixed(2) + ' DEL'
                                        : ''
                                }`}
                                onClick={getConclusion}
                                disabled={!(+sum > 0 && adress.length > 5)}
                                bg="2"
                            />
                        </div>
                    </>
                )}

                {showReplenish && (
                    <WalletReplenishCard
                        address={
                            decimalWallet?.address
                                ? decimalWallet.address
                                : decimalWallet
                        }
                    />
                )}
            </WalletStyler>
        </div>
    )
}

export default WalletCard
