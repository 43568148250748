import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../components/Header'
import Rang from '../../components/Rang'
import { BoosterStats } from '../../components/Stats'
import { v4 } from 'uuid'
import './styles.css'
import 'swiper/css'
import 'swiper/css/virtual'
import 'swiper/css/pagination'
import { Pagination, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import Modal from '../../components/Modal/modal'
import ButtonFilled from '../../components/ButtonFilled'
import { post, useGet, usePut } from '../../api/request'
import Loader from '../../components/Loader'
import { useAlert } from '../../components/AlertNotification'
import { useDispatch, useSelector } from 'react-redux'
import {
    chooseBoosterOrderRedux,
    chooseBoosterRedux,
    confirmActiveBoosterRedux,
    confirmBuyFarm,
    confirmMainRedux,
    confirmUpdateBoosterRedux,
    selectBoosterRedux,
    selectedForBuyBoosterRedux,
    setAllBoosterRedux,
    setReduxCollection,
    setReduxInfoPower,
    setReduxMain,
    setTokenBalanceRedux,
} from '../../redux/booster'
import { useNavigate } from 'react-router-dom'
import { formatClearMoney } from '../../helpers'

const Booster = ({ number = -1, n = false }) => {
    const [loading, setLoading] = useState(false)
    const [extraBstrs, setExtraBstrs] = useState([])
    const dispatch = useDispatch()
    const { collection, allBooster, selectedForBuyBooster, slideNumber } =
        useSelector((state) => state.booster)
    const farm = useSelector((state) => state.booster.boosterFarms[number - 1])
    const get = useGet()
    const put = usePut()
    const alert = useAlert()
    const navigate = useNavigate()

    useEffect(() => {
        const arr =
            farm?.booster_additional?.map((el) => (
                <Rang
                    key={v4()}
                    {...{
                        rangS: el?.booster?.rang?.split('')[0].toLowerCase(),
                        stars: el?.booster?.star,
                        small: true,
                        onClick: () => {
                            el?.booster
                                ? (() => {
                                      dispatch(selectBoosterRedux(el.booster))
                                      dispatch(chooseBoosterOrderRedux(el.id))
                                  })()
                                : dispatch(
                                      chooseBoosterRedux({
                                          val: true,
                                          order: el?.id,
                                      })
                                  )
                        },
                    }}
                />
            )) || []
        setExtraBstrs(arr)
    }, [])

    const baseLayout = (
        <>
            <div className="booster__farm-header">Ферма {number}</div>
            <div className="staking__rang-wrap">
                <div className="staking__rang">
                    {(farm?.booster_main || true) && (
                        <Rang
                            {...{
                                rangS: farm?.booster_main?.rang
                                    ?.split(' ')[0]
                                    .toLowerCase(),
                                stars: farm?.booster_main?.star,
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                !n && farm?.booster_main
                                    ? dispatch(
                                          selectBoosterRedux(farm?.booster_main)
                                      )
                                    : dispatch(
                                          chooseBoosterRedux({
                                              val: true,
                                              order: 0,
                                          })
                                      )
                            }
                            cursor="pointer"
                        />
                    )}
                    <div className="stats__wrap">
                        <button
                            type="button"
                            onClick={() => navigate(`/staking/${slideNumber}`)}
                            className="stats__value-wrap"
                        >
                            <p className="stats__price-value">
                                {Math.floor(farm?.balance) || 0} CRYG
                            </p>
                        </button>
                    </div>
                </div>
                <BoosterStats
                    main={farm?.main_booster_power}
                    collection={farm?.additional_booster_power}
                    powerAll={farm?.general_power}
                    percent={formatClearMoney(farm?.percent)}
                />
            </div>

            <div className="booster__slider">
                <div className="slide__block">
                    {}
                    {extraBstrs.length ? (
                        extraBstrs
                    ) : (
                        <>
                            <Rang small={true} />
                            <Rang small={true} />
                            <Rang small={true} />
                            <Rang small={true} />
                            <Rang small={true} />
                            <Rang small={true} />
                        </>
                    )}
                </div>
                <ButtonFilled
                    text={'Просмотр коллекции'}
                    onClick={() => navigate('/collection')}
                />
            </div>
        </>
    )

    return (
        <>
            <div className="booster">{baseLayout}</div>
        </>
    )
}

export default Booster
