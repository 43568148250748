import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import getEnvVars from '../../api/environment'
import './styles.css'

const { uploadUrl } = getEnvVars()

const ReferralsProfileBar = () => {
    const user = useSelector((state) => state.auth.user)
    return (
        <div className="referrals-profile-bar">
            <div className="referrals-profile-bar__left">
                <div className="referrals-profile-bar__left-shine" />
                <svg
                    className="referrals-profile-bar__left-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 136 137"
                    fill="none"
                >
                    <g filter="url(#filter0_d_57_1216)">
                        <path
                            d="M123.276 35.7133C123.957 35.3585 124.799 35.6256 125.134 36.3165C131.961 50.3712 133.457 66.4755 129.309 81.5885C125.038 97.1503 115.098 110.515 101.474 119.012C87.8507 127.509 71.5476 130.512 55.8227 127.421C40.0979 124.331 26.1094 115.374 16.6527 102.341C7.19592 89.3077 2.96744 73.1582 4.8122 57.1195C6.65695 41.0807 14.4391 26.3341 26.6034 15.8264C38.7678 5.31868 54.4185 -0.176191 70.428 0.439819C85.9801 1.03823 100.758 7.36048 111.979 18.1826C112.528 18.7117 112.527 19.5859 111.991 20.1272C111.448 20.6745 110.565 20.6737 110.009 20.1395C99.2827 9.8222 85.1715 3.79568 70.3225 3.22432C55.0089 2.63509 40.0385 7.89112 28.4028 17.9421C16.7672 27.9931 9.32336 42.0987 7.55879 57.4403C5.79422 72.7818 9.8389 88.2293 18.8846 100.696C27.9303 113.163 41.3107 121.73 56.3521 124.686C71.3934 127.643 86.9879 124.77 100.019 116.642C113.05 108.515 122.559 95.7311 126.644 80.8457C130.608 66.4035 129.183 51.015 122.67 37.5803C122.338 36.8953 122.601 36.065 123.276 35.7133Z"
                            fill="url(#paint0_linear_57_1216)"
                        />
                    </g>
                    <g filter="url(#filter1_d_57_1216)">
                        <path
                            d="M120.141 30.2857C120.784 29.8637 121.649 30.0453 122.052 30.7002C122.204 30.947 122.354 31.1948 122.503 31.4437C122.893 32.0973 122.663 32.9397 122.004 33.3204C121.338 33.7047 120.488 33.4727 120.093 32.8134C119.971 32.6097 119.848 32.4067 119.724 32.2044C119.325 31.5558 119.504 30.7034 120.141 30.2857Z"
                            fill="#42A4FF"
                        />
                    </g>
                    <g filter="url(#filter2_d_57_1216)">
                        <path
                            d="M116.955 25.8013C117.559 25.3238 118.438 25.4289 118.897 26.0465C119.103 26.3231 119.307 26.6013 119.508 26.8812C119.952 27.4994 119.795 28.3583 119.171 28.7941C118.54 29.2344 117.672 29.0756 117.222 28.4516C117.053 28.2165 116.882 27.9826 116.709 27.75C116.255 27.1386 116.358 26.2736 116.955 25.8013Z"
                            fill="#42A4FF"
                        />
                    </g>
                    <g filter="url(#filter3_d_57_1216)">
                        <path
                            d="M113.366 21.5882C113.926 21.0587 114.811 21.0857 115.323 21.6608C115.616 21.9896 115.906 22.3213 116.192 22.6561C116.687 23.2351 116.603 24.1045 116.017 24.5914C115.425 25.0836 114.546 24.9981 114.045 24.4135C113.797 24.124 113.546 23.837 113.293 23.5522C112.786 22.983 112.812 22.1119 113.366 21.5882Z"
                            fill="#42A4FF"
                        />
                    </g>
                    <path
                        d="M126.328 64.5C126.328 97.011 100.214 123.366 68 123.366C35.7861 123.366 9.6716 97.011 9.6716 64.5C9.6716 31.9889 35.7861 5.63354 68 5.63354C100.214 5.63354 126.328 31.9889 126.328 64.5Z"
                        fill="url(#pattern0)"
                    />
                    <defs>
                        <filter
                            id="filter0_d_57_1216"
                            x="0.388931"
                            y="0.393066"
                            width="135.222"
                            height="136.214"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_57_1216"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_57_1216"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter1_d_57_1216"
                            x="115.512"
                            y="30.0586"
                            width="11.1811"
                            height="11.4478"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_57_1216"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_57_1216"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter2_d_57_1216"
                            x="112.427"
                            y="25.5027"
                            width="11.3345"
                            height="11.5413"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_57_1216"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_57_1216"
                                result="shape"
                            />
                        </filter>
                        <filter
                            id="filter3_d_57_1216"
                            x="108.931"
                            y="21.2095"
                            width="11.5849"
                            height="11.7029"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_57_1216"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_57_1216"
                                result="shape"
                            />
                        </filter>
                        <pattern
                            id="pattern0"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use
                                href="#image0_57_1216"
                                transform="translate(-0.25677) scale(0.000591227 0.000585823)"
                            />
                        </pattern>
                        <linearGradient
                            id="paint0_linear_57_1216"
                            x1="135.37"
                            y1="64.0821"
                            x2="0.902626"
                            y2="64.9008"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0.000564953" stopColor="#4596F7" />
                            <stop offset="1" stopColor="#031381" />
                        </linearGradient>
                        <image
                            preserveAspectRatio="xMidYMid slice"
                            id="image0_57_1216"
                            width="2560"
                            height="1707"
                            href={user.avatar || '/img/avatar.jpg'}
                        />
                    </defs>
                </svg>
            </div>
            <div className="referrals-profile-bar__right">
                <p className="referrals-profile-bar__name">
                    {user.name || '-'} {user.surname || '-'}
                </p>
                <p className="referrals-profile-bar__tag">#{user.login}</p>
                <div className="referrals-profile-bar__line" />
                <p className="referrals-profile-bar__name">Аплайнер:</p>
                <p className="referrals-profile-bar__tag" style={{ margin: 0 }}>
                    {user?.appleiner?.login ? `#${user.appleiner.login}` : '-'}
                </p>
            </div>
        </div>
    )
}

export default ReferralsProfileBar
