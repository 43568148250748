import React from 'react'
import './styles.css'
import { v4 as uuid } from 'uuid'

const List = ({ data }) => {
    if (!data) return null

    return (
        <div className="list">
            {data.map(({ left, center, right }, index) => (
                <div
                    key={uuid()}
                    className={`list__item${
                        index == data.length - 1 ? ' list__item_last' : ''
                    }`}
                >
                    <p className="list__item-left">{left || ''}</p>
                    <p className="list__item-center">{center || ''}</p>
                    <p className="list__item-right">
                        <div className="history__item-date">
                            {right?.time || '-'} <br /> {right?.date || ''}
                        </div>
                    </p>
                </div>
            ))}
        </div>
    )
}

export default List
