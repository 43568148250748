import React from 'react'
import './styles.css'
import ButtonFilledMin from '../ButtonFilledMin'
import { v4 as uuid } from 'uuid'

const Alert = ({ title, text, onClose, btns = {} }) => {
    const showBtns = () => {
        if (btns.length) {
            return btns.map((btn) => (
                <button key={uuid()} onClick={btn.cb}>
                    {btn.name}
                </button>
            ))
        }
    }
    return (
        <div className="alert">
            <div className="alert__wrap">
                <button className="alert__close" onClick={onClose}>
                    <svg
                        className="alert__close-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                    >
                        <path
                            d="M7.00001 5.90672L2.17858 1.08529L2.17868 1.08519L2.17378 1.0807C2.02676 0.946164 1.83351 0.873541 1.63428 0.877956C1.43505 0.88237 1.24521 0.963482 1.1043 1.10439C0.963389 1.2453 0.882278 1.43515 0.877863 1.63438C0.873449 1.8336 0.946071 2.02686 1.0806 2.17387L1.0805 2.17397L1.08517 2.17864L5.90416 7.00008L1.08516 11.8191C1.01114 11.89 0.951939 11.9748 0.910994 12.0688L1.0483 12.1286L0.910994 12.0688C0.869799 12.1634 0.847919 12.2652 0.846641 12.3683C0.845364 12.4714 0.864716 12.5738 0.903557 12.6693C0.942399 12.7648 0.999948 12.8517 1.07282 12.9246C1.14569 12.9976 1.23242 13.0553 1.3279 13.0943L1.38459 12.9554L1.3279 13.0943C1.42338 13.1332 1.52569 13.1527 1.62882 13.1516C1.73194 13.1505 1.8338 13.1287 1.9284 13.0877C2.02245 13.0469 2.10745 12.9877 2.17845 12.9138L6.99999 8.09471L11.8214 12.9162L11.8213 12.9163L11.8262 12.9208C11.9733 13.0553 12.1665 13.1279 12.3657 13.1235C12.565 13.1191 12.7548 13.038 12.8957 12.8971C13.0366 12.7562 13.1177 12.5663 13.1222 12.3671C13.1266 12.1679 13.054 11.9746 12.9194 11.8276L12.9195 11.8275L12.9148 11.8228L8.09338 7.00134L12.9136 2.17987C12.9876 2.10899 13.0468 2.0241 13.0878 1.93014C13.129 1.8356 13.1509 1.73377 13.1521 1.63065C13.1534 1.52752 13.1341 1.42519 13.0952 1.32965C13.0564 1.23411 12.9988 1.14731 12.926 1.07433L12.8198 1.18032L12.926 1.07433C12.8531 1.00135 12.7664 0.943681 12.6709 0.904703L12.6143 1.04338L12.6709 0.904703C12.5754 0.865726 12.4731 0.84623 12.37 0.847361C12.2668 0.848492 12.165 0.870227 12.0704 0.911289C11.9758 0.952351 11.8903 1.01191 11.8191 1.08647L11.7933 1.11346L7.00001 5.90672Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.3"
                        />
                    </svg>
                </button>
                <div className="alert__inner">
                    <p className="alert__title">{title}</p>
                    <p className="alert__text">{text}</p>
                </div>
            </div>
            <div className="alert__btns-block">{showBtns()}</div>
        </div>
    )
}

export default Alert
