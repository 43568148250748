import { formatNumber as accoountingFormatNumber, toFixed } from 'accounting'
import moment from 'moment/moment'
import 'moment/locale/ru'

export const shortenAddress = (text) => {
    console.log(text, 'text')
    if (text.length <= 18) {
        return text
    }
    return `${text.slice(0, 12)}......${text.slice(-6)}`
}

export const shortenEmail = (text) => {
    const [left, right] = text.split('@')
    return `${left.slice(0, 2)}*****@${right}`
}

export const formatMoney = (value, decimals = 1, prefix = '') =>
    value && value !== ''
        ? `${prefix}${accoountingFormatNumber(value, decimals, '.', ',')}`
        : '0'

export const formatClearMoney = (value, decimals = 2) =>
    value && value !== '' ? `${toFixed(value, decimals)}` : '0.00'

export const formatTime = (str) => {
    if (str) {
        const time = moment(str).add(-3, 'hours').format('kk:mm')
        const date = moment(str).format('DD:MM:YY')
        console.log(time, date)
        return {
            time,
            date,
        }
    }
    return {}
}
