import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setTokens, setUser } from '../../redux/auth'
import TitleAuth from '../../components/TitleAuth'
import Input from '../../components/Input'
import CheckBox from '../../components/CheckBox'
import ButtonFilled from '../../components/ButtonFilled'
import LinkText from '../../components/LinkText'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import { post } from '../../api/request'
import './styles.css'
import { useAlert } from '../../components/AlertNotification'

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [login, setLogin] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isViewPassword, setIsViewPassword] = useState(false)
    const [repeatPassword, setRepeatPassword] = useState('')
    const [isViewRepeatPassword, setIsViewRepeatPassword] = useState(false)
    const [userAgreement, setUserAgreement] = useState(false)
    const [errors, setErrors] = useState({
        login: '',
        email: '',
        password: '',
        repeatPassword: '',
    })
    const { key } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const alert = useAlert()

    if (key) console.log('KEY ', key)

    const validation = () => {
        let valid = true
        if (!login) {
            setErrors((prev) => ({
                ...prev,
                login: 'Неверный логин',
            }))
            valid = false
        }
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setErrors((prev) => ({
                ...prev,
                email: 'Неверно указан адрес электронной почты',
            }))
            valid = false
        }
        if (password !== repeatPassword) {
            setErrors((prev) => ({
                ...prev,
                password: true,
                repeatPassword: 'Пароли не совпадают',
            }))
            valid = false
        }
        if (!password) {
            setErrors((prev) => ({
                ...prev,
                password: 'Неверный пароль',
            }))
            valid = false
        }
        if (!repeatPassword) {
            setErrors((prev) => ({
                ...prev,
                repeatPassword: 'Неверный пароль',
            }))
            valid = false
        }
        return valid
    }

    const handleChangeLogin = (evt) => {
        setLogin(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            login: '',
        }))
    }

    const handleChangeEmail = (evt) => {
        setEmail(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            email: '',
        }))
    }

    const handleChangePassword = (evt) => {
        setPassword(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            password: '',
            repeatPassword: '',
        }))
    }

    const handleChangeRepeatPassword = (evt) => {
        setRepeatPassword(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            password:
                prev.password && typeof prev.password != 'string'
                    ? ''
                    : prev.password,
            repeatPassword: '',
        }))
    }

    const submit = () => {
        if (!validation()) return
        setIsLoading(true)

        const requestData = {
            email,
            login,
            password,
            repeat_password: repeatPassword,
        }

        //navigate(`/auth/signup_verification-message${key?`/${key}`:''}`);

        post(`auth/singUp${key ? `/${key}` : ''}`, requestData)
            .then((response) => {
                // dispatch(setTokens({
                // 	accessToken: response.data.user.access_token,
                // 	refreshToken: response.data.user.refresh_token
                // }));
                // dispatch(setUser(response.data.user));

                //FROM_REF SAVE
                key && localStorage.setItem('from_ref', key)
                navigate(
                    `/auth/signup_verification-message${key ? `/${key}` : ''}`
                )
            })
            .catch((e) => {
                console.log(e)
                if (e.response?.data?.message) {
                    alert({
                        status: 'error',
                        title: 'Ошибка !',
                        message: e.response?.data?.message,
                    })
                }
                setErrors((prev) => ({
                    ...prev,
                    ...(e.response.data?.validation || {}),
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="sign-up">
            <div className="sign-up__wrap-title">
                <TitleAuth text="Регистрация" />
            </div>
            <div className="sign-up__wrap-input">
                <Input
                    value={login}
                    onChange={handleChangeLogin}
                    leftIcon="profile"
                    placeholder="Логин"
                    error={errors.login}
                />
            </div>
            <div className="sign-up__wrap-input">
                <Input
                    value={email}
                    onChange={handleChangeEmail}
                    leftIcon="mail"
                    placeholder="Email"
                    error={errors.email}
                    type="email"
                />
            </div>
            <div className="sign-up__wrap-input">
                <Input
                    value={password}
                    onChange={handleChangePassword}
                    leftIcon="lock"
                    rightIcon={isViewPassword ? 'eye' : 'eye-lock'}
                    onClickRight={() => setIsViewPassword((prev) => !prev)}
                    type={isViewPassword ? 'text' : 'password'}
                    placeholder="Пароль"
                    error={errors.password}
                />
            </div>
            <div className="sign-up__wrap-input">
                <Input
                    value={repeatPassword}
                    onChange={handleChangeRepeatPassword}
                    leftIcon="lock"
                    rightIcon={isViewRepeatPassword ? 'eye' : 'eye-lock'}
                    onClickRight={() =>
                        setIsViewRepeatPassword((prev) => !prev)
                    }
                    type={isViewRepeatPassword ? 'text' : 'password'}
                    placeholder="Повторите пароль"
                    error={errors.repeatPassword}
                />
            </div>
            <div className="sign-up__wrap-checkbox">
                <CheckBox
                    text={
                        <>
                            Я согласен с{' '}
                            <LinkText
                                text="Пользовательским соглашением"
                                to="/auth/agreement"
                            />
                        </>
                    }
                    value={userAgreement}
                    onChange={setUserAgreement}
                />
            </div>
            <div className="sign-up__wrap-btn">
                <ButtonFilled
                    disabled={!userAgreement}
                    text="Зарегистрироваться"
                    onClick={submit}
                />
            </div>
            <div className="sign-up__wrap-sign-in">
                <p className="sign-up__sign-in">
                    У вас уже есть аккаунт?{' '}
                    <Link text="Войти" to="/auth/sign-in" />
                </p>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}

export default SignUp
