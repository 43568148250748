export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
    >
        <g clip-path="url(#clip0_615_929)">
            <path
                d="M12.5 2.5C6.98 2.5 2.5 6.98 2.5 12.5C2.5 18.02 6.98 22.5 12.5 22.5H17.5V20.5H12.5C8.16 20.5 4.5 16.84 4.5 12.5C4.5 8.16 8.16 4.5 12.5 4.5C16.84 4.5 20.5 8.16 20.5 12.5V13.93C20.5 14.72 19.79 15.5 19 15.5C18.21 15.5 17.5 14.72 17.5 13.93V12.5C17.5 9.74 15.26 7.5 12.5 7.5C9.74 7.5 7.5 9.74 7.5 12.5C7.5 15.26 9.74 17.5 12.5 17.5C13.88 17.5 15.14 16.94 16.04 16.03C16.69 16.92 17.81 17.5 19 17.5C20.97 17.5 22.5 15.9 22.5 13.93V12.5C22.5 6.98 18.02 2.5 12.5 2.5ZM12.5 15.5C10.84 15.5 9.5 14.16 9.5 12.5C9.5 10.84 10.84 9.5 12.5 9.5C14.16 9.5 15.5 10.84 15.5 12.5C15.5 14.16 14.16 15.5 12.5 15.5Z"
                fill="#4596F7"
            />
        </g>
        <defs>
            <clipPath id="clip0_615_929">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
)
