import { useState } from 'react'
import './styles.css'
import { StarWrapper } from './star-wrapper'

const RangButton = ({
    title,
    isOpen,
    changeCardButton,
    setStars,
    choosen,
    stars,
}) => {
    return (
        <div className="marketplace__rangbutton-wrapper">
            <button type="button" alt="rangbutton" onClick={changeCardButton}>
                {title}
            </button>

            {isOpen && (
                <div className="marketplace__starbuttons-wrapper">
                    <button
                        type="button"
                        alt="rangbutton"
                        className="starwrapper"
                        onClick={() => setStars(3)}
                    >
                        <StarWrapper starAmmouth={3} />
                    </button>
                    <button
                        type="button"
                        alt="rangbutton"
                        className="starwrapper"
                        onClick={() => setStars(4)}
                    >
                        <StarWrapper starAmmouth={4} />
                    </button>
                    <button
                        type="button"
                        alt="rangbutton"
                        className="starwrapper"
                        onClick={() => setStars(5)}
                    >
                        <StarWrapper starAmmouth={5} />
                    </button>
                </div>
            )}

            {!!choosen && (
                <div className="marketplace__starchoosen">
                    <StarWrapper starAmmouth={stars} />
                </div>
            )}
        </div>
    )
}

export default RangButton
