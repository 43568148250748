import React, { useEffect, useMemo, useRef, useState } from 'react'
import getEnvVars from '../../api/environment'
import { useGet } from '../../api/request'
import './styles.css'

const { uploadUrl } = getEnvVars()

const ProfileBlock = ({ img, onChange }) => {
    const [svgState, setSvgState] = useState()

    useEffect(() => {
        setSvgState(
            <svg
                className="profile-block__left-icon"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 136 137"
                fill="none"
            >
                <g filter="url(#filter0_d_57_1216)">
                    <path
                        d="M123.276 35.7133C123.957 35.3585 124.799 35.6256 125.134 36.3165C131.961 50.3712 133.457 66.4755 129.309 81.5885C125.038 97.1503 115.098 110.515 101.474 119.012C87.8507 127.509 71.5476 130.512 55.8227 127.421C40.0979 124.331 26.1094 115.374 16.6527 102.341C7.19592 89.3077 2.96744 73.1582 4.8122 57.1195C6.65695 41.0807 14.4391 26.3341 26.6034 15.8264C38.7678 5.31868 54.4185 -0.176191 70.428 0.439819C85.9801 1.03823 100.758 7.36048 111.979 18.1826C112.528 18.7117 112.527 19.5859 111.991 20.1272C111.448 20.6745 110.565 20.6737 110.009 20.1395C99.2827 9.8222 85.1715 3.79568 70.3225 3.22432C55.0089 2.63509 40.0385 7.89112 28.4028 17.9421C16.7672 27.9931 9.32336 42.0987 7.55879 57.4403C5.79422 72.7818 9.8389 88.2293 18.8846 100.696C27.9303 113.163 41.3107 121.73 56.3521 124.686C71.3934 127.643 86.9879 124.77 100.019 116.642C113.05 108.515 122.559 95.7311 126.644 80.8457C130.608 66.4035 129.183 51.015 122.67 37.5803C122.338 36.8953 122.601 36.065 123.276 35.7133Z"
                        fill="url(#paint0_linear_57_1216)"
                    />
                </g>
                <g filter="url(#filter1_d_57_1216)">
                    <path
                        d="M120.141 30.2857C120.784 29.8637 121.649 30.0453 122.052 30.7002C122.204 30.947 122.354 31.1948 122.503 31.4437C122.893 32.0973 122.663 32.9397 122.004 33.3204C121.338 33.7047 120.488 33.4727 120.093 32.8134C119.971 32.6097 119.848 32.4067 119.724 32.2044C119.325 31.5558 119.504 30.7034 120.141 30.2857Z"
                        fill="#42A4FF"
                    />
                </g>
                <g filter="url(#filter2_d_57_1216)">
                    <path
                        d="M116.955 25.8013C117.559 25.3238 118.438 25.4289 118.897 26.0465C119.103 26.3231 119.307 26.6013 119.508 26.8812C119.952 27.4994 119.795 28.3583 119.171 28.7941C118.54 29.2344 117.672 29.0756 117.222 28.4516C117.053 28.2165 116.882 27.9826 116.709 27.75C116.255 27.1386 116.358 26.2736 116.955 25.8013Z"
                        fill="#42A4FF"
                    />
                </g>
                <g filter="url(#filter3_d_57_1216)">
                    <path
                        d="M113.366 21.5882C113.926 21.0587 114.811 21.0857 115.323 21.6608C115.616 21.9896 115.906 22.3213 116.192 22.6561C116.687 23.2351 116.603 24.1045 116.017 24.5914C115.425 25.0836 114.546 24.9981 114.045 24.4135C113.797 24.124 113.546 23.837 113.293 23.5522C112.786 22.983 112.812 22.1119 113.366 21.5882Z"
                        fill="#42A4FF"
                    />
                </g>
                <path
                    d="M126.328 64.5C126.328 97.011 100.214 123.366 68 123.366C35.7861 123.366 9.6716 97.011 9.6716 64.5C9.6716 31.9889 35.7861 5.63354 68 5.63354C100.214 5.63354 126.328 31.9889 126.328 64.5Z"
                    fill="url(#pattern0)"
                />
                <defs>
                    <filter
                        id="filter0_d_57_1216"
                        x="0.388931"
                        y="0.393066"
                        width="135.222"
                        height="136.214"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_57_1216"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_57_1216"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="filter1_d_57_1216"
                        x="115.512"
                        y="30.0586"
                        width="11.1811"
                        height="11.4478"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_57_1216"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_57_1216"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="filter2_d_57_1216"
                        x="112.427"
                        y="25.5027"
                        width="11.3345"
                        height="11.5413"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_57_1216"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_57_1216"
                            result="shape"
                        />
                    </filter>
                    <filter
                        id="filter3_d_57_1216"
                        x="108.931"
                        y="21.2095"
                        width="11.5849"
                        height="11.7029"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_57_1216"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_57_1216"
                            result="shape"
                        />
                    </filter>
                    <pattern
                        id="pattern0"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                    >
                        <use
                            href="#image0_57_1216"
                            transform="translate(-0.25677) scale(0.000591227 0.000585823)"
                        />
                    </pattern>
                    <linearGradient
                        id="paint0_linear_57_1216"
                        x1="135.37"
                        y1="64.0821"
                        x2="0.902626"
                        y2="64.9008"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.000564953" stopColor="#4596F7" />
                        <stop offset="1" stopColor="#031381" />
                    </linearGradient>
                    <image
                        preserveAspectRatio="xMidYMid slice"
                        id="image0_57_1216"
                        width="2560"
                        height="1707"
                        href={img || 'img/avatar.jpg'}
                    />
                </defs>
            </svg>
        )
    }, [img])

    const [power, setPower] = useState(0)
    const get = useGet()

    useEffect(() => {
        setTimeout(() => {
            get('users/infoCard').then((data) =>
                setPower(data.data.amountPower)
            )
        })
    }, [])

    return (
        <>
            <div className="profile-block">
                <div className="profile-block__left">
                    <div className="profile-block__left-shine" />
                    {svgState}
                    <label className="profile-block__btn">
                        <input type="file" hidden onChange={onChange} />
                        <img src="/img/icon_pen.svg" alt="icon pen" />
                    </label>
                </div>
                <div className="profile-block__right">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 15 16"
                        fill="none"
                    >
                        <path
                            d="M7.28963 0.0229263C7.19401 0.0581551 7.10678 0.15042 7.03129 0.296368C6.99438 0.366826 6.81992 0.740921 6.6421 1.12844C6.46427 1.51595 6.27136 1.9387 6.21264 2.06787C6.15225 2.19704 6.06837 2.38157 6.02308 2.47887C5.88216 2.78587 5.74963 3.07608 5.49465 3.628C5.35876 3.92325 5.21114 4.24366 5.16752 4.34096C5.04841 4.60434 4.74813 5.25691 4.32035 6.18628C3.3524 8.29162 3.38931 8.2027 3.41447 8.31342C3.4195 8.34026 3.44299 8.37885 3.4648 8.40066L3.50338 8.43756L4.77833 8.41743C6.1187 8.3973 6.1187 8.3973 6.26297 8.46944C6.34349 8.51138 6.38711 8.59525 6.36194 8.66236C6.35188 8.6892 6.20761 9.07336 6.03985 9.51623C5.8721 9.95911 5.70937 10.3886 5.67918 10.4724C5.64898 10.5546 5.498 10.9556 5.34367 11.3615C5.04841 12.145 4.59212 13.3528 4.46295 13.7017C4.41933 13.8175 4.28009 14.1866 4.15428 14.5237C3.76844 15.5454 3.67114 15.8188 3.67114 15.8926C3.66946 15.9631 3.68288 16 3.70804 16C3.72314 16 4.84207 14.5103 6.92728 11.7138C7.16885 11.3901 7.40203 11.078 7.449 11.0176C7.4943 10.9573 7.69225 10.6939 7.88852 10.4305C8.0848 10.1671 8.76924 9.2495 9.41175 8.39227C10.9937 6.27854 10.8779 6.44127 10.8679 6.3339L10.8628 6.27854L10.6867 6.26848C10.5894 6.26177 9.89153 6.25674 9.13495 6.25506C7.66876 6.25338 7.43223 6.24667 7.43223 6.20809C7.43223 6.18125 7.43894 6.17118 7.77109 5.66623C7.91369 5.44983 8.10996 5.15122 8.20726 5.0036C8.37166 4.75197 8.60819 4.39297 9.02255 3.76221C9.11985 3.61458 9.31612 3.31598 9.45872 3.09957C9.60299 2.88316 9.78081 2.6114 9.8563 2.49565C9.93179 2.3799 10.1432 2.05948 10.326 1.78269C11.0893 0.626847 11.299 0.303079 11.3191 0.247719C11.3493 0.162164 11.346 0.0900288 11.3091 0.044734L11.2772 0.00447273L9.31612 0.00111771C7.61508 -0.00223732 7.34835 0.00111771 7.28963 0.0229263Z"
                            fill="url(#paint0_linear_57_1274)"
                        />
                        <path
                            d="M5.337 1.11155C4.46299 1.33634 3.44975 1.85806 2.71833 2.4603C1.35615 3.58595 0.453625 5.05213 0.133211 6.6542C0.0895945 6.87732 0.0694638 7.02494 0.0174595 7.51479C-0.0278346 7.95095 0.0174595 8.71089 0.124823 9.28965C0.45027 11.041 1.38132 12.6246 2.71833 13.7C2.93138 13.8727 3.16959 14.0304 3.2367 14.0455C3.3038 14.0589 3.39271 14.0187 3.44471 13.9499C3.4682 13.9197 3.51853 13.8291 3.55375 13.7503C3.75003 13.3242 3.82049 12.9266 3.74332 12.68C3.69299 12.5189 3.63428 12.4384 3.45981 12.2858C2.78543 11.702 2.29726 11.0276 1.93826 10.1871C1.58094 9.35172 1.42661 8.33679 1.52559 7.4695C1.69502 5.99324 2.4667 4.59919 3.62421 3.67821C3.71144 3.60943 3.93624 3.45006 4.12245 3.32592C4.70288 2.93673 5.02162 2.59115 5.30009 2.04259C5.41752 1.81277 5.46953 1.67017 5.53495 1.40847C5.5987 1.14845 5.61212 1.05283 5.5836 1.05451C5.57186 1.05451 5.46114 1.07967 5.337 1.11155Z"
                            fill="url(#paint1_linear_57_1274)"
                        />
                        <path
                            d="M11.1129 2.24237C11.0458 2.28263 10.982 2.36148 10.7656 2.67183C10.5593 2.96875 10.4972 3.07612 10.462 3.19858C10.4033 3.39821 10.4385 3.47706 10.665 3.6532C10.9988 3.91322 11.4081 4.34435 11.6799 4.72013C11.9986 5.16636 12.2905 5.73505 12.4717 6.27019C12.8693 7.45119 12.8861 8.7362 12.5153 9.84675C12.1647 10.8952 11.4903 11.8212 10.571 12.5174C9.96879 12.9737 9.33971 13.2891 8.61836 13.4988C8.18051 13.6263 7.793 13.6833 7.24779 13.7018C6.87202 13.7135 6.82672 13.7186 6.716 13.7555C6.46269 13.8444 6.22112 14.0574 5.98962 14.398C5.84703 14.6093 5.68766 14.9079 5.68766 14.9683C5.68766 15.0153 5.73631 15.0707 5.7967 15.0942C5.98123 15.1646 6.54322 15.2183 7.09681 15.22C7.83661 15.22 8.34324 15.1562 9.01762 14.9734C10.6465 14.5355 12.0238 13.5877 12.98 12.2457C13.693 11.2442 14.1342 10.1001 14.2969 8.8184C14.3254 8.58522 14.3271 7.49817 14.2969 7.27673C14.1795 6.39601 13.9731 5.69479 13.6141 4.95163C13.2853 4.26886 12.9247 3.7354 12.4096 3.17006C12.1144 2.84461 11.5138 2.31786 11.3461 2.23566C11.2756 2.20211 11.1766 2.20379 11.1129 2.24237Z"
                            fill="url(#paint2_linear_57_1274)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_57_1274"
                                x1="3.566"
                                y1="20.6928"
                                x2="29.253"
                                y2="-11.0732"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#EAD411" />
                                <stop
                                    offset="1"
                                    stopColor="#B58C24"
                                    stopOpacity="0"
                                />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_57_1274"
                                x1="0.113158"
                                y1="17.8591"
                                x2="21.3911"
                                y2="-5.00228"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#EAD411" />
                                <stop
                                    offset="1"
                                    stopColor="#B58C24"
                                    stopOpacity="0"
                                />
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear_57_1274"
                                x1="5.86214"
                                y1="19.0352"
                                x2="24.7693"
                                y2="-12.2522"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#EAD411" />
                                <stop
                                    offset="1"
                                    stopColor="#B58C24"
                                    stopOpacity="0"
                                />
                            </linearGradient>
                        </defs>
                    </svg>
                    <p className="profile-block__balance">{power || 0}</p>
                </div>
            </div>
        </>
    )
}

export default ProfileBlock
