import sms from './SMS'
import tg from './Telegram'
import tw from './Twitter'
import wu from './WhatsApp'
import e from './Email'
import './styles.css'

export default function ShareButton({ src, text, handleclick, last = false }) {
    return (
        <div className={`sharebutton__wrapper ${last && 'last'}`}>
            <button type="button" onClick={handleclick} className="sharebutton">
                {src === 'sms'
                    ? sms
                    : src === 'tg'
                      ? tg
                      : src === 'wu'
                        ? wu
                        : src === 'tw'
                          ? tw
                          : e}
            </button>
            <label className="sharelabel">{text}</label>
        </div>
    )
}
