import React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonFilledMin from '../../components/ButtonFilledMin'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div>
            NotFound
            <ButtonFilledMin
                text={'Вернуться на главный экран'}
                onClick={() => navigate('/')}
            />
        </div>
    )
}

export default NotFound
