export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="45"
        viewBox="0 0 96 45"
        fill="none"
    >
        <g filter="url(#filter0_d_2005_19787)">
            <path
                d="M0.966553 6.57227C0.966553 3.25856 3.65284 0.572266 6.96655 0.572266H69.3524C72.6661 0.572266 75.3524 3.25856 75.3524 6.57227V18.8693C75.3524 22.183 72.6661 24.8693 69.3524 24.8693H6.96656C3.65286 24.8693 0.966553 22.183 0.966553 18.8693V6.57227Z"
                fill="url(#paint0_linear_2005_19787)"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2005_19787"
                x="0.966553"
                y="0.572266"
                width="94.3859"
                height="44.2969"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2005_19787"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2005_19787"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2005_19787"
                x1="77.5511"
                y1="12.7208"
                x2="-1.07266"
                y2="12.7208"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.000564953" stop-color="#4596F7" />
                <stop offset="1" stop-color="#031381" />
            </linearGradient>
        </defs>
    </svg>
)
