export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
    >
        <path
            d="M12.5 2.5C11.1868 2.5 9.88641 2.75866 8.67315 3.2612C7.4599 3.76375 6.35751 4.50035 5.42892 5.42893C3.55356 7.3043 2.49999 9.84784 2.49999 12.5C2.49125 14.8091 3.29078 17.0485 4.75999 18.83L2.75999 20.83C2.62123 20.9706 2.52723 21.1492 2.48986 21.3432C2.45249 21.5372 2.47341 21.7379 2.54999 21.92C2.63305 22.0999 2.76769 22.2511 2.93683 22.3544C3.10598 22.4577 3.30199 22.5083 3.49999 22.5H12.5C15.1522 22.5 17.6957 21.4464 19.5711 19.5711C21.4464 17.6957 22.5 15.1522 22.5 12.5C22.5 9.84784 21.4464 7.3043 19.5711 5.42893C17.6957 3.55357 15.1522 2.5 12.5 2.5ZM12.5 20.5H5.90999L6.83999 19.57C7.02624 19.3826 7.13078 19.1292 7.13078 18.865C7.13078 18.6008 7.02624 18.3474 6.83999 18.16C5.53057 16.852 4.71516 15.1305 4.53268 13.2888C4.3502 11.447 4.81193 9.59901 5.83922 8.05952C6.8665 6.52004 8.39578 5.38436 10.1665 4.84597C11.9372 4.30759 13.8398 4.3998 15.5502 5.10691C17.2606 5.81402 18.6728 7.09227 19.5464 8.72389C20.42 10.3555 20.7009 12.2395 20.3411 14.055C19.9814 15.8705 19.0033 17.505 17.5735 18.6802C16.1438 19.8554 14.3508 20.4985 12.5 20.5Z"
            fill="white"
        />
    </svg>
)
