export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="48"
        viewBox="0 0 47 48"
        fill="none"
    >
        <g filter="url(#filter0_di_2053_1865)">
            <path
                d="M15.3187 13.1512L23.2813 9.99245L31.3096 13.1512L30.3883 35.5911L23.2813 40.0001L15.3187 35.5911V13.1512Z"
                fill="url(#paint0_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M15.3187 13.1512L23.2813 9.99245L31.3096 13.1512L30.3883 35.5911L23.2813 40.0001L15.3187 35.5911V13.1512Z"
                fill="url(#paint1_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M23.2813 6.90966L15.9768 3.29032L23.2813 0L30.7174 3.29032L23.2813 6.90966Z"
                fill="url(#paint2_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M23.2813 6.90966L15.9768 3.29032L23.2813 0L30.7174 3.29032L23.2813 6.90966Z"
                fill="url(#paint3_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M16.8322 7.68885L11.6335 5.45144L4 8.34692V12.0979L5.0529 12.7559L5.57935 29.734L11.0413 32.6294L10.449 10.3869L16.8322 7.68885Z"
                fill="url(#paint4_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M16.8322 7.68885L11.6335 5.45144L4 8.34692V12.0979L5.0529 12.7559L5.57935 29.734L11.0413 32.6294L10.449 10.3869L16.8322 7.68885Z"
                fill="url(#paint5_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M29.2038 7.75499L34.4025 5.51758L42.0361 8.41306V12.164L40.9832 12.8221L40.4567 29.8001L34.9948 32.6956L35.587 10.4531L29.2038 7.75499Z"
                fill="url(#paint6_linear_2053_1865)"
                fillOpacity="0.4"
            />
            <path
                d="M29.2038 7.75499L34.4025 5.51758L42.0361 8.41306V12.164L40.9832 12.8221L40.4567 29.8001L34.9948 32.6956L35.587 10.4531L29.2038 7.75499Z"
                fill="url(#paint7_linear_2053_1865)"
                fillOpacity="0.4"
            />
        </g>
        <defs>
            <filter
                id="filter0_di_2053_1865"
                x="0"
                y="0"
                width="46.0361"
                height="48"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2053_1865"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2053_1865"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect2_innerShadow_2053_1865"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_2053_1865"
                x1="7"
                y1="4.50001"
                x2="23.0181"
                y2="40.0001"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EA5A6F" />
                <stop
                    offset="0.458333"
                    stopColor="#DE791E"
                    stopOpacity="0.987805"
                />
                <stop offset="1" stopColor="#FCCF3A" />
            </linearGradient>
        </defs>
    </svg>
)
