import React from 'react'
import './styles.css'
import { useSelector } from 'react-redux'

const MainCard = ({ onClick, img, title }) => {
    const { currentCryptocurrency } = useSelector(
        (state) => state.currentCryptocurrency
    )
    return (
        <div>
            <button
                className="main-card"
                onClick={onClick}
                style={
                    currentCryptocurrency === 'DEL'
                        ? {
                              background:
                                  'linear-gradient(136deg, rgba(1, 252, 173, 0.80) 0%, rgba(1, 252, 173, 0.80) 0.01%, rgba(62, 116, 242, 0.80) 36.98%, rgba(108, 75, 246, 0.80) 71.35%, rgba(137, 49, 245, 0.80) 100%)',
                          }
                        : currentCryptocurrency === 'CRYG'
                          ? {
                                background:
                                    'linear-gradient(315deg, #011C50 0%, #5E58D0 100%)',
                            }
                          : {}
                }
            >
                <div className="main-card__wrap-icon">{img}</div>
            </button>
            <p className="main-card__title">{title}</p>
        </div>
    )
}

export default MainCard
