import './styles.css'

const CheckBox = ({ text, value, onChange, ...props }) => {
    return (
        <div className={`checkbox`}>
            <div
                className={`checkbox__wrap${
                    value ? ' checkbox__wrap_check' : ''
                }`}
                onClick={() => onChange((prev) => !prev)}
            >
                {value && (
                    <svg
                        className="checkbox__check"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 13 10"
                        fill="none"
                    >
                        <path d="M4.38625 9.3125C4.27514 9.3125 4.17097 9.295 4.07375 9.26C3.97653 9.22555 3.88625 9.16667 3.80292 9.08333L0.219585 5.5C0.066807 5.34722 -0.00624855 5.14917 0.000418118 4.90583C0.00764034 4.66305 0.08764 4.46528 0.240418 4.3125C0.393196 4.15972 0.58764 4.08333 0.823751 4.08333C1.05986 4.08333 1.25431 4.15972 1.40708 4.3125L4.38625 7.29167L11.4488 0.229167C11.6015 0.076389 11.7996 0 12.0429 0C12.2857 0 12.4835 0.076389 12.6363 0.229167C12.789 0.381945 12.8654 0.579722 12.8654 0.8225C12.8654 1.06583 12.789 1.26389 12.6363 1.41667L4.96958 9.08333C4.88625 9.16667 4.79597 9.22555 4.69875 9.26C4.60153 9.295 4.49736 9.3125 4.38625 9.3125Z" />
                    </svg>
                )}
            </div>
            {text && <p className="checkbox__text">{text}</p>}
        </div>
    )
}

export default CheckBox
