export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="170"
        height="60"
        viewBox="0 0 170 60"
        fill="none"
    >
        <g filter="url(#filter0_d_2005_7233)">
            <path
                d="M0 6C0 2.68629 2.68629 0 6 0H144C147.314 0 150 2.68629 150 6V33.6094C150 36.9231 147.314 39.6094 144 39.6094H6C2.68629 39.6094 0 36.9231 0 33.6094V6Z"
                fill="url(#paint0_linear_2005_7233)"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2005_7233"
                x="0"
                y="0"
                width="170"
                height="59.6094"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2005_7233"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2005_7233"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2005_7233"
                x1="154.434"
                y1="19.8047"
                x2="-4.11209"
                y2="19.8047"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.000564953" stop-color="#4596F7" />
                <stop offset="1" stop-color="#031381" />
            </linearGradient>
        </defs>
    </svg>
)
