import { useEffect, useState } from 'react'
import { useGet } from '../api/request'
import { useDispatch } from 'react-redux'
import { setBalance } from '../redux/auth'

export const useDebounce = (text, delay = 400) => {
    const [debounce, setDebounce] = useState(text)

    useEffect(() => {
        const timeout = setTimeout(() => setDebounce(text), delay)
        return () => clearTimeout(timeout)
    }, [text, delay])
    return { debounce }
}

export const useBalance = () => {
    const get = useGet()
    const dispatch = useDispatch()
    const getBalance = async () => {
        get('users/balance')
            .then((data) => {
                if (data.status === 'success') {
                    dispatch(setBalance(data?.balance?.amount))
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }
    return {
        getBalance,
    }
}
