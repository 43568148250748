import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import './styles.css'

const Link = ({ text, ...props }) => (
    <RouterLink className="link" {...props}>
        {text}
    </RouterLink>
)

export default Link
