import { createSlice } from '@reduxjs/toolkit'
import { configureTransport } from '../../api/transport'

export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: '',
        refreshToken: '',
        user: {},
        rememberData: {},
    },
    reducers: {
        setTokens: (state, action) => {
            configureTransport(action.payload.accessToken)
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        setUser: (state, action) => {
            state.user = action.payload || {}
        },
        setBalance: (state, action) => {
            state.user.wallet.balance.amount = action.payload
        },
        setDecimalWallet: (state, action) => {
            state.user.decimal_wallet = action.payload
        },
        setRememberData: (state, action) => {
            state.rememberData = {
                login: action.payload.login,
                password: action.payload.password,
            }
        },
        logout: (state, action) => {
            configureTransport('')
            state.accessToken = ''
            state.refreshToken = ''
            state.user = {}
        },
    },
})

export const {
    setTokens,
    setUser,
    setBalance,
    setRememberData,
    logout,
    setDecimalWallet,
} = counterSlice.actions

export default counterSlice.reducer
