import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
    name: 'bottomBar',
    initialState: {
        fixed: true,
    },
    reducers: {
        setFixed: (state, action) => {
            state.fixed = !!action.payload
        },
    },
})

export const { setFixed } = counterSlice.actions

export default counterSlice.reducer
