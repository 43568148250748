import { useRef, useEffect, useState } from 'react'
import { usePut } from '../../api/request'
import { useSelector } from 'react-redux'
import { useFetchUser } from '../../controllers/ControllerAuth'
import { useAlert } from '../../components/AlertNotification'
import './styles.css'

const ModalWallet = ({ dialogRef }) => {
    const user = useSelector((state) => state.auth.user)
    const [value, setValue] = useState(
        user.decimal_wallet ? user.decimal_wallet : ''
    )
    const [error, setError] = useState(false)
    const dialogInnerRef = useRef()
    const fetchUser = useFetchUser()
    const put = usePut()
    const alert = useAlert()
    const alertNotifications = {
        success: {
            status: 'success',
            title: 'Успешно',
            message: 'Ваш колек был успешно сохранен',
        },
        error: {
            status: 'error',
            title: 'Произошла ошибка',
            message: 'Не удалось сохранить адрес',
        },
    }

    useEffect(() => {
        dialogRef.current = {
            open: () => {
                dialogInnerRef.current.setAttribute('open', true)
            },
            close: () => {
                dialogInnerRef.current.removeAttribute('open')
            },
        }
    }, [])

    const handleChange = (evt) => {
        setValue(evt.target.value)
        setError(false)
    }

    const handleClose = () => {
        dialogInnerRef.current.removeAttribute('open')
        //dialogInnerRef.current.close();
    }

    const handleSubmit = () => {
        if (!value) {
            setError(true)
            return
        }
        const requestData = new FormData()

        requestData.append('decimal_wallet', value)

        const headers = {
            'Content-Type': 'multipart/form-data',
        }

        put('users', requestData, { headers })
            .then((response) => {
                fetchUser()

                alert(alertNotifications.success)

                dialogInnerRef.current.removeAttribute('open')
                //dialogInnerRef.current.close();
            })
            .catch((e) => {
                alert(alertNotifications.error)

                setError(true)
                console.log(e)
                dialogInnerRef.current.removeAttribute('open')
                //dialogInnerRef.current.close();
            })
    }

    return (
        <dialog ref={dialogInnerRef} className="modal-wallet">
            <div className="modal-wallet__wrap">
                <p className="modal-wallet__title">Ваш номер кошелька DEL</p>
                <input
                    className="modal-wallet__input"
                    type="text"
                    value={value}
                    {...(error ? { error: '' } : {})}
                    placeholder="Введите номер кошелька"
                    onChange={handleChange}
                />
            </div>
            <div className="modal-wallet__wrap-btns">
                <button
                    className="modal-wallet__btn modal-wallet__btn_cancel"
                    onClick={handleClose}
                >
                    Отменить
                </button>
                <button
                    className="modal-wallet__btn modal-wallet__btn_confirm"
                    onClick={handleSubmit}
                >
                    Сохранить
                </button>
            </div>
            <p className="modal-wallet__text">
                Чтобы приобрести CRYG, необходимо задать адрес своего
                ОФИЦИАЛЬНОГО кошелька DEL с которого будете производить
                пополнение.
            </p>
        </dialog>
    )
}

export default ModalWallet
