import './styles.css'

export default function SearchInput({ input, setInput, margin }) {
    return (
        <div className="searchWrap" style={{ margin }}>
            <SearchIco />
            <input
                className="searchInput"
                type={'text'}
                placeholder="Поиск"
                value={input}
                onChange={(event) => setInput(event.target.value)}
            />
        </div>
    )
}

export function SearchIco() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3333 17.5L11.0833 12.25C10.6667 12.5833 10.1875 12.8472 9.64583 13.0417C9.10417 13.2361 8.52778 13.3333 7.91667 13.3333C6.40278 13.3333 5.12167 12.8089 4.07333 11.76C3.025 10.7111 2.50056 9.43 2.5 7.91667C2.5 6.40278 3.02444 5.12167 4.07333 4.07333C5.12222 3.025 6.40333 2.50056 7.91667 2.5C9.43056 2.5 10.7117 3.02444 11.76 4.07333C12.8083 5.12222 13.3328 6.40333 13.3333 7.91667C13.3333 8.52778 13.2361 9.10417 13.0417 9.64583C12.8472 10.1875 12.5833 10.6667 12.25 11.0833L17.5 16.3333L16.3333 17.5ZM7.91667 11.6667C8.95833 11.6667 9.84389 11.3019 10.5733 10.5725C11.3028 9.84306 11.6672 8.95778 11.6667 7.91667C11.6667 6.875 11.3019 5.98944 10.5725 5.26C9.84306 4.53056 8.95778 4.16611 7.91667 4.16667C6.875 4.16667 5.98944 4.53139 5.26 5.26083C4.53056 5.99028 4.16611 6.87556 4.16667 7.91667C4.16667 8.95833 4.53139 9.84389 5.26083 10.5733C5.99028 11.3028 6.87556 11.6672 7.91667 11.6667Z"
                fill="white"
                fillOpacity="0.5"
            />
        </svg>
    )
}
