import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import List from '../../components/List'
import { useGet } from '../../api/request'
import './styles.css'
import { formatTime } from '../../helpers'

const ReferralsLine = () => {
    const user = useSelector((state) => state.auth.user)
    const [info, setInfo] = useState({})
    const [listUsers, setListUsers] = useState([])
    const { key } = useParams()
    const get = useGet()

    useEffect(() => {
        setTimeout(async () => {
            fetchReferalLine()
        })
    }, [])

    const fetchReferalLine = () => {
        get(`users/referral/${key}?user_id=${user.id}&limit=999`)
            .then((response) => {
                setInfo({
                    allCount: response.data.allCount,
                    earned: response.data.earned,
                })
                setListUsers(
                    response.data.users.map((user) => ({
                        left: `#${user.user_login}`,
                        right: formatTime(user?.date_create),
                    }))
                )
            })
            .catch(console.log)
    }

    return (
        <div className="referrals-line">
            <Header text={`${key} линия`} />
            <div className="referrals-line__wrap">
                <div className="referrals-line__info">
                    <div className="referrals-line__info-row">
                        <p className="referrals-line__info-label">
                            Количество приглашенных:
                        </p>
                        <p className="referrals-line__info-text">
                            {info.allCount || 0} человек
                        </p>
                    </div>
                    <div className="referrals-line__info-row">
                        <p className="referrals-line__info-label">
                            Заработано:
                        </p>
                        <p className="referrals-line__info-text">
                            {info.earned || 0} CRYG
                        </p>
                    </div>
                </div>
            </div>
            <div className="referrals-line__wrap-list">
                <List data={listUsers} />
            </div>
        </div>
    )
}

export default ReferralsLine
