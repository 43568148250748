import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../../components/Input'
import Header from '../../components/Header'
import ButtonFilled from '../../components/ButtonFilled'
import Loader from '../../components/Loader'
import { put } from '../../api/request'
import './styles.css'

const PasswordReset = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [isViewPassword, setIsViewPassword] = useState(false)
    const [repeatPassword, setRepeatPassword] = useState('')
    const [isViewRepeatPassword, setIsViewRepeatPassword] = useState(false)
    const [errors, setErrors] = useState({
        password: '',
        repeatPassword: '',
    })
    const { key } = useParams()
    const navigate = useNavigate()

    const validation = () => {
        let valid = true
        if (password !== repeatPassword) {
            setErrors((prev) => ({
                ...prev,
                password: true,
                repeatPassword: 'Пароли не совпадают',
            }))
            valid = false
        }
        if (!password) {
            setErrors((prev) => ({
                ...prev,
                password: 'Неверный пароль',
            }))
            valid = false
        }
        if (!repeatPassword) {
            setErrors((prev) => ({
                ...prev,
                repeatPassword: 'Неверный пароль',
            }))
            valid = false
        }
        return valid
    }

    const handleChangePassword = (evt) => {
        setPassword(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            password: '',
            repeatPassword: '',
        }))
    }

    const handleChangeRepeatPassword = (evt) => {
        setRepeatPassword(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            password:
                prev.password && typeof prev.password != 'string'
                    ? ''
                    : prev.password,
            repeatPassword: '',
        }))
    }

    const submit = () => {
        if (!validation()) return
        setIsLoading(true)
        const requestData = {
            password,
            repeat_password: repeatPassword,
            tmp_key: key,
        }

        put('users/recover', requestData)
            .then((response) => {
                navigate('/auth/password-reset-success')
            })
            .catch((e) => {
                console.log(e)
                setErrors((prev) => ({
                    ...prev,
                    ...(e.response.data.validation || {}),
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="password-reset">
            <Header text="Cброс пароля" />
            <div className="password-reset__wrap">
                <div className="password-reset__wrap-input">
                    <Input
                        value={password}
                        onChange={handleChangePassword}
                        leftIcon="lock"
                        rightIcon={isViewPassword ? 'eye' : 'eye-lock'}
                        onClickRight={() => setIsViewPassword((prev) => !prev)}
                        type={isViewPassword ? 'text' : 'password'}
                        placeholder="Пароль"
                        error={errors.password}
                    />
                </div>
                <div className="password-reset__wrap-input">
                    <Input
                        value={repeatPassword}
                        onChange={handleChangeRepeatPassword}
                        leftIcon="lock"
                        rightIcon={isViewRepeatPassword ? 'eye' : 'eye-lock'}
                        onClickRight={() =>
                            setIsViewRepeatPassword((prev) => !prev)
                        }
                        type={isViewRepeatPassword ? 'text' : 'password'}
                        placeholder="Повторите пароль"
                        error={errors.repeatPassword}
                    />
                </div>
                <div className="password-reset__wrap-btn">
                    <ButtonFilled text="Сохранить" onClick={submit} />
                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}

export default PasswordReset
