import axios from 'axios'
import getEnvVars from './environment'

const { apiUrl } = getEnvVars()

let transport

export const configureTransport = (token = null) => {
    const options = token
        ? {
              baseURL: apiUrl,
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        : { baseURL: apiUrl }
    transport = axios.create(options)
    return transport
}

export const getTransport = () => transport || configureTransport()
