import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './redux/auth'
import bottomBar from './redux/bottomBar'
import { boosterReducer } from './redux/booster'
import { stakingReducer } from './redux/staking'
import { boosterPackReducer } from './redux/boosterPack'
import { currentCryptocurrencyReducer } from './redux/currentCryptocurrency'

const appReducer = combineReducers({
    auth,
    bottomBar,
    booster: boosterReducer,
    staking: stakingReducer,
    boosterPack: boosterPackReducer,
    currentCryptocurrency: currentCryptocurrencyReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    //whitelist: ['auth'],
    whitelist: [
        'auth',
        'booster',
        'staking',
        'boosterPack',
        'currentCryptocurrency',
    ],
}

const persistedReducer = persistReducer(persistConfig, appReducer)

const store = createStore(
    persistedReducer,
    process.env.NODE_ENV !== 'production'
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__()
        : ''
)

export default store
export const persistor = persistStore(store)
