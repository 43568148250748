import './styles.css'
import btn1 from './btn1'
import btn2 from './btn2'
import mbtn from './modalbtn'
import bpack_btn from './bpack_btn'

const ButtonFilledMin = ({
    text,
    disabled,
    onClick,
    zIndex = 0,
    bg = 'bpack',
    style,
}) => (
    <button
        className={`button-filled-min${
            disabled ? ' button-filled-min_disabled' : ''
        }`}
        disabled={disabled}
        onClick={onClick}
        style={{ ...style, zIndex }}
    >
        {bg === 'm'
            ? mbtn
            : bg === 'bpack'
              ? bpack_btn
              : bg === '1'
                ? btn1
                : btn2}
        <p className="button-filled-min__text" translate="no">
            {text}
        </p>
    </button>
)

export default ButtonFilledMin
