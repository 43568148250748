import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { shortenAddress } from '../../helpers'
import { useAlert } from '../AlertNotification'
import './styles.css'

const WalletReplenishCard = ({ background, address /* url */ }) => {
    console.log('address', address)
    const alert = useAlert()

    const handleCopy = () => {
        alert({
            status: 'success',
            title: 'Скопировано',
            message: 'Адресс скопирован',
        })
    }

    return (
        <div className="wallet-replenish-card" style={{ background }}>
            <p className="wallet-replenish-card__title">Пополнить</p>
            {/*  {url && (
                <img className="wallet-replenish-card__qr" src={url} alt="qr" />
            )} */}
            <img
                className="wallet-replenish-card__qr"
                src="/img/qr.png"
                alt="qr"
            />
            {address && (
                <CopyToClipboard text={address} onCopy={handleCopy}>
                    <div className="wallet-replenish-card__copy">
                        <svg
                            className="wallet-replenish-card__copy-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 13 14"
                            fill="none"
                        >
                            <path d="M7.59138 11.3831H2.53046C1.85959 11.3822 1.21642 11.0821 0.742042 10.5486C0.267663 10.0151 0.000803602 9.29181 0 8.53734L0 2.84578C0.000803602 2.09131 0.267663 1.368 0.742042 0.834508C1.21642 0.301016 1.85959 0.000903739 2.53046 0L7.59138 0C8.26225 0.000903739 8.90542 0.301016 9.3798 0.834508C9.85418 1.368 10.121 2.09131 10.1218 2.84578V8.53734C10.121 9.29181 9.85418 10.0151 9.3798 10.5486C8.90542 11.0821 8.26225 11.3822 7.59138 11.3831ZM12.1462 10.814V3.41494C12.1462 3.26399 12.0929 3.11922 11.998 3.01248C11.9031 2.90574 11.7743 2.84578 11.6401 2.84578C11.5059 2.84578 11.3772 2.90574 11.2823 3.01248C11.1873 3.11922 11.134 3.26399 11.134 3.41494V10.814C11.134 11.2668 10.9741 11.7011 10.6893 12.0213C10.4046 12.3415 10.0184 12.5214 9.61575 12.5214H3.03655C2.90233 12.5214 2.7736 12.5814 2.67869 12.6881C2.58378 12.7949 2.53046 12.9396 2.53046 13.0906C2.53046 13.2415 2.58378 13.3863 2.67869 13.493C2.7736 13.5998 2.90233 13.6597 3.03655 13.6597H9.61575C10.2866 13.6588 10.9298 13.3587 11.4042 12.8252C11.8785 12.2917 12.1454 11.5684 12.1462 10.814Z" />
                        </svg>
                        <p className="wallet-replenish-card__copy-text">
                            {shortenAddress(address)}
                        </p>
                    </div>
                </CopyToClipboard>
            )}
        </div>
    )
}

export default WalletReplenishCard
