import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import MainCard from '../../components/MainCard'
import ProfileBar from '../../components/ProfileBar'
import Alert from '../../components/Alert'
import { useDelete, useGet } from '../../api/request'
import SVGMainCard1 from './main_card_1'
import SVGMainCard2 from './main_card_2'
import SVGMainCard3 from './main_card_3'
import SVGMainCard4 from './main_card_4'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { clearStakingRedux } from '../../redux/staking'
import { clearBoosterRedux, setTokenBalanceRedux } from '../../redux/booster'
import Loader from '../../components/Loader'
import { useAlert } from '../../components/AlertNotification'
import { logout } from '../../redux/auth'
import { clearBoosterPackRedux } from '../../redux/boosterPack'
import { formatClearMoney } from '../../helpers'
import Wallet from '../Wallet'
import { userTokenBalance } from '../BoosterFerma/BoosterFerma'
import moment from 'moment'
import 'moment/locale/ru'
import { setCurrentCryptocurrencyRedux } from '../../redux/currentCryptocurrency'

const Main = () => {
    const remove = useDelete()
    const DeleteDecimalWallet = () => {
        remove('users/balance_test')
            .then((response) => {
                console.log(response, 'res')
            })
            .catch((e) => {
                console.log(e, 'ошибка-мой текст')
            })
            .finally(() => {})
    }
    moment.locale('ru')
    const navigate = useNavigate()
    const get = useGet()
    // const {percentDay,stakingBalance}=useSelector(state=>state.staking.infoStaking);
    // const {collection,mainBooster,infoPower}=useSelector(state=>state.booster);
    // const {boosterCount}=useSelector(state=>state.boosterPack);

    const [isShowAlert, setIsShowAlert] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showWithdrawal, setShowWithdrawal] = useState(false)
    const [showReplenish, setShowReplenish] = useState(false)
    const [alert] = useState({
        title: 'Скоро будет!',
    })
    const crygBalance = useSelector(
        (state) => state.auth.user.wallet?.balance?.amount
    )
    const { tokenBalance } = useSelector((state) => state.booster)
    const { currentCryptocurrency } = useSelector(
        (state) => state.currentCryptocurrency
    )

    const [, /* cardsStats */ setCardsStats] = useState({
        amountPower: null,
        balance: null,
        quantityBooster: null,
        quantityBoosterPack: null,
        quantityReferralsAllLine: null,
        quantityReferralsOneLine: null,
        stakingPercentInDay: null,
    })

    const alertX = useAlert()

    const dispatch = useDispatch()

    const relogin = (error) => {
        if (error?.response?.data?.message === 'wrong') {
            dispatch(clearStakingRedux())
            dispatch(clearBoosterRedux())
            dispatch(clearBoosterPackRedux())
            dispatch(logout())
            return true
        }
        return false
    }

    const getMainCardsStats = async () => {
        get('users/infoCard')
            .then((data) => {
                setCardsStats(data.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log('SERVER ERROR :', e)

                if (relogin(e)) {
                    return
                }

                alertX({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
                setLoading(false)
            })
    }

    const getTokkenBalance = async () => {
        get('tokensBooster/currentUser').then((data) => {
            dispatch(
                setTokenBalanceRedux(
                    data.data.tokens.sort((a, b) => a.token_id - b.token_id)
                )
            )
        })
    }

    const updateData = async () => {
        setTimeout(async () => {
            await getMainCardsStats()
            await getTokkenBalance()
        })
    }

    useEffect(() => {
        setTimeout(async () => {
            updateData()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBooster = () => {
        navigate('/booster')
    }

    const handleRent = () => {
        setIsShowAlert(true)
    }

    const handleBoosterpack = () => {
        navigate('/boosterpack')
    }

    const handleReferrals = () => {
        navigate('/referrals')
    }

    const handleCurrentCryptocurrency = ({ target }) => {
        dispatch(setCurrentCryptocurrencyRedux(target.id))
        setShowWithdrawal(false)
        setShowReplenish(false)
    }

    return (
        <div className="main">
            {loading && <Loader />}
            <div className="main__wrap-profile-bar">
                <ProfileBar />
            </div>
            <div>
                <Wallet
                    showWithdrawal={showWithdrawal}
                    setShowWithdrawal={setShowWithdrawal}
                    showReplenish={showReplenish}
                    setShowReplenish={setShowReplenish}
                />
            </div>
            {currentCryptocurrency === 'CRYG' && (
                <>
                    <hr className="main__hr" />
                    <label className="main__label" htmlFor="#main__cardwrapper">
                        Панель инструментов
                    </label>
                    <div id="main__cardwrapper" className="main__wrap-cards">
                        <div className="main__wrap-card">
                            <MainCard
                                img={SVGMainCard1}
                                title="booster ferma"
                                onClick={handleBooster}
                            />
                        </div>
                        <div className="main__wrap-card">
                            <MainCard
                                img={SVGMainCard2}
                                title="booster pack"
                                onClick={handleBoosterpack}
                            />
                        </div>
                        <div className="main__wrap-card">
                            <MainCard
                                img={SVGMainCard3}
                                title="rent"
                                onClick={handleRent}
                            />
                        </div>
                        <div className="main__wrap-card">
                            <MainCard
                                img={SVGMainCard4}
                                title="referrals"
                                onClick={handleReferrals}
                            />
                        </div>
                    </div>
                </>
            )}

            <hr className="main__hr" />
            <label className="main__label" htmlFor="#main__token">
                Криптовалюты
            </label>
            <div
                className="booster__price__table-item"
                id="CRYG"
                onClick={handleCurrentCryptocurrency}
                style={{
                    backgroundColor:
                        currentCryptocurrency === 'CRYG' ? '#1c225c' : '',
                }}
            >
                <div className="booster__price__table-logowrap" id="CRYG">
                    <img
                        src="/img/Cryglogo.png"
                        alt="cryg"
                        style={{ width: 30, height: 30, marginRight: '5px' }}
                        id="CRYG"
                    />
                    <p id="CRYG">CRYG</p>
                </div>
                <p id="CRYG">{formatClearMoney(crygBalance, 2)}</p>
            </div>
            <div
                className="booster__price__table-item"
                id="DEL"
                onClick={handleCurrentCryptocurrency}
                style={{
                    backgroundColor:
                        currentCryptocurrency === 'DEL' ? '#1c225c' : '',
                }}
            >
                <div className="booster__price__table-logowrap" id="DEL">
                    <img
                        src="https://decimalchain.com/_nuxt/img/del_mobile.caaf033.svg"
                        alt="del"
                        style={{ width: 30, height: 30, marginRight: '5px' }}
                        id="DEL"
                    />
                    <p id="DEL">DEL</p>
                </div>
                <p id="DEL">0</p>
            </div>
            <div
                className="booster__price__table-item"
                id="CAPSULA"
                onClick={handleCurrentCryptocurrency}
                style={{
                    backgroundColor:
                        currentCryptocurrency === 'CAPSULA' ? '#1c225c' : '',
                }}
            >
                <div className="booster__price__table-logowrap" id="CAPSULA">
                    <div
                        alt="boost"
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                            border: '1px solid #FFF',
                            marginRight: '5px',
                        }}
                        id="CAPSULA"
                    />
                    <p id="CAPSULA">CAPSULA</p>
                </div>
                <p id="CAPSULA">0</p>
            </div>
            <hr className="main__hr" />
            <label className="main__label" htmlFor="#main__token">
                Внутренние токены
            </label>
            {userTokenBalance(tokenBalance, 'all', {})}
            {isShowAlert && (
                <Alert onClose={() => setIsShowAlert(false)} {...alert} />
            )}
            <button style={{ color: 'red' }} onClick={DeleteDecimalWallet}>
                Удалить кошелек
            </button>
        </div>
    )
}

export default Main
