export default (
    <svg
        style={{ position: 'absolute', top: 0, right: 0 }}
        xmlns="http://www.w3.org/2000/svg"
        width="166"
        height="200"
        viewBox="0 0 166 200"
        fill="none"
    >
        <path
            d="M116.976 -13L0.000244141 40.8496L16.0748 75.7677L98.1322 37.9926L142.337 134.017L60.2797 171.792L75.5505 204.965L192.526 151.115L116.976 -13Z"
            fill="white"
            fillOpacity="0.3"
        />
        <path
            d="M84.6645 82.2783L30.5415 107.194L45.0086 138.62L99.1316 113.705L84.6645 82.2783Z"
            fill="white"
            fillOpacity="0.3"
        />
    </svg>
)
