import React from 'react'
import Header from '../../components/Header'
import './styles.css'

const PasswordResetSuccess = () => (
    <div className="password-reset-success">
        <Header text="На главную" to="/main" />
        <div className="password-reset-success__wrap">
            <div className="password-reset-success__shine" />
            <img
                className="password-reset-success__img"
                src="/img/success.png"
                alt="success"
            />
            <p className="password-reset-success__text">
                Пароль успешно изменен
            </p>
        </div>
    </div>
)

export default PasswordResetSuccess
