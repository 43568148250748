import React from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'

const Header = ({ text, to, cb = null, ...props }) => {
    const navigate = useNavigate()
    return (
        <div className="header" {...props}>
            <button
                className="header__btn"
                onClick={() => (cb ? cb() : to ? navigate(to) : navigate(-1))}
            >
                <img
                    src={`data:image/svg+xml,${encodeURIComponent(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" fill="#5165CD"><path d="M7.02971 16.4757L0.854543 10.3005C0.583624 10.0297 0.368716 9.70809 0.222092 9.35417C0.0754674 9.00025 0 8.62092 0 8.23783C0 7.85475 0.0754674 7.47541 0.222092 7.1215C0.368716 6.76758 0.583624 6.44601 0.854543 6.17517L7.02971 0L9.50421 2.47567L3.73738 8.23783L9.49954 14L7.02971 16.4757Z" /></svg>'
                    )}`}
                    alt="My SVG"
                />
            </button>
            <p className="header__title" translate="no">
                {text}
            </p>
        </div>
    )
}

export default Header
