export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
    >
        <g filter="url(#filter0_f_2005_20901)">
            <ellipse
                cx="6.26837"
                cy="6.5005"
                rx="6.26837"
                ry="6.5005"
                transform="matrix(0.999982 -0.00605393 0.00562727 0.999984 4.48529 4.07617)"
                fill="url(#paint0_radial_2005_20901)"
            />
        </g>
        <path
            d="M10.3334 6.44412C10.5096 6.04875 11.0707 6.04875 11.2469 6.44412L12.054 8.25574C12.1241 8.41309 12.2702 8.52327 12.4408 8.54735L14.3535 8.8174C14.7599 8.87479 14.9279 9.37044 14.6401 9.6631L13.1973 11.1301C13.0843 11.2449 13.0336 11.4071 13.0611 11.5658L13.4167 13.6231C13.4891 14.0416 13.0399 14.354 12.6728 14.1406L11.0414 13.1922C10.8861 13.1019 10.6942 13.1019 10.5389 13.1922L8.90752 14.1406C8.54042 14.354 8.09121 14.0416 8.16354 13.6231L8.51921 11.5658C8.54665 11.4071 8.49595 11.2449 8.383 11.1301L6.94021 9.6631C6.65237 9.37044 6.82033 8.87479 7.22679 8.8174L9.13949 8.54735C9.31006 8.52327 9.45622 8.41309 9.52632 8.25574L10.3334 6.44412Z"
            fill="url(#paint1_linear_2005_20901)"
        />
        <defs>
            <filter
                id="filter0_f_2005_20901"
                x="0.521759"
                y="0.0380859"
                width="20.5367"
                height="21.001"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="2"
                    result="effect1_foregroundBlur_2005_20901"
                />
            </filter>
            <radialGradient
                id="paint0_radial_2005_20901"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(6.26837 6.5005) rotate(90.3201) scale(6.18397 5.96316)"
            >
                <stop stop-color="#0067C7" stop-opacity="0.45" />
                <stop
                    offset="0.505208"
                    stop-color="#2D8CE3"
                    stop-opacity="0.223594"
                />
                <stop offset="1" stop-color="#59AFFF" stop-opacity="0" />
            </radialGradient>
            <linearGradient
                id="paint1_linear_2005_20901"
                x1="15.8519"
                y1="10.5379"
                x2="5.74886"
                y2="10.5379"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.000564953" stop-color="#4596F7" />
                <stop offset="1" stop-color="#031381" />
            </linearGradient>
        </defs>
    </svg>
)
