export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
    >
        <path
            d="M22.7646 2.92778C22.4799 2.69091 22.1363 2.53567 21.7704 2.47858C21.4044 2.42149 21.0298 2.46469 20.6865 2.60357L2.7656 9.83892C2.38235 9.9966 2.05612 10.2671 1.8302 10.6145C1.60428 10.962 1.48937 11.3699 1.5007 11.7841C1.51204 12.1984 1.6491 12.5994 1.89368 12.934C2.13826 13.2685 2.4788 13.5208 2.8701 13.6573L6.4951 14.918L8.5156 21.5997C8.54306 21.6889 8.5829 21.7739 8.63398 21.852C8.64173 21.864 8.65266 21.873 8.6609 21.8846C8.7199 21.967 8.79121 22.0397 8.87233 22.1004C8.8954 22.118 8.91749 22.1345 8.94215 22.1501C9.03708 22.2131 9.14222 22.2591 9.25288 22.2862L9.26472 22.2872L9.27143 22.2901C9.33796 22.3036 9.40568 22.3105 9.47358 22.3106C9.48011 22.3106 9.48591 22.3074 9.49238 22.3073C9.59484 22.3055 9.69641 22.2879 9.79347 22.255C9.81605 22.2473 9.8354 22.2345 9.85731 22.2252C9.92969 22.1952 9.99826 22.1567 10.0616 22.1106C10.1123 22.0679 10.1631 22.0251 10.2138 21.9824L12.916 18.9991L16.9462 22.1211C17.3011 22.3974 17.7378 22.5475 18.1875 22.5479C18.6586 22.5473 19.1153 22.3847 19.4808 22.0874C19.8464 21.7901 20.0987 21.3762 20.1953 20.9151L23.458 4.89849C23.5319 4.53801 23.5065 4.16421 23.3843 3.81708C23.2622 3.46995 23.048 3.16255 22.7646 2.92778ZM9.8701 15.2364C9.73144 15.3745 9.63666 15.5505 9.59764 15.7422L9.28813 17.2462L8.50407 14.6532L12.5693 12.5362L9.8701 15.2364ZM18.1719 20.5401L13.4092 16.8506C13.2099 16.6966 12.9599 16.6234 12.7091 16.6455C12.4583 16.6675 12.2249 16.7833 12.0556 16.9697L11.1902 17.9249L11.4961 16.4385L18.5791 9.35549C18.7481 9.18665 18.8511 8.96285 18.8694 8.72461C18.8877 8.48638 18.8201 8.24947 18.6788 8.05681C18.5375 7.86414 18.3318 7.72845 18.0991 7.67433C17.8664 7.6202 17.622 7.65121 17.4101 7.76174L7.24485 13.0544L3.52049 11.6915L21.499 4.49905L18.1719 20.5401Z"
            fill="white"
        />
    </svg>
)
