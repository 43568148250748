import { Outlet } from 'react-router-dom'
import ControllerAuth from '../../controllers/ControllerAuth'
import Container from '../../components/Container'
import BottomBar from '../../components/BottomBar'

const MainLayout = () => {
    return (
        <ControllerAuth>
            <Container>
                <Outlet />
            </Container>
            <BottomBar />
        </ControllerAuth>
    )
}

export default MainLayout
