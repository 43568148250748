import './styles.css'

const ButtonReferral = ({ text, onClick }) => (
    <button className="button-referral" onClick={onClick}>
        <div className="button-referral__wrap">
            <p className="button-referral__text">{text}</p>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 18">
            <path d="M10.6044 15H3.79121C2.88805 14.9988 2.0222 14.6033 1.38358 13.9003C0.74495 13.1973 0.385695 12.2442 0.384613 11.25L0.384613 3.75C0.385695 2.7558 0.74495 1.80267 1.38358 1.09966C2.0222 0.396661 2.88805 0.00119089 3.79121 0L10.6044 0C11.5075 0.00119089 12.3734 0.396661 13.012 1.09966C13.6507 1.80267 14.0099 2.7558 14.011 3.75V11.25C14.0099 12.2442 13.6507 13.1973 13.012 13.9003C12.3734 14.6033 11.5075 14.9988 10.6044 15ZM16.7363 14.25V4.5C16.7363 4.30109 16.6645 4.11032 16.5367 3.96967C16.4089 3.82902 16.2356 3.75 16.0549 3.75C15.8742 3.75 15.7009 3.82902 15.5732 3.96967C15.4454 4.11032 15.3736 4.30109 15.3736 4.5V14.25C15.3736 14.8467 15.1583 15.419 14.775 15.841C14.3916 16.2629 13.8718 16.5 13.3297 16.5H4.47253C4.29183 16.5 4.11853 16.579 3.99076 16.7197C3.86299 16.8603 3.79121 17.0511 3.79121 17.25C3.79121 17.4489 3.86299 17.6397 3.99076 17.7803C4.11853 17.921 4.29183 18 4.47253 18H13.3297C14.2328 17.9988 15.0987 17.6033 15.7373 16.9003C16.3759 16.1973 16.7352 15.2442 16.7363 14.25Z" />
        </svg>
    </button>
)

export default ButtonReferral
