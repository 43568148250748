const defaultENV = process.env.REACT_APP_VER // dev | prod

defaultENV === 'dev' && console.log('VERSION : ', '0.1.1')

//const baseURLDev = 'https://capsula-backend.herokuapp.com/api';
const baseURLDev = 'https://capsula.backend2.usdemowts.ru/api'
const uploadUrlDev = ''

const baseURLProd = 'https://backend.capsula.site/api'
const uploadUrlProd = ''

const ENV = {
    dev: {
        apiUrl: baseURLDev,
        uploadUrl: uploadUrlDev,
    },
    prod: {
        apiUrl: baseURLProd,
        uploadUrl: uploadUrlProd,
    },
}

const getEnvVars = () => {
    return ENV[defaultENV]
}

export default getEnvVars
