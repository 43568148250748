import { useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AlertProvider } from './components/AlertNotification'
import store, { persistor } from './store'
import navigation from './navigation'

const App = () => {
    const content = useRoutes(navigation)

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AlertProvider>{content}</AlertProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
