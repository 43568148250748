import './styles.css'

const ButtonFilled = ({ text, disabled, onClick }) => (
    <button
        className={`button-filled${disabled ? ' button-filled_disabled' : ''}`}
        disabled={disabled}
        onClick={onClick}
    >
        <p className="button-filled__text" translate="no">
            {text}
        </p>
    </button>
)

export default ButtonFilled
