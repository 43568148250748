import React, { useRef, useState } from 'react'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import Header from '../../components/Header'
import Modal from '../../components/Modal/modal'
import Rang from '../../components/Rang'
import Stats from '../../components/Stats'
import accounting from 'accounting'
import './styles.css'
import { useGet, usePut } from '../../api/request'
import { useAlert } from '../../components/AlertNotification'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { setStakingRedux } from '../../redux/staking'
import { useNavigate, useParams } from 'react-router-dom'

const inWords = (count, type) =>
    new Intl.NumberFormat('ru', {
        style: 'unit',
        unit: type,
        unitDisplay: 'long',
    }).format(count)

const Staking = () => {
    const [depInput, setDepInput] = useState('')
    const [deposit, setDeposit] = useState(false)
    const [action, setAction] = useState()
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [freezeArr, setFreezeArr] = useState([])
    const [timeMow, setTimeNow] = useState(new Date().getTime())
    const put = usePut()
    const get = useGet()
    const alert = useAlert()
    const dispatch = useDispatch()
    const { infoStaking, boosterMain, maxStaking } = useSelector(
        (state) => state.staking
    )
    const { amount } = useSelector((state) => state.auth.user.wallet.balance)
    const { number } = useParams()
    const farm = useSelector((state) => state.booster.boosterFarms[number])
    const navigate = useNavigate()

    console.log(number, infoStaking)
    console.log(farm)

    const timeInterval = useRef()

    const nandleChange = (event) => {
        if (action && event.target.value > maxStaking) {
            setDepInput(maxStaking - Math.round(infoStaking.stakingBalance))
            alert({
                status: 'warning',
                title: 'Внимание !',
                message: `Лимит на фарминг ${maxStaking}`,
            })
            return
        }
        setDepInput(event.target.value)
    }

    const depositWin = () => {
        setMsg('Внести на стейкинг')
        setAction(true)
        setDeposit(true)
    }

    const unmountWin = () => {
        setMsg('Снять со стейкинга')
        setAction(false)
        setDeposit(true)
    }

    const getMax = () => {
        setDepInput(Math.floor(infoStaking.stakingBalance))
    }

    const setMax = () => {
        //setDepInput(maxStaking - Math.round(infoStaking.stakingBalance))
        setDepInput(Math.floor(amount))
    }

    const updateStakingInfo = async () => {
        get('staking/info')
            .then((data) => {
                dispatch(setStakingRedux({ ...data.data }))
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: e?.response?.data?.message
                        ? 'Внимание !'
                        : 'Ошибка сервера',
                    message:
                        e?.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
    }

    const fillBalance = async () => {
        setLoading(true)
        await put('staking/fillBalance', { amount: depInput, farm_id: farm.id })
            .then((data) => {
                alert({
                    status: 'success',
                    title: 'Операция успешно проведена',
                })
                setDepInput('')
                setDeposit(false)
                navigate('/booster')
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание !',
                    message: e.response.data.message,
                })
                setDepInput('')
                setDeposit(false)
            })
            .finally(() => setLoading(false))

        await updateData()

        setLoading(false)
    }

    const withdrawBalance = async () => {
        setLoading(true)
        await put('staking/withdrawBalance', {
            amount: depInput,
            farm_id: farm.id,
        })
            .then((data) => {
                setDepInput('')
                setDeposit(false)
                alert({
                    status: 'success',
                    title: 'Операция успешно проведена',
                })
                navigate('/booster')
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание !',
                    message: e.response.data.message,
                })
                setDepInput('')
                setDeposit(false)
            })

        await updateData()
        setTimeout(() => {
            getFreezingAll()
        }, 1500)
        setTimeout(() => {
            getFreezingAll()
        }, 3000)
        setLoading(false)
    }

    const getFreezingAll = async () => {
        get('staking/freeze/freezing')
            .then((data) => {
                setFreezeArr(data.data.freezing)
            })
            .catch((err) => console.log('FREEZE ERR', err))
    }

    const amountFreeze = async (id) => {
        setLoading(true)
        put(`staking/freeze?farmingFreezingId=${id}`)
            .then((data) => {
                alert({
                    status: 'success',
                    title: 'Операция успешно проведена',
                })
                updateData()
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание !',
                    message: e.response?.data?.message,
                })
            })
            .finally(() => setLoading(false))
    }

    const updateData = async () => {
        setLoading(true)
        await getFreezingAll()
        await updateStakingInfo()
        setLoading(false)
    }

    useEffect(() => {
        setTimeout(async () => {
            updateData()
        })
        timeInterval.current = setInterval(async () => {
            setTimeNow(new Date().getTime())
        }, 30000)

        return () => {
            clearInterval(timeInterval.current)
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            getFreezingAll()
        })
    }, [timeMow])

    const calcTime = (time, freezeTime) => {
        const days = Math.floor(
            (new Date().getTime() - new Date(time).getTime()) /
                1000 /
                60 /
                60 /
                24
        )
        const mins = Math.ceil(
            (new Date(time).getTime() + Number(freezeTime) - Number(timeMow)) /
                1000 /
                60
        )
        const minsLeft = Math.ceil(freezeTime / 1000 / 60)
        const daysLeft = Math.floor(freezeTime / 1000 / 60 / 60 / 24)

        if (mins <= 60) {
            return `Осталось : ${inWords(
                minsLeft >= 0 ? minsLeft : 0,
                'minute'
            )}`
        }
        return `Осталось : ${inWords(daysLeft, 'day')}`
        //return `Осталось : ${daysLeft} дня(ей)`;
    }

    return (
        <div className="staking">
            {loading && <Loader />}
            {deposit && (
                <Modal
                    showCb={(bool) => setDeposit(bool)}
                    blackClick={false}
                    btns={[
                        {
                            name: action ? 'Внести' : 'Снять',
                            cb: action ? fillBalance : withdrawBalance,
                            disabled: +depInput <= 0,
                        },
                        {
                            name: 'Отменить',
                            cb: () => {
                                setDeposit(false)
                                setDepInput('')
                            },
                        },
                    ]}
                >
                    <p className="staking__input-text">{msg}</p>
                    <div className="staking__input-wrap">
                        <input
                            className="staking__input"
                            type={'number'}
                            placeholder="Сумма"
                            value={depInput}
                            onChange={nandleChange}
                        />
                        <span
                            onClick={action ? setMax : getMax}
                            className={'staking__input__maxbtn'}
                        >
                            Max.
                        </span>
                    </div>
                </Modal>
            )}
            <Header text={'Booster панель'} />
            {/* <div className='staking__rang-wrap'>
				<Rang rangS={boosterMain?.rang.split(' ')[0].toLowerCase()} stars={boosterMain?.star} />
				<Stats power={infoStaking?.isActive ? infoStaking?.power : 0} perday={infoStaking?.isActive ? infoStaking.percentDay : 0} extracted={infoStaking.isActive ? Number(infoStaking.extracted).toFixed(1) : 0} active={infoStaking.isActive} />
			</div> */}
            <div className="staking__wrap-balance">
                <p className="staking__info-title">Staking balance</p>
                <div className="staking__line" />
                <p className="staking__info-count">
                    {farm?.isActive ? Math.floor(+farm?.balance) || 0 : ''}
                </p>
            </div>
            <div className="staking__btns">
                <ButtonFilledMin
                    text="Пополнить"
                    onClick={depositWin}
                    disabled={!farm?.isActive}
                    bg="m"
                />
                <ButtonFilledMin
                    text="Снять"
                    onClick={unmountWin}
                    disabled={!farm?.isActive}
                    bg="m"
                />
            </div>
            <div className="staking__wrap-balance">
                <p className="staking__info-title">
                    Список замороженных средств
                </p>
                <div className="staking__line" />
                <div className="staking__freeze-list">
                    {freezeArr.map((el) => (
                        <div
                            key={Math.random() + ''}
                            className="staking__freeze-list-item-item"
                        >
                            <div className="staking__freeze-list-item-sumwrap">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/token G.png'
                                    }
                                    alt="cryg"
                                    style={{ width: 30, height: 30 }}
                                />
                                <span className="staking__freeze-list-item-item-sun">
                                    {' '}
                                    {~~el.amount} CRYG
                                </span>
                            </div>
                            <div className="staking__freeze-list-item-date">
                                {el.freezeTime ? (
                                    calcTime(el.date_create, el.freezeTime)
                                ) : (
                                    <div
                                        className="staking__freeze-list-item-take"
                                        onClick={() => amountFreeze(el.id)}
                                    >
                                        Забрать
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Staking
