import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { GamesIco } from '../../modules/Games'
import Alert from '../Alert'
import './styles.css'

const BottomBar = () => {
    const fixed = useSelector((state) => state.bottomBar.fixed)
    const location = useLocation()
    const [isShowAlert, setIsShowAlert] = useState(false)
    const user = useSelector((state) => state?.auth?.user)

    const handleClick = () => {
        if (!Boolean(user?.decimal_wallet)) {
            setIsShowAlert(true)
        }
    }

    return (
        <div className="bottom-bar">
            <div
                className={`bottom-bar__wrap${
                    !fixed ? ' bottom-bar__wrap_unfixed' : ''
                }`}
            >
                <div className="bottom-bar__wrap-inner">
                    {/* <Link to={process.env.REACT_APP_VER=='prod'?'':'/games'} onClick={()=>{process.env.REACT_APP_VER=='prod'&&setIsShowAlert(true)}}> */}
                    <Link to={'/games'}>
                        <GamesIco />
                    </Link>
                    {/* {process.env.NODE_ENV!=='production'
					?
					
					:
					 <Link className={`bottom-bar__left${/^\tmp/.test(location.pathname) ? ' bottom-bar__left_active' : ''}`} to="/">
						<div className="bottom-bar__left-shine" />
						<svg className="bottom-bar__left-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={()=>setIsShowAlert(true)}>
							<path d="M6.99999 0H3.99999C1.79086 0 0 1.79086 0 3.99999V7C0 9.20917 1.79086 11 3.99999 11H6.99999C9.20912 11 11 9.20913 11 7V3.99999C11 1.79086 9.20912 0 6.99999 0ZM20 0H17C14.7908 0 13 1.79086 13 3.99999V7C13 9.20913 14.7909 11 17 11H20C22.2092 11 24 9.20913 24 7V3.99999C24 1.79086 22.2092 0 20 0ZM3.99999 13.0001H6.99999C9.20912 13.0001 11 14.791 11 17.0001V20.0001C11 22.2092 9.20912 24.0001 6.99999 24.0001H3.99999C1.79086 24.0001 0 22.2093 0 20.0001V17.0001C0 14.791 1.79086 13.0001 3.99999 13.0001ZM20 13.0001H17C14.7908 13.0001 13 14.791 13 17.0001V20.0001C13 22.2093 14.7909 24.0001 17 24.0001H20C22.2092 24.0001 24 22.2093 24 20.0001V17.0001C24 14.791 22.2092 13.0001 20 13.0001Z" />
						</svg>
					</Link> 
					
					}
					 */}

                    <Link
                        className={`bottom-bar__center${
                            /^\/main/.test(location.pathname)
                                ? ' bottom-bar__center_active'
                                : ''
                        }`}
                        to="/"
                    >
                        <div className="bottom-bar__center-shine" />
                        <img
                            className="bottom-bar__center-img"
                            src="/img/logo.png"
                            alt="logo"
                        />
                    </Link>
                    <Link
                        className={`bottom-bar__right${
                            /^\/tmp/.test(location.pathname)
                                ? ' bom-bar__right_active'
                                : ''
                        }`}
                        onClick={handleClick}
                        to={Boolean(user?.decimal_wallet) && '/marketplace'}
                    >
                        <div className="bottom-bar__right-shine" />
                        <svg
                            className="bottom-bar__right-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="4 0 24 24"
                        >
                            <path d="M27.2844 4.07701C26.996 3.73944 26.635 3.46796 26.227 3.28182C25.8189 3.09568 25.3739 2.99947 24.9234 3H8.34871L8.30566 2.649C8.21754 1.91942 7.85806 1.24673 7.29535 0.758479C6.73265 0.270224 6.00585 0.000341794 5.25274 0L5.02516 0C4.75327 0 4.49252 0.105357 4.30026 0.292894C4.10801 0.48043 4 0.734785 4 1C4 1.26522 4.10801 1.51957 4.30026 1.70711C4.49252 1.89465 4.75327 2 5.02516 2H5.25274C5.50384 2.00004 5.74619 2.08996 5.93383 2.25272C6.12146 2.41548 6.24134 2.63975 6.27072 2.883L7.68134 14.583C7.82778 15.7998 8.42711 16.9218 9.36557 17.736C10.304 18.5503 11.5163 19.0001 12.7723 19H23.478C23.7499 19 24.0106 18.8947 24.2029 18.7071C24.3951 18.5196 24.5031 18.2652 24.5031 18C24.5031 17.7348 24.3951 17.4805 24.2029 17.2929C24.0106 17.1054 23.7499 17 23.478 17H12.7723C12.1377 16.9983 11.5193 16.8051 11.0017 16.4471C10.4842 16.089 10.0928 15.5836 9.88132 15H22.1012C23.303 15.0001 24.4666 14.5883 25.3887 13.8365C26.3108 13.0847 26.9328 12.0407 27.146 10.887L27.9507 6.53301C28.031 6.10102 28.013 5.65708 27.8978 5.23265C27.7827 4.80822 27.5732 4.41368 27.2844 4.07701Z" />
                            <path d="M11.1761 24C12.3085 24 13.2264 23.1046 13.2264 22C13.2264 20.8954 12.3085 20 11.1761 20C10.0438 20 9.12582 20.8954 9.12582 22C9.12582 23.1046 10.0438 24 11.1761 24Z" />
                            <path d="M21.4276 24C22.56 24 23.478 23.1046 23.478 22C23.478 20.8954 22.56 20 21.4276 20C20.2953 20 19.3773 20.8954 19.3773 22C19.3773 23.1046 20.2953 24 21.4276 24Z" />
                        </svg>
                    </Link>
                </div>
            </div>
            {isShowAlert && (
                <Alert
                    onClose={() => setIsShowAlert(false)}
                    {...alert}
                    title="Маркетплейс доступен после создания кошелька!"
                />
            )}
        </div>
    )
}

export default BottomBar
