export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="162"
        height="47"
        viewBox="0 0 162 47"
        fill="none"
    >
        <g filter="url(#filter0_d_2019_5339)">
            <path
                d="M0.255859 6.79785C0.255859 3.48414 2.94215 0.797852 6.25586 0.797852H144C147.314 0.797852 150 3.48414 150 6.79785V26.2585C150 29.5722 147.314 32.2585 144 32.2585H6.25585C2.94214 32.2585 0.255859 29.5722 0.255859 26.2585V6.79785Z"
                fill="url(#paint0_linear_2019_5339)"
                shape-rendering="crispEdges"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2019_5339"
                x="0.255859"
                y="0.797852"
                width="169.744"
                height="51.4604"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2019_5339"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2019_5339"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2019_5339"
                x1="66.8087"
                y1="-8.77714"
                x2="92.7455"
                y2="55.9969"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#96CBFC" stop-opacity="1" />
                <stop offset="1" stop-color="#07437A" stop-opacity="1" />
            </linearGradient>
        </defs>
    </svg>
)
