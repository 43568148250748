export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="183"
        height="64"
        viewBox="0 0 183 64"
        fill="none"
    >
        <g filter="url(#filter0_d_2005_20173)">
            <path
                d="M0.974579 6.87403C0.974579 3.56032 3.66087 0.874023 6.97458 0.874023H156.032C159.346 0.874023 162.032 3.56031 162.032 6.87402V37.4033C162.032 40.717 159.346 43.4033 156.032 43.4033H6.97458C3.66087 43.4033 0.974579 40.717 0.974579 37.4033V6.87403Z"
                fill="url(#paint0_linear_2005_20173)"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2005_20173"
                x="0.974579"
                y="0.874023"
                width="181.058"
                height="62.5293"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2005_20173"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2005_20173"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2005_20173"
                x1="166.793"
                y1="22.1387"
                x2="-3.44064"
                y2="22.1387"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.000564953" stop-color="#4596F7" />
                <stop offset="1" stop-color="#031381" />
            </linearGradient>
        </defs>
    </svg>
)
