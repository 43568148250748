import { useState } from 'react'
import { usePut } from '../../api/request'
import { useAlert } from '../../components/AlertNotification'
import ButtonFilled from '../../components/ButtonFilled'
import Header from '../../components/Header'
import Input from '../../components/Input'

import '../Verification/repeat.css'

export default function VerificationRepeat() {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({
        email: '',
    })
    const [sended, setSended] = useState(false)
    const put = usePut()
    const alert = useAlert()

    const sendVerEmail = () => {
        put(`auth/resendEmail?email=${email}`)
            .then((data) => {
                setSended(true)
            })
            .catch((err) => {
                console.log(err)
                if (err.response?.data?.message) {
                    alert({
                        status: 'error',
                        title: 'Ошибка !',
                        message: err.response?.data?.message,
                    })
                }
                setErrors((prev) => ({
                    ...prev,
                    ...(err.response.data?.validation || {}),
                }))
            })
    }

    return (
        <>
            <Header text={'Назад'} />
            <div className="verrep__wrap">
                {!sended ? (
                    <>
                        <h2 className="verrep__title">Укажите email</h2>
                        <p className="verrep__info">
                            Введите ваш электронный адрес и мы повторно отправим
                            вам ссылку для активации учетной записи.
                        </p>
                        <span className="verrep__input">
                            <Input
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                leftIcon="mail"
                                placeholder="Email"
                                error={errors.email}
                                type="email"
                            />
                        </span>
                        <ButtonFilled
                            text={'Получить ссылку'}
                            onClick={sendVerEmail}
                        />
                    </>
                ) : (
                    <>
                        <h2 className="verrep__title">
                            Электронное письмо отправлено
                        </h2>
                        <p className="verrep__info">
                            Мы отправили ссылку для активации учетной записи на
                            адрес {email}
                        </p>
                    </>
                )}
            </div>
        </>
    )
}
