import './styles.css'

const ButtonOutline = ({ text, onClick }) => (
    <button className="button-outline" onClick={onClick}>
        <div className="button-outline__wrap">
            <p className="button-outline__text" translate="no">
                {text}
            </p>
        </div>
    </button>
)

export default ButtonOutline
