import { useCallback, useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { v4 } from 'uuid'
import {
    confirmBuyFarm,
    confirmMainRedux,
    confirmSellBooster,
    confirmUpdateBoosterRedux,
    createBoosterRedux,
    selectBoosterRedux,
    selectedForBuyBoosterRedux,
    setAllBoosterRedux,
    setBoosterFarm,
    setReduxCollection,
    setSlideNumberRedux,
    setTokenBalanceRedux,
} from '../../redux/booster'
import { Pagination, Virtual } from 'swiper'
import ButtonFilled from '../../components/ButtonFilled'
import Booster from '../Booster'
import Header from '../../components/Header'
import Rang from '../../components/Rang'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import { useGet, usePost, usePut } from '../../api/request'
import Collection from '../Collection/Collection'
import Modal from '../../components/Modal/modal'
import Balance from '../../components/Balance'
import Alert from '../../components/Alert'
import { useAlert } from '../../components/AlertNotification'
import { useNavigate } from 'react-router-dom'
import { useBalance } from '../../Hooks/Hooks'

export const userTokenBalance = (tokenBalance, str, selectedBooster) => {
    if (str === 'all') {
        return tokenBalance.map((el) => (
            <div key={v4()} className="booster__price__table-item">
                <div className="booster__price__table-logowrap">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            `/img/token ${el?.token_name?.split(' ')[0]}.png`
                        }
                        alt={el?.token_name?.split(' ')[0]}
                        style={{ width: 30, height: 30 }}
                    />
                    <p>{el?.token_name?.split(' ')[0]} Rang Token</p>
                </div>
                <p>{el?.amount}</p>
            </div>
        ))
    } else {
        console.log(tokenBalance)
        return tokenBalance
            .map((el) => (
                <div key={v4()} className="booster__price__table-item">
                    <div className="booster__price__table-logowrap">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                `/img/token ${
                                    el?.token_name?.split(' ')[0]
                                } 1.png`
                            }
                            alt={el?.token_name?.split(' ')[0]}
                        />
                        <p>{el?.token_name?.split(' ')[0]} Rang Token</p>
                    </div>
                    <p>{el.amount}</p>
                </div>
            ))
            .filter((el, idx) => {
                return tokenBalance[idx].token_name === selectedBooster?.rang
            })
    }
}

const BoosterFerma = () => {
    const [loading, setLoading] = useState(false)
    const [slides, setSlides] = useState([])
    const [choosing, setChoosing] = useState(false)
    const [readyToBuy, setReadyToBuy] = useState(false)
    const [stateBuyFarm, setStateBuyFarm] = useState(0)
    const [enoughtMoney, setEnoughtMoney] = useState(false)
    const [activateBooster, setActivateBooster] = useState(false)
    const [deactivateBooster, setDeactivateBooster] = useState(false)
    const [deactivateMainBooster, setDeactivateMainBooster] = useState(false)
    const [fixMain, setFixMain] = useState(false)
    const [stopFarming, setStopFarming] = useState(false)
    const [boosterPrice, setBoosterPrice] = useState('')
    const [successSellBooster, setSuccessSellBooster] = useState(false)
    const { id } = useSelector((state) => state.auth.user)
    const {
        boosterFarms,
        collection,
        allBooster,
        slideNumber,
        layouts,
        selectedBooster,
        selectedForBuyBooster,
        tokenBalance,
        chooseBoosterOrder,
    } = useSelector((state) => state.booster)
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const alert = useAlert()
    const navigate = useNavigate()
    const { getBalance } = useBalance()

    const get = useGet()
    const post = usePost()
    const put = usePut()

    const getTokkenBalance = async () => {
        console.log()
        get('tokensBooster/currentUser').then((data) => {
            dispatch(
                setTokenBalanceRedux(
                    data.data.tokens.sort((a, b) => a.token_id - b.token_id)
                )
            )
        })
    }

    const getFreeBoosters = async () => {
        setLoading(true)
        const { boosters } = await get('farm/free')
            .then((data) => data)
            .catch((e) => {
                setLoading(false)
                console.log('SERVER ERROR :', e)
                alert({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
            })
            .finally()
        setLoading(false)
        dispatch(setReduxCollection(boosters))
    }

    const getBoosterCollection = useCallback(async () => {
        setLoading(true)
        const {
            data: { boosters },
        } = await get('boosters/myCollection')
            .then((data) => data)
            .catch((e) => {
                setLoading(false)
                console.log('SERVER ERROR :', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
            .finally(() => {
                setLoading(false)
            })
        dispatch(
            setReduxCollection([
                {
                    id: -1,
                    user_id: 1,
                    booster_star_id: 1,
                    is_main: 0,
                    date_remove: null,
                    date_update: null,
                    date_create: '2023-02-07T15:43:06.000Z',
                    cost_improvement: 0,
                    power: 10,
                    star: 1,
                    token_pumping_id: 1,
                    booster_id: 1,
                    rang: 'C rang',
                    price_pumping: 10,
                    isSelling: true,
                },
                ...boosters,
            ])
        )
    }, [])

    const getFarmsForUser = useCallback(async (id) => {
        setLoading(true)
        console.log(id)
        const data = await get(`farm/user/${id}`)
            .then((data) => {
                dispatch(setBoosterFarm(data.data.farms))
                setReadyToBuy(true)
                return data
            })
            .catch((e) => {
                console.log('SERVER ERROR :', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const getAllBooster = useCallback(async () => {
        get('boosters/')
            .then((data) => {
                const { boosters } = data.data
                dispatch(
                    setAllBoosterRedux(
                        boosters.sort((a, b) => a.token_id - b.token_id)
                    )
                )
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
    }, [])

    const buyFarm = useCallback(async () => {
        if (user?.wallet?.balance?.amount < 10000) {
            await dispatch(confirmBuyFarm(false))
            setEnoughtMoney(true)
            return
        }
        setLoading(true)
        await post('farm', { user_id: id })
            .then((data) => {
                setStateBuyFarm(1)
                getFarmsForUser(user.id)
            })
            .catch((e) => {
                console.log('ERROR', e)
                setStateBuyFarm(-1)
                // alert({ status: 'warning', title: 'Внимание!', message: e.response?.data?.message||'Попробуйте обновить через 5 минут или позже'});
            })
            .finally(() => {
                dispatch(confirmBuyFarm(false))
                getBalance()
                setLoading(false)
            })

        // await dispatch(setBoosterFarm([...boosterFarms, {
        //     mainBooster: {
        //         rang: 'S rang',
        //         star: 4,
        //         power: 1200,
        //         price_pumping: 100,
        //     },
        //     extraBoosters: [
        //         {
        //             rang: 'A rang',
        //             star: 5,
        //             power: 1200,
        //             price_pumping: 100,
        //         },
        //         {
        //             rang: 'B rang',
        //             star: 5,
        //             power: 1200,
        //             price_pumping: 100,
        //         },
        //         {
        //             rang: 'C rang',
        //             star: 5,
        //             power: 1200,
        //             price_pumping: 100,
        //         },
        //         {
        //             rang: 'S rang',
        //             star: 5,
        //             power: 1200,
        //             price_pumping: 100,
        //         },
        //         {
        //             rang: 'A rang',
        //             star: 4,
        //             power: 1200,
        //             price_pumping: 100,
        //         }
        //     ],
        //     infoPower:{
        //         collection: 1,
        //         main: 8,
        //         powerAll: 1299,
        //         percent: '% 0.11'
        //     }
        // }]))
    }, [boosterFarms])

    const buyBooster = async () => {
        const id = selectedForBuyBooster.id
        dispatch(selectedForBuyBoosterRedux(null))
        setLoading(true)
        await post(`boosters/${id}`)
            .then((data) => {
                alert({ status: 'success', title: data.message })
                getFarmsForUser(user.id)
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
        await updateStats()
        setLoading(false)
        navigate('/booster')
    }

    const setMainBooster = async (id) => {
        setLoading(true)
        const previousMain = boosterFarms[slideNumber]?.booster_main || false
        await put('farm/putMain', {
            farm_id: boosterFarms[slideNumber]?.id,
            booster_id: id,
        })
            .then((data) => {
                // alert({ status: 'success', title: data.message});
                if (id)
                    previousMain ? setFixMain(true) : setActivateBooster(true)
                else setDeactivateMainBooster(true)
                getFarmsForUser(user.id)
                getFreeBoosters()
                // if (previousMain)
                // req to throw previous to collection
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })

        await updateStats()
        setLoading(false)
    }

    const setExtraBooster = async (id, order) => {
        setLoading(true)
        await put('farm/putAdditional', {
            farm_id: boosterFarms[slideNumber]?.id,
            booster_id: id,
            order,
        })
            .then((data) => {
                if (id) setActivateBooster(true)
                else setDeactivateBooster(true)
                // alert({ status: 'success', title: data.message});
                getFarmsForUser(user.id)
                getFreeBoosters()
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
        await updateStats()
        setLoading(false)
        navigate('/booster')
    }

    const updateBooster = async () => {
        setLoading(true)
        const newBooster = {
            ...selectedBooster,
            star: selectedBooster.star + 1,
        }
        await put(`boosters/${selectedBooster.id}`)
            .then((data) => {
                alert({ status: 'success', title: data.message })
                dispatch(selectBoosterRedux(newBooster))
                getFarmsForUser(user.id)
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
        await updateStats()
        // console.log(collection)
        setLoading(false)
    }

    const createSlides = useCallback(
        (arr) => {
            if (boosterFarms?.length + 1 > 0) {
                let slidesTemp = []
                for (let i = 0; i < boosterFarms.length + 1; i++) {
                    if (i < boosterFarms.length) {
                        slidesTemp = [
                            ...slidesTemp,
                            <SwiperSlide key={v4()} virtualIndex={i + 1}>
                                <Booster number={i + 1} />
                            </SwiperSlide>,
                        ]
                    } else {
                        slidesTemp = [
                            ...slidesTemp,
                            <SwiperSlide key={v4()} virtualIndex={i + 1}>
                                <Booster number={i + 1} n={true} />
                            </SwiperSlide>,
                        ]
                    }
                }
                setSlides(slidesTemp)
            }
        },
        [boosterFarms]
    )

    const firstBulletStyle = () => {
        const arr = document.querySelectorAll('.swiper-pagination-bullet')
        const last = arr[arr.length - 1]
        const preLast = arr[arr.length - 2]
        preLast.innerHTML = ''
        last.classList.add('bullet_last')
        last.insertAdjacentHTML(
            'afterbegin',
            '<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 9C6.98528 9 9 6.98528 9 4.5C9 2.01472 6.98528 0 4.5 0C2.01472 0 0 2.01472 0 4.5C0 6.98528 2.01472 9 4.5 9ZM4.85714 5.10666H7V4.39237H4.85714V2.24951H4.14286V4.39237H2V5.10666H4.14286V7.24951H4.85714V5.10666Z" fill="#4596F7" fill-opacity="1"/></svg>'
        )
    }

    const bulletStyle = () => {
        const active = document.querySelector(
            '.swiper-pagination-bullet.swiper-pagination-bullet-active'
        )
        document
            .querySelectorAll('.near-active')
            .forEach((el) => el?.classList?.remove('near-active'))
        active?.previousElementSibling?.classList.add('near-active')
        active?.nextElementSibling?.classList.add('near-active')
    }

    const slideChange = (data) => {
        bulletStyle()
        dispatch(setSlideNumberRedux(data.activeIndex))
    }

    const createBoosterLayout = () => (
        <>
            <Header
                text={'Назад'}
                cb={() => dispatch(createBoosterRedux(false))}
            />
            <div className="booster__wrap">
                <div className="create_booster">
                    {allBooster?.map((el, idx) => (
                        <Rang
                            key={v4()}
                            icoSize={130}
                            rangS={allBooster[idx].rang
                                .split(' ')[0]
                                .toLowerCase()}
                            price={allBooster[idx].price_token}
                            onClick={() => {
                                dispatch(selectedForBuyBoosterRedux(el))
                            }}
                            cursor="pointer"
                        />
                    ))}
                </div>
                {tokenBalance && userTokenBalance(tokenBalance, 'all')}
            </div>
        </>
    )

    const showBoosterLayout = () => (
        <>
            <Header
                text={'Назад'}
                to={'/collection'}
                cb={() => {
                    dispatch(selectBoosterRedux(null))
                }}
            />
            <div className="booster__wrap">
                <Rang
                    showRang={true}
                    rangS={selectedBooster?.rang?.split('')[0].toLowerCase()}
                    power={{ count: selectedBooster?.power, x: 2 }}
                    main={!!selectedBooster?.is_main}
                    stars={selectedBooster?.star}
                    isSelling={selectedBooster?.isSelling}
                />
                <div className="booster__btns">
                    <div className="booster__btns__wrap">
                        {boosterFarms[slideNumber]?.booster_additional.find(
                            (el) => el?.booster?.id === selectedBooster?.id
                        ) ? (
                            <>
                                {selectedBooster?.star < 5 && (
                                    <ButtonFilledMin
                                        text={'Усилить'}
                                        bg="m"
                                        onClick={() => {
                                            updateBooster(selectedBooster?.id)
                                        }}
                                    />
                                )}
                                <ButtonFilledMin
                                    text={'Выключить'}
                                    bg="m"
                                    onClick={() => {
                                        setExtraBooster(
                                            null,
                                            chooseBoosterOrder
                                        )
                                        dispatch(selectBoosterRedux(null))
                                    }}
                                />
                                <ButtonFilled
                                    text={'Сделать основным'}
                                    bg="m"
                                    onClick={() => {
                                        setMainBooster(selectedBooster?.id)
                                        dispatch(selectBoosterRedux(null))
                                    }}
                                />
                            </>
                        ) : selectedBooster?.id ===
                          boosterFarms[slideNumber]?.booster_main?.id ? (
                            <>
                                {selectedBooster?.star < 5 && (
                                    <ButtonFilledMin
                                        text={'Усилить'}
                                        bg="m"
                                        onClick={() => {
                                            updateBooster(selectedBooster?.id)
                                        }}
                                    />
                                )}
                                <ButtonFilledMin
                                    text={'Выключить'}
                                    bg="m"
                                    onClick={() => {
                                        setMainBooster(null)
                                        dispatch(selectBoosterRedux(null))
                                    }}
                                />
                            </>
                        ) : (
                            // collection?.find(el => el?.id === selectedBooster?.id) &&
                            <>
                                {/* <ButtonFilledMin text={'Активировать'} bg='m' onClick={() => setActivateBooster(true)} /> */}
                                <ButtonFilledMin
                                    text={'Продать'}
                                    bg="m"
                                    onClick={() =>
                                        dispatch(confirmSellBooster(true))
                                    }
                                />
                                {selectedBooster?.star < 5 && (
                                    <ButtonFilledMin
                                        text={'Усилить'}
                                        bg="m"
                                        onClick={() => {
                                            updateBooster(selectedBooster?.id)
                                        }}
                                    />
                                )}
                            </>
                        )}

                        {/* <ButtonFilledMin text={'Усилить'} bg='m' onClick={() => dispatch(confirmUpdateBoosterRedux(true))} disabled={!(selectedBooster?.star < 5)} />				 */}
                    </div>
                </div>
                <div className="booster__price">
                    {selectedBooster?.star < 5 ? (
                        <p className="booster__price-count">
                            Цена усиления: {selectedBooster?.price_pumping}
                        </p>
                    ) : (
                        <p className="booster__price-count">
                            Бустер максимально улучшен
                        </p>
                    )}
                    <div className="booster__price__table">
                        {userTokenBalance(tokenBalance, '', selectedBooster)}
                    </div>
                </div>
            </div>
        </>
    )

    const ConfirmBuyBoosterLayout = () => {
        const haveToken = tokenBalance.filter(
            (el) => el.token_name === selectedForBuyBooster.rang
        )
        let res = null
        if (!haveToken.length) res = 0
        else
            res =
                +haveToken[0].amount >= +selectedForBuyBooster.price_token
                    ? 1
                    : 2

        const resObj = [
            <p>
                У вас нет в наличии {selectedForBuyBooster.rang.split(' ')[0]}RT
                токенов
            </p>,
            <p>
                С вашего баланса будет списанно{' '}
                {selectedForBuyBooster.price_token}{' '}
                {selectedForBuyBooster.rang.split(' ')[0]}RT
            </p>,
            <p>
                На вашем балансе не достаточно{' '}
                {selectedForBuyBooster.rang.split(' ')[0]}RT токенов{' '}
            </p>,
        ]

        return (
            <Modal
                btns={[
                    {
                        name: 'Подтвердить',
                        cb: buyBooster,
                        disabled: res !== 1,
                    },
                    {
                        name: 'Отменить',
                        cb: () => {
                            dispatch(selectedForBuyBoosterRedux(null))
                        },
                    },
                ]}
            >
                <div className="booster__message">{resObj[res]}</div>
            </Modal>
        )
    }

    const closeBuyModal = useCallback(() => {
        dispatch(confirmBuyFarm(false))
    }, [])

    const changeSellModal = (val) => {
        dispatch(confirmSellBooster(val))
    }

    const setPrice = (val) => {
        setBoosterPrice(val)
    }

    const sellBooster = () => {
        setSuccessSellBooster(true)
        changeSellModal(false)
    }

    const closeBuyFarmResult = () => {
        setStateBuyFarm(0)
    }

    const updateStats = async () => {
        await getTokkenBalance()
        // await getActiveBooster();
        await getFreeBoosters()
        // await getBoosterCollection()
        await getAllBooster()
    }

    const openBuyModal = useCallback(() => {
        if (readyToBuy) dispatch(confirmBuyFarm(true))
    }, [readyToBuy])

    const createFarmLayout = () => (
        <>
            <Modal
                btns={[
                    { name: 'Подтвердить', cb: buyFarm },
                    { name: 'Отменить', cb: closeBuyModal },
                ]}
                blackClick={true}
                showCb={closeBuyModal}
                closeBtn={true}
                bspan={
                    <p>
                        С вашего баланса
                        <br />
                        будет списанно 10 000 CRYG
                    </p>
                }
            >
                <div className="booster__message">
                    <p>Создать новую ферму?</p>
                    <h3>Цена: 10 000 CRYG</h3>
                </div>
            </Modal>
        </>
    )

    const sellBoosterLayout = (
        <>
            <Modal
                btns={[
                    { name: 'Подтвердить', cb: sellBooster },
                    { name: 'Отменить', cb: () => changeSellModal(false) },
                ]}
                showCb={changeSellModal}
                closeBtn={true}
            >
                <div className="booster__message">
                    <p>Установите цену продажи</p>
                    <input
                        className="modal-wallet__input"
                        type="text"
                        value={boosterPrice}
                        placeholder="Цена"
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
            </Modal>
        </>
    )

    useEffect(() => {
        // if (boosterFarms?.length) {
        createSlides()
        // }
    }, [boosterFarms])

    useEffect(() => {
        if (readyToBuy)
            if (!boosterFarms?.length | (slideNumber === boosterFarms?.length))
                dispatch(confirmBuyFarm(true))
    }, [slideNumber])

    useEffect(() => {
        getFarmsForUser(id)
        setTimeout(() => {
            updateStats()
        })
        return () => {
            setReadyToBuy(false)
        }
    }, [])

    useEffect(() => {
        console.log('bullet')
        setTimeout(() => bulletStyle(), 50)
        if (!layouts.chooseBooster)
            setTimeout(() => {
                firstBulletStyle()
            }, 100)
    })

    return (
        <>
            {loading && <Loader />}
            {layouts.confirmBuyFarm && <>{createFarmLayout()}</>}
            {layouts.confirmSellBooster && (
                <>
                    {sellBoosterLayout}
                    {}
                </>
            )}
            {enoughtMoney && (
                <Alert
                    title="На вашем балансе недостаточно средств!"
                    onClose={() => setEnoughtMoney(false)}
                />
            )}
            {activateBooster && (
                <Alert
                    title={
                        <>
                            Успех!
                            <br />
                            Бустер активирован
                        </>
                    }
                    onClose={() => setActivateBooster(false)}
                />
            )}
            {deactivateMainBooster && (
                <Alert
                    title={
                        <>
                            Успех!
                            <br />
                            Бустер выключен и отправлен в коллекцию
                        </>
                    }
                    onClose={() => {
                        setDeactivateMainBooster(false)
                        setStopFarming(true)
                    }}
                />
            )}
            {deactivateBooster && (
                <Alert
                    title={
                        <>
                            Успех!
                            <br />
                            Бустер выключен и отправлен в коллекцию
                        </>
                    }
                    onClose={() => setDeactivateBooster(false)}
                />
            )}
            {fixMain && (
                <Alert
                    title={
                        <>
                            Успех!
                            <br />
                            Вы заменили основной бустер
                        </>
                    }
                    onClose={() => setFixMain(false)}
                />
            )}
            {stopFarming && (
                <Alert
                    title={
                        <>
                            Внимание!
                            <br />
                            Фарминг остановлен. Для восстановления фарминга
                            установите бустер в основную панель.
                        </>
                    }
                    onClose={() => setStopFarming(false)}
                />
            )}
            {successSellBooster && (
                <Alert
                    title={
                        <>
                            Успех!
                            <br />
                            Бустер выставлен на продажу
                        </>
                    }
                    onClose={() => setSuccessSellBooster(false)}
                />
            )}
            {stateBuyFarm < 0 && (
                <Alert title="Произошла ошибка!" onClose={closeBuyFarmResult} />
            )}

            {stateBuyFarm > 0 && (
                <Alert
                    title="Успех!"
                    text="Вы расширили свои владения"
                    onClose={closeBuyFarmResult}
                />
            )}
            {layouts.chooseBooster ? (
                <Collection
                    choose={true}
                    title="Назад"
                    cb={(id, order = 0) =>
                        !order ? setMainBooster(id) : setExtraBooster(id, order)
                    }
                />
            ) : layouts.createBooster ? (
                <>
                    {createBoosterLayout()}
                    {selectedForBuyBooster && <ConfirmBuyBoosterLayout />}
                </>
            ) : selectedBooster ? (
                <>
                    {showBoosterLayout()}
                    {/* {layouts.confirmDisableMain && confirmDisableMainLayout}
                {layouts.confirmActiveBooster && confirmActiveBoosterLayout}
                {layouts.confirmUpdateBooster && <ConfirmUpdateBoosterLayout />} */}
                </>
            ) : (
                <>
                    <div className="main__wrap-balance">
                        <Balance />
                    </div>

                    {/* {layouts.confirmDisableMain && confirmDisableActiveLayout} */}
                    <Header text={'Booster ферма'} to={'/main'} />
                    <div className="booster__slider">
                        <div className="booster__slider__block">
                            <Swiper
                                modules={[Virtual, Pagination]}
                                pagination={{ clickable: true }}
                                spaceBetween={50}
                                slidesPerView={1}
                                onSlideChange={slideChange}
                                virtual
                                initialSlide={slideNumber}
                                className="boosters_swiper"
                            >
                                {slides}
                            </Swiper>
                        </div>
                        {/* <ButtonFilled text={'Просмотр коллекции'} onClick={() => navigate('/collection')} /> */}
                    </div>
                </>
            )}
        </>
    )
}

export default BoosterFerma
