import React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonOutline from '../../components/ButtonOutline'
import ButtonFilled from '../../components/ButtonFilled'
import './styles.css'

const Introduction = () => {
    const navigate = useNavigate()

    return (
        <div className="introduction">
            <div className="introduction__wrap-logo">
                <div className="introduction__shine" />
                <img
                    className="introduction__logo"
                    src="/img/logo.png"
                    alt="logo"
                />
                <img
                    className="introduction__logo-text"
                    src="/img/logo_text.png"
                    alt="logo text"
                />
            </div>
            <div className="introduction__wrap-btn">
                <ButtonOutline
                    text="Войти"
                    onClick={() => navigate('/auth/sign-in')}
                />
            </div>
            <ButtonFilled
                text="Зарегистрировать аккаунт"
                onClick={() => navigate('/auth/sign-up')}
            />
        </div>
    )
}

export default Introduction
