import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { formatMoney, formatClearMoney } from '../../helpers'
import './styles.css'

const Balance = () => {
    const user = useSelector((state) => state.auth.user)

    return (
        <RouterLink className="balance" to="/wallet">
            <svg
                className="balance__icon"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 8 122 35"
                fill="none"
            >
                <rect
                    x="3.97731"
                    y="14.3298"
                    width="95.4943"
                    height="20.696"
                    rx="10.348"
                    stroke="url(#paint0_linear_57_1227)"
                    strokeWidth="0.5"
                ></rect>
                <rect
                    x="0.6"
                    y="11.5355"
                    width="104.102"
                    height="26.1437"
                    rx="13.0719"
                    stroke="url(#paint1_linear_57_1227)"
                    strokeWidth="1.2"
                ></rect>
                <text
                    className="balance__text"
                    y="30"
                    x="50"
                    textAnchor="middle"
                >
                    {formatClearMoney(user?.wallet?.balance?.amount, 2)}
                </text>
                <ellipse
                    cx="99.3022"
                    cy="24.5546"
                    rx="15.714473051892519"
                    ry="17"
                    fill="url(#pattern1)"
                ></ellipse>
                <defs>
                    <pattern
                        id="pattern1"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                    >
                        <use
                            href="#image0_57_1227"
                            transform="translate(-0.461605) scale(0.00100167 0.000925926)"
                        ></use>
                    </pattern>
                    <linearGradient
                        id="paint0_linear_57_1227"
                        x1="102.559"
                        y1="24.6778"
                        x2="1.09573"
                        y2="24.6778"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.000564953" stopColor="#4596F7"></stop>
                        <stop offset="1" stopColor="#031381"></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_57_1227"
                        x1="108.414"
                        y1="24.6074"
                        x2="-2.88673"
                        y2="24.6074"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.000564953" stopColor="#4596F7"></stop>
                        <stop offset="1" stopColor="#031381"></stop>
                    </linearGradient>
                    <image
                        id="image0_57_1227"
                        width="1920"
                        height="1080"
                        href="/img/balance.png"
                    />
                </defs>
            </svg>
        </RouterLink>
    )
}

export default Balance
