import Header from '../../components/Header'
import TitleAuth from '../../components/TitleAuth'
import './agreement.css'

export default function Agreement() {
    return (
        <div className="agreement__wrap">
            <Header text={'Пользовательское соглашение CAPSULA'} />
            <br />
            <br />
            <br />
            <p className="agreement__wrap-right">
                Последнее обновление: 12 февраля 2023 г.
                <br />
                Пользовательское соглашение CAPSULA
            </p>
            <p className="agreement__wrap-middle">
                Пользовательское соглашение CAPSULA
            </p>
            <p>
                Перед использованием проекта CAPSULA (далее по тексту –
                «Проект»), пожалуйста, ознакомьтесь с условиями настоящего
                Соглашения и приложений к настоящему Соглашению (Приложение
                №1.Описание проекта CAPSULA;
                <br /> Приложение № 2. Условия участия в реферальной программе
                CAPSULA;
                <br /> Приложение № 3. Политика безопасности CAPSULA;
                <br /> Приложение № 4. Особенности функционирования криптовалют
                (монет, токенов) при использовании проекта CAPSULA), являющимися
                его неотъемлемыми частями (далее по тексту –«Соглашение»). Любое
                использование Вами Проекта означает полное и безоговорочное
                принятие Вами условий настоящего Соглашения. Если Вы не
                принимаете условия Соглашения в полном объеме, Вы не имеете
                права использовать проект в каких-либо целях.
            </p>
            <p>
                Данное Соглашение определяет порядок использования проекта
                CAPSULA посредством WEB приложения и иным доступным способом
                (далее - «Соглашение») заключается между Проектом CAPSULA (далее
                - «Компания», «мы»), и Вами (далее - «Пользователь»), именуемые
                в дальнейшем при совместном упоминании как «Стороны».
            </p>
            <p>
                Настоящее Соглашение является публичной офертой и размещено для
                ознакомления и принятия его условий при использовании проекта
                CAPSULA. Соглашение может быть изменено нами без какого-либо
                специального уведомления, новая редакция Соглашения вступает в
                силу с момента ее размещения по указанному в настоящем абзаце
                адресу, если иное не предусмотрено новой редакцией Соглашения.{' '}
            </p>
            <p className="select">
                Проект CAPSULA не является инвестиционным проектом. Проект
                CAPSULA, Пользователи проекта CAPSULA, в том числе
                пользователи-менеджеры CAPSULA, не занимаются инвестиционной
                деятельностью.
            </p>
            <p>
                Проект CAPSULA занимается экономической деятельностью, связанной
                с разработкой программного обеспечения для технологии блокчейн,
                взаимодействия с платформами блокчейн, созданием и эксплуатацией
                блокчейн-приложений для мобильных приложений и
                блокчейн-кошельков, и иной деятельностью в области
                информационных технологий
            </p>
            <br />
            <p>
                Проект CAPSULA представляет из себя веб-приложение, которое
                выполняет роль ядра экосистемы и позволяет добывать токен CRYG,
                хранить его, пользоваться приложениями и играми внутри
                экосистемы, приятно проводить своё время и зарабатывать на
                продвижении проекта.
            </p>
            <p className="select">
                С подробным описанием работы проекта CAPSULA Вы можете
                ознакомиться в Приложении № 1 к настоящему соглашению «Описание
                проекта CAPSULA».{' '}
            </p>
            <p className="select">
                Проект CAPSULA и пользователи-менеджеры CAPSULA не призывают к
                покупке криптовалюты. Однако Вы можете использовать веб
                приложение CAPSULA только являясь обладателем криптовалюты.
                Купить криптовалюту Вы можете любым доступным Вам способом.
            </p>
            <br />
            <p className="select">
                Использование проекта CAPSULA не гарантирует получение
                каких-либо доходов независимо от количества приобретенной Вами в
                целях использования проекта криптовалюты и количества новых
                Пользователей (рефералов), которых Вы пригласите для
                использования проекта CAPSULA.{' '}
            </p>
            <p className="select">
                Все пользователи проекта CAPSULA могут получать вознаграждение
                за использование программы только в криптовалюте.{' '}
            </p>
            <span className="select">
                <p className="select">
                    Проект CAPSULA — это программный продукт, который
                    представляет из себя веб-приложение, адаптированное под
                    мобильные устройства и персональные компьютеры (ПК).
                </p>
                <p className="select">
                    CAPSULA является многофункциональным программным
                    обеспечением, способна предоставить широкий спектр
                    возможностей для ее пользователя и состоит из следующих
                    продуктов:
                </p>
                <p className="select">
                    — сервис по заработку криптовалюты на играх разных форматов
                    и жанров;
                </p>
                <p className="select">
                    — сервис по использованию криптовалюты (возможность траты на
                    различные активы, которые могут приносить новые активы);
                </p>
                <p className="select">
                    — кастодиальный кошелек для хранения и отправки
                    криптовалюты, в том числе NFT;
                </p>
                <p className="select">— сервис для проведения турниров;</p>
                <p className="select">— Airdrop-сервис;</p>
                <p className="select">
                    — маркетплейс CANYON (обмен токенов/NFT/бустеров)
                </p>
            </span>
            <p>
                На момент публикации соглашения реализована площадка проекта
                CAPSULA с возможностью создания бустер ферм, стейкинга и добычи
                криптовалюты CRYG остальные направления находятся в разработке и
                ближайшей представленной разработкой будет реализация проведения
                турниров по киберспортивным направлениям Для добычи токена CRYG
                на площадку необходимо внести нативную монету DEL блокчейна
                decimalchain далее производится обмен монеты DEL на токен CRYG,
                с помощью которого осуществляются все операции внутри
                приложения. Обменный курс токена CRYG рассчитывается с помощью
                математических алгоритмов и зависит от баланса спроса и
                предложения на токен. Вывод монет DEL происходит также в личном
                кабинете пользователя на его кошельке через "обратную"
                конвертацию в нативную монету DEL по текущему курсу CRYG.
                <br />
                Компания не несет ответственности за функционирование систем,
                поддерживающих работу указанных монет, доступных в Проекте и
                рекомендует Пользователям ознакомиться с документацией,
                размещенной на сайтах монет, поддерживаемых проектом.
                <br />
                Проект CAPSULA обращает внимание Пользователя, что для
                приобретения криптовалюты и использования Программы,
                Пользователь вправе использовать по своему усмотрению и
                внутреннему убеждению биржевые площадки (обменники), доступные
                на момент покупки. Компания не советует и не рекомендует
                пользоваться услугами какой-либо конкретной платформы для
                покупки/обмена криптовалюты. Все сведения о биржевых площадках,
                размещенные на сайте, имеют исключительно информационный
                характер.
            </p>
            <p className="select">
                Решение о переводе криптовалюты в проект CAPSULA принимается
                Пользователем самостоятельно, посредством команды Компании о
                совершении соответствующего действия исходя из внутренних
                убеждений каждого Пользователя и с учетом его субъективной
                оценки текущей экономической ситуации.
            </p>
            <p className="select">
                Проект CAPSULA уведомляет Пользователей о том, что криптовалюта
                пользователей объединяется в пул, делегируется в валидатор и
                хранится на кошельках, принадлежащих Проекту CAPSULA.
            </p>
            <p className="select">
                Проект CAPSULA также уведомляет Пользователя о том, что
                вознаграждение за добычу криптовалюты перечисляется на кошельки,
                принадлежащие Проекту CAPSULA.{' '}
            </p>
            <p>
                Для использования проекта CAPSULA Вы должны соответствовать
                определенным требованиям, установленным в настоящем документе.
                Например, Вы должны быть совершеннолетними, не использовать
                программу в случаях, если вы являетесь резидентами юрисдикций, в
                которых программа не работает, запрещена и пр. Кроме того, есть
                определенные действия, которые запрещаются при использовании
                проекта CAPSULA, например, занятие незаконной деятельностью,
                предоставление ложной информации или иные действия, которые
                могут нанести ущерб нашим сервисам или системам.
                <br />
                Дополнительную информацию смотрите в условиях настоящего
                документа.
                <br />
                Деятельность пользователей проекта CAPSULA не может нарушать
                настоящее соглашение, а также местные законы страны проживания
                Пользователя, международные законы и правила.
                <br />
                Все данные, представляемые в пректе CAPSULA, не обязательно даны
                в режиме реального времени и могут не являться точными. Все цены
                (значения) включая данные, котировки, графики, в том числе
                указание на размер вознаграждения за добываемые монеты (прирост
                производительности добычи криптовалюты), процентов и дат
                обновлений и разработки новых продуктов.носят прогнозный и
                ориентировочный характер и могут быть изменены Компанией по
                своему усмотрению в одностороннем порядке.
            </p>
            Сделки с цифровыми активами (криптовалютами) в виду неопределенности
            правового режима цифровых активов во многих юрисдикциях могут быть
            рискованными. Пожалуйста, не используйте Проект если Вы не понимаете
            эти риски.Заключайте сделки с криптовалютами только теми средствами,
            которые Вы можете позволить себе потерять.
            <br />
            <p>
                Начиная использовать проект CAPSULA, пройдя процедуру
                регистрации, Пользователь считается принявшим условия Соглашения
                в полном объеме, без всяких оговорок и исключений. В случае
                несогласия Пользователя с какими-либо из положений Соглашения,
                Пользователь не вправе использовать проект CAPSULA. В случае
                если Компанией были внесены какие-либо изменения в Соглашение с
                которыми Пользователь не согласен, он обязан прекратить
                использование программы.{' '}
            </p>
            В случае несогласия с любым из условий, а также противоречия
            указанного документа нормам законодательства страны вашего
            местонахождения, Вы обязуетесь не использовать проект CAPSULA, в
            противном случае Вы самостоятельно несете все убытки и расходы,
            вызванные несоблюдением указанных выше документов как перед нами,
            так и перед третьими лицами.
            <br />
            <p>
                Мы рекомендуем Пользователям регулярно проверять условия
                настоящего документа на предмет его изменения и/или дополнения.
                Продолжение использования проекта CAPSULA после внесения
                изменений и/или дополнений в настоящий документ означает
                принятие и согласие Пользователя с такими изменениями и/или
                дополнениями.
                <br />
                Настоящее Соглашение регулируют использование проекта CAPSULA,
                заменяя собой все предыдущие письменные или устные
                договоренности по предмету настоящего Соглашения.
                <br />
                Компания и Пользователи, в том числе пользователи-менеджеры,
                являются независимыми договаривающимися сторонами и настоящее
                Соглашение не устанавливает и не имеет целью установить
                какие-либо агентские, партнерские отношения, отношения
                участников совместного предприятия, отношения между нанимателем
                и работником либо франшизодателем и франшизополучателем.
                <br />
                Ничто в Соглашении не может пониматься как установление между
                Пользователем и нами агентских отношений, отношений
                товарищества, отношений по совместной деятельности, отношений
                личного найма, либо каких-то иных отношений, прямо не
                предусмотренных Соглашением.
            </p>
            ПРОЙДЯ ПРОЦЕСС РЕГИСТРАЦИИ И НАЖАВ КНОПКУ "I AGREE" (Я СОГЛАСЕН), ВЫ
            ВЫРАЖАЕТЕ СВОЕ СОГЛАСИЕ НА ТО, ЧТО НАСТОЯЩЕЕ СОГЛАШЕНИЕ ОБ УСЛОВИЯХ
            ИСПОЛЬЗОВАНИЯ ПРОЕКТА CAPSULA, А ТАКЖЕ ДОКУМЕНТЫ, ВКЛЮЧЕННЫЕ В
            НАСТОЯЩЕЕ СОГЛАШЕНИЕ ПОСРЕДСТВОМ ССЫЛКИ (ДАЛЕЕ СОВМЕСТНО ИМЕНУЕМЫЕ –
            «УСЛОВИЯ ИСПОЛЬЗОВАНИЯ»), БУДУТ ЯВЛЯТСЯ ДЛЯ ВАС ЮРИДИЧЕСКИ
            ОБЯЗЫВАЮЩИМИ.
            <br />
            ЕСЛИ ВЫ ХОТИТЕ УЧАСТВОВАТЬ В ПРОЕКТЕ CAPSULA ВАМ НЕОБХОДИМО
            ОЗНАКОМИТЬСЯ С ПОЛИТИКОЙ КОНФЕДЕНЦИАЛЬНОСТИ И ДАТЬ СОГЛАСИЕ НА
            ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ.
            <br />
            <br />
            Вы всегда можете связаться с нами через службу поддержки, если у Вас
            есть какие-либо вопросы.
            <br />
            <p className="agreement__wrap-middle">ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>
            Приведенные в настоящем разделе Соглашения термины имеют следующее
            значение только для настоящего Соглашения и не могут толковаться
            иначе применительно к условиям, закрепленным в настоящем Соглашении.
            <br />
            <p>
                <span className="select">Airdrop</span> — мотивационная акция, в
                результате которой пользователи могут получить токены за
                активность в проектах.
            </p>
            <p>
                <span className="select">BOOST</span> — утилитарный токен
                проекта CAPSULA, предназначенный для операций внутри сервиса по
                торговле токенами и игровыми предметами на маркетплейсе CANYON.
            </p>
            <p>
                <span className="select">CANYON</span> — маркетплейс для
                торговли игровыми токенами и предметами с использованием токена
                BOOST.
            </p>
            <p>
                <span className="select">CRT, BRT, ART, SRT</span> — токены
                “Элементы” проекта CAPSULA, необходимые для поднятия уровня
                рангов бустеров.
            </p>
            <p>
                <span className="select">CRYG</span> — утилитарный токен проекта
                CAPSULA, который имеет ценность внутри самой площадки в качестве
                актива и может быть обменен на токен DEL для обеспечения
                маркетинговых потребностей проекта и достижения иных
                бизнес-целей.
            </p>
            <p>
                <span className="select">DEL</span> — это нативная монета
                блокчейна Decimal.
            </p>
            <p>
                <span className="select">GameFi</span> — это блокчейн-проекты,
                которые позволяют пользователям зарабатывать криптовалюту в
                процессе игры. Модель объединяет в себе игровую механику,
                элементы децентрализованных финансов (DeFi), невзаимозаменяемых
                токенов (NFT), а также, в некоторых случаях, метавселенные.
            </p>
            <p>
                <span className="select">NFT </span>— невзаимозаменяемый токен,
                каждый экземпляр которого уникален.
            </p>
            <p>
                <span className="select">Play-to-Earn </span>(P2E с англ.
                "играй, чтобы заработать") — термин из игровой индустрии, где
                геймеры могут зарабатывать криптовалюты и NFT-токены за счет
                своей игровой деятельности.
            </p>
            <p>
                <span className="select">ROOT</span> — утилитарный токен проекта
                CAPSULA, предназначенный для инвестиций в проект. Благодаря
                токену ROOT пользователь может получить долю в проекте.
            </p>
            <p>
                <span className="select">Блокчейн</span> — это
                децентрализованная база данных, в которой все записи (блоки)
                связаны между собой с помощью криптографических
            </p>
            алгоритмов.
            <br />
            <p>
                <span className="select">Бустер</span> — это внутриигровой
                предмет, предназначенный для функционирования бустер-фермы,
                используя который можно получать преимущества в пользовании
                продуктами CAPSULA.
            </p>
            <p>
                <span className="select">Farming</span> - процесс хранения
                криптовалюты в криптовалютном кошельке направленный на
                поддержание всех операций в блокчейн определенного вида
                криптовалюты, состоящий из блокировки определенного количества
                криптовалюты в целях получения наибольшего вознаграждения по
                системе Proof-of-stake (PoS).
            </p>
            <p>
                <span className="select">Криптовалютная транзакция</span> – это
                переход криптовалюты с одного адреса на другой в системе
                блокчейн.{' '}
            </p>
            <p>
                <span className="select">«Цифровые активы»</span> - это цифровое
                представление ценности, под которым понимается «криптовалюта»,
                «виртуальная валюта», «цифровая валюта» например, биткоин, эфир,
                или призм который основан на криптографическом протоколе
                компьютерной сети, может быть централизованным или
                децентрализованным, с закрытым или открытым исходным кодом и
                использоваться в качестве средства обмена и / или хранения
                ценности.
            </p>
            <p>
                <span className="select">Производные от Проекта материалы</span>{' '}
                — любая программа, работа, информация, разработанные
                Пользователем или третьим лицом с Использованием Программы или
                какой-либо ее части.
            </p>
            <p>
                <span className="select">Использование Проекта</span> — любые
                действия, связанные с функционированием Программы в соответствии
                с ее назначением.
            </p>
            <p className="agreement__wrap-middle">1. ПРЕДМЕТ СОГЛАШЕНИЯ</p>
            <p>
                В соответствии с условиями настоящего Соглашения, мы
                предоставляем Пользователю право использования проекта CAPSULA
                посредством использования веб-приложения CAPSULA, а также иным
                доступным способом на условиях, установленных настоящим
                соглашением.
                <br />
                Регистрируясь в проекте и используя проект любым иным доступным
                способом Пользователь выражает свое полное и безоговорочное
                согласие со всеми условиями Соглашения.
                <br />
                Использование Проекта на условиях и способами, не
                предусмотренными настоящим соглашения, возможно только на
                основании отдельного соглашения с нами. Условия настоящего
                Соглашения могут быть изменены нами без какого-либо специального
                уведомления, новая редакция вступает в силу с момента ее
                опубликования посредством мобильного приложения или иным
                доступным способом.
                <br />
                Использование проекта Пользователем предполагает его согласие на
                использование технической информации об устройстве, системе и
                прикладном программном обеспечении и периферийных устройствах
                Пользователя. Проект вправе собирать и использовать технические
                данные и связанную информацию для улучшения Программы или для
                предоставления услуг или технологий Пользователю.
                <br />
                <p className="agreement__wrap-middle">
                    2. СПОСОБЫ И ПОРЯДОК ИСПОЛЬЗОВАНИЯ ПРОЕКТА CAPSULA
                </p>
                Правообладатель предоставляет Пользователю право использовать
                Проект следующим способом:
                <br />
                - Применять Проект по прямому функциональному назначению, в
                целях чего произвести ее копирование и регистрацию с мобильного
                (ых) устройства (в) Пользователя. Пользователь вправе произвести
                установку Проекта на неограниченное число мобильных устройств,
                принадлежащих Пользователю.
                <br />
                - Проект должен использоваться под наименованием CAPSULA.
                Пользователь не вправе изменять и/или удалять наименование
                Проекта, знак охраны авторского права или иные указания на
                Правообладателя.
                <br />
                Особые условия использования программы установлены в следующих
                разделах проекта CAPSULA:
                <br />
                <span className="select">
                    - Кошелёк;
                    <br />
                    - Инструкции;
                    <br />
                    - Партнерская программа;
                    <br />
                    - Настройки;
                    <br />
                    - .Farming;
                    <br />
                    - Booster;
                    <br />
                    - Boosterpack;
                    <br />
                </span>
                - и др. разделах, доступных на сайте/веб-приложении CAPSULA.
                <br />
                Используя программу, Пользователь соглашается с особыми
                условиями, указанными в разделах, поименованных в настоящем
                пункте. Условия могут быть изменены Нами в одностороннем порядке
                без предварительного уведомления Пользователя.
                <br />
                Для использования проекта необходимо создать учетную
                запись(аккаунт).Для получения Аккаунта Пользователь обязан
                пройти регистрацию. Аккаунт является личной страницей и учетной
                записью Пользователя и может быть использован только
                Пользователем, которому этот Аккаунт был предоставлен. Каждому
                Аккаунту присваивается индивидуальный номер. Пользователи могут
                использовать только свои имена и личную информацию при
                регистрации аккаунта. Запрещается регистрация аккаунта на имя
                третьих лиц. Компания может по своему собственному усмотрению
                ограничить количество учетных записей Пользователя, которые он
                может регистрировать При создании учетной записи Пользователь
                соглашается:
                <br />
                - предоставлять точную и правдивую информацию;
                <br />
                - поддерживать и оперативно обновлять информацию об учетной
                записи;
                <br />
                -поддерживать безопасность вашей учетной записи, защищая свой
                пароль и ограничивая несанкционированный доступ третьих лиц к
                вашей учетной записи;
                <br />
                - незамедлительно уведомлять , в случае, Проект в случае
                обнаружения каких-либо нарушений безопасности, связанных с
                учетной записью;
                <br />
                - взять на себя ответственность за все действия, которые
                происходят на учетной записи, и принимать все риски любого
                разрешенного или несанкционированного доступа к учетной записи в
                максимально возможной степени, разрешенной законом.
                <br />
                Пользователь несет всю полноту ответственности за сохранение
                своих авторизационных данных в тайне. Любые действия с
                Пользователем, совершенные с использованием корректных
                авторизационных данных признаются совершенными Пользователем, за
                исключениями, случаев, установленных действующим
                законодательством.
                <br />
                Пользователь обязуется не предоставлять информацию о доступе к
                своему Аккаунту или сам Аккаунт третьим лицам или другим
                Пользователям.
                <br />
                В случае кражи или использования информации о доступе к Аккаунту
                или самого Аккаунта третьими лицами Пользователь несет бремя
                доказывания того, что это произошло не по его вине.
                <br />
                Пользователь несет ответственность за действия третьих лиц,
                получивших доступ к его Аккаунту.
                <br />
                Любые действия, совершенные с Аккаунта Пользователя считаются
                совершенными Пользователем, которому этот Аккаунт был
                предоставлен.Пользователь подтверждает, что деятельность,
                которая осуществляется под его учетной записью (включая, без
                ограничений, размещение любой информации о компании и продуктах,
                нажатие кнопок подтверждения согласия на любые Дополнительные
                Соглашения или правила, подписка или оплата любых услуг, отсылка
                электронных писем и прочее) будет расцениваться как
                санкционированная Пользователем.
                <br />
                Пользователь понимает и осознает, что передача учетной записи
                другим лицам, либо разрешение использования его личной учетной
                записи третьим лицам может нанести ущерб нам и/или другим
                Пользователям. В таких случаях Пользователь обязуется возместить
                нам и нашим аффилированным лицам, руководству, сотрудникам,
                агентам и нашим представителям любые убытки и ущерб (включая, но
                не ограничиваясь, недополученные доходы), понесенные в
                результате использования третьими лицами Вашей учетной записи.
                Пользователь также подтверждает, что в случае использования
                третьими лицами его личной учетной записи, либо нарушения по
                вине Пользователя сохранности данных его личной учетной записи,
                мы не несем ответственности за любые убытки и ущерб, понесенные
                из-за такого нарушения и имеем право приостановить или удалить
                учетную запись Пользователя без его согласия.
                <br />
                В случае утраты доступа к Аккаунту, мы предоставляем
                Пользователю возможность восстановления доступа к Аккаунту, в
                частности, следующими способами: <br />
                - путем ввода корректного кода восстановления пароля, ранее
                созданного Пользователем для данного Аккаунта при предоставлении
                Компанией такой возможности;
                <br />
                - путем ввода, запрошенного Пользователем у нас кода
                восстановления, направленного Пользователю в виде смс-сообщения
                на номер мобильного телефона, ранее привязанный Пользователем к
                данному Аккаунту;
                <br />
                - иным способом, согласованным нами с Пользователем.
                <br />
                Компания вправе отказать Пользователю в восстановлении доступа к
                Аккаунту, если:
                <br />
                - Пользователем предоставлены данные для восстановления доступа
                к Аккаунту, отличные от имеющихся у Компании полученных при
                идентификации Пользователя;
                <br />
                - по результатам рассмотрения соответствующего обращения для
                восстановления доступа к Аккаунту не будет подтверждена
                принадлежность данного Аккаунта обратившемуся за восстановлением
                доступа лицу;
                <br />
                - сведения о личности Пользователя, предоставленные при
                прохождении процедуры Идентификации, не совпадают с указанными
                сведениями, ранее предоставленными при прохождении процедуры
                идентификации в отношении того же Аккаунта.
                <br />
                Мы вправе осуществить Блокирование Аккаунта Пользователя:
                <br />
                - по инициативе уполномоченных государственных органов
                Блокирование Аккаунта осуществляется в случаях и порядке,
                установленном законодательством.
                <br />
                - по инициативе Пользователя Блокирование Аккаунта
                осуществляется в любой момент на основании полученного от
                Пользователя уведомления.
                <br />
                - по инициативе Компании Блокирование Аккаунта может быть
                осуществлено в случае наличия у нас подозрений в нарушении
                Пользователем порядка использования аккаунта, в том числе:
                <br />
                - в случае необходимости обеспечения нами сохранности данных
                аккаунта, доступ к которому осуществляется с использованием
                аккаунта, в отношении которого у Компании возникли подозрения в
                несанкционированном доступе;
                <br />
                - в случае наличия нестандартных или необычно сложных схем
                проведения операций, отличающихся от обычного порядка операций,
                характерных для Пользователей программы;
                <br />
                - в случае выполнения нами требований, установленных
                законодательством о противодействии легализации (отмыванию)
                доходов, полученных преступным путем, и финансированию
                терроризма;
                <br />
                - в случае предъявления нами Пользователю требования о
                прохождении Идентификации по любому из оснований,
                предусмотренных настоящим Соглашением;
                <br />
                - в случае наличия операций, возникших в результате
                несанкционированного доступа к Аккаунту.
                <br />
                - в случае наличия у идентифицированного Пользователя, чей
                Аккаунт заблокирован на любом основании, предусмотренном
                настоящим Соглашением, иных аккаунтов, Компания вправе
                произвести блокирование этих аккаунтов - в иных случаях,
                установленных настоящими Условиями;
                <br />
                Блокирование Аккаунта влечет прекращение всех оказываемых услуг.
                <br />
                Пользователь вправе потребовать удалить свой Аккаунт и
                персональные данные, направив соответствующее требование
                Администрации сайта по адресу электронной почты Службы поддержки
                Пользователей. В требовании должны быть указаны следующие
                сведения: Имя, Фамилия, логин, адрес электронной почты.
                Администрация сайта обязана уничтожить персональные данные и
                удалить аккаунт в срок, не превышающий тридцати дней с даты
                поступления указанного требования от Пользователя. Аккаунт и
                данные Пользователя удаляются без возможности восстановления.
                <br />
                Пользователь обязан при использовании Аккаунта соблюдать
                действующее законодательство, права и законные интересы нас и
                третьих лиц.
                <br />
                Пользователь обязан использовать Аккаунт только лично.
                Пользователь не вправе сообщать или иным образом передавать свои
                Авторизационные данные третьим лицам;
                <br />
                Пользователь обязан обеспечить хранение Авторизационных данных
                способом, исключающим возможность получения к ним доступа
                третьих лиц, в частности, не записывать Авторизационные данные
                таким образом, чтобы можно было определить, к чему эти
                Авторизационные данные относятся, не сохранять в электронном
                виде, в том числе, в специальных программах для хранения
                паролей. Пользователь обязан самостоятельно принимать все
                необходимые меры по сохранению конфиденциальности,
                предотвращению несанкционированного использования и защите
                своего Аккаунта от несанкционированного доступа со стороны
                третьих лиц.
                <br />
                Пользователь обязан перед вводом пароля удостовериться, что за
                ним не ведется наблюдение, в том числе с использованием
                технических средств;
                <br />
                Пользователь обязан перед вводом пароля удостовериться
                Пользователь обязан обеспечить антивирусную безопасность
                устройства, используемого для доступа к Аккаунту;
                <br />
                Пользователь не вправе использовать Аккаунт для осуществления
                противоправных (имеющих определяемые по исключительному нашему
                усмотрению признаки состава преступления, административного
                правонарушения, неисполнения гражданско-правовых обязательств
                перед любыми третьими лицами, легализации (отмывания) доходов,
                полученных преступным путем, или финансирования терроризма)
                действий, в том числе:
                <br />
                - Не использовать Аккаунт для систематического аккумулирования и
                дальнейшего распределения денежных средств среди нескольких
                получателей/систематического перевода на собственные
                реквизиты/получения наличных денежных средств/иных операций,
                имеющих признаки транзита; - Не использовать Аккаунт для
                предоставления или сбора денежных средств, материальных
                ценностей для финансирования/снабжения организованной группы,
                незаконного вооруженного формирования, преступного сообщества
                (преступной организации);
                <br />
                - Не использовать Аккаунт для совершения операций
                оплаты/получения оплаты в отношении объектов, изъятыми из
                оборота или ограниченными в обороте;
                <br />
                - Не использовать Аккаунт с целью хищения/сокрытия чужих
                денежных средств и материальных ценностей;
                <br />
                - Не использовать Аккаунт для совершения любых операций,
                связанных с распространением информации, товаров, работ, услуг,
                пропагандирующих ненависть, насилие, расовую или религиозную
                нетерпимость, нацистскую или сходную атрибутику и/или символику,
                побуждающих к свержению органов власти и изменению
                государственного строя, к незаконному уничтожению собственности,
                мятежу, массовым беспорядкам, осуществлению иной экстремистской
                деятельности;
                <br />
                - Не использовать Аккаунт для совершения любых операций, прямо
                или косвенно связанных с финансированием терроризма;
                <br />
                - Не использовать Аккаунт в целях совершения любых операций,
                нарушающих или посягающих на нарушение любых интеллектуальных
                прав;
                <br />
                Пользователь не вправе использовать Аккаунт для финансирования
                оккультно-религиозных, оккультно-философских, мистических и иных
                подобных объединений, способствующих распространению
                деструктивных культов, проявлению религиозного, этнического,
                конфессионального экстремизма;
                <br />
                Пользователь не вправе использовать Аккаунт для сбора денежных
                средств с целью финансирования политической деятельности
                физических лиц, юридических лиц, в том числе некоммерческих и
                общественных организаций;
                <br />
                Пользователь не вправе использовать Аккаунт для совершения любых
                операций, связанных с деятельностью по привлечению денежных
                средств с обещанием выплаты дохода в объемах, сопоставимых с
                объемами привлеченных денежных средств, а также организации
                такой деятельности, включая организацию/распространение
                информации об участии в инвестиционных пирамидах и схемах,
                матричных программах, других аналогичных схемах быстрого
                обогащения или программах сетевого маркетинга, иных программах,
                по своему содержанию направленных на хищение чужого имущества
                или приобретение права на чужое имущество путем обмана или
                злоупотребления доверием;
                <br />
                Пользователь не вправе использовать программы и применять иные
                меры, позволяющие Пользователю скрыть от Компании технические
                характеристики подключения к сети Интернет для использования
                Аккаунта;
                <br />
                Пользователь не вправе совершать действия, имеющие целью или
                результатом нарушение нормального функционирования оборудования
                и программного обеспечения Компании;
                <br />
                Пользователь не вправе вносить какие-либо изменения в
                программное обеспечение Аккаунта и/или любую его часть своими
                силами или с привлечением третьих лиц, а также использовать
                какие-либо средства автоматизированного доступа к Аккаунту, если
                иное не согласовано с Проектом;
                <br />
                Пользователь не вправе использовать при управлении Аккаунтом
                номера телефона, логины, программы, устройства, иные клиентские
                идентификаторы, связанные прямо или косвенно с Пользователями,
                ранее нарушавшими условия настоящего Соглашения.
                <br />
                Пользователь при регистрации, прохождении Упрощенной
                идентификации обязан указывать принадлежащие ему действительные
                данные.
                <br />
                Наличие в действиях Пользователя признаков нарушения порядка
                использования Аккаунта определяется по усмотрению Проекта.
                <br />
                В случае попытки со стороны Пользователя использовать свои права
                доступа к программе для создания препятствий в бесперебойной
                работы программы, а также при систематическом нарушении
                настоящего Соглашения (2 и более) Пользователю может быть
                отказано в доступе к программе как с правом восстановления
                доступа, так и без права на восстановление доступа.
                <br />
                Запрещено использовать Аккаунт для передачи денежных средств
                третьим лицам, путем передачи самого Аккаунта или доступа к
                нему.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                3. ПРАВА И ОБЯЗАННОСТИ СТОРОН
            </p>
            <p>
                <span className="select">3.1.</span>Проект имеет право:
                <br />
                <span className="select">3.1.1.</span> Использовать полученную
                от Пользователя информацию для улучшения Программы, в том числе,
                для информирования Пользователя о произведенных улучшениях,
                обновлениях;
                <br />
                <span className="select">3.1.2.</span> Проект вправе передавать
                права и обязанности по настоящему Соглашению, третьим лицам в
                целях исполнения настоящего Соглашения без дополнительного
                согласия Пользователя.
                <br />
                <span className="select">3.1.3.</span> Проект вправе удерживать
                в свою пользу часть вознаграждения за добычу (генерацию)
                криптовалюты, выдаваемого разработчиками другой программы
                (криптовалютюты), например, разработчиками Программы
                «Decimalchain».
                <br />
                <span className="select">3.1.4.</span> Проект вправе в
                одностороннем порядке прекратить функционирование Программы,
                обеспечив возврат переданной Пользователем в майнинговый пул
                криптовалюты.
                <br />
                <span className="select">3.2.</span>Компания обязуется:
                <br />
                <span className="select">3.2.1.</span> предоставить Пользователю
                доступ к Системе клиентской помощи (службы поддержки).
                <br />
                <span className="select">3.3.</span> Пользователь имеет право:
                <br />
                <span className="select">3.3.1.</span> получить доступ к
                использованию Приложения после соблюдения требований об условиях
                использования Приложения;
                <br />
                <span className="select">3.3.2.</span>пользоваться Приложением
                исключительно в целях и порядке, предусмотренных Соглашением и
                не запрещенных законодательством
                <span className="select">3.3.3.</span> требовать возврата
                перечисленных цифровых активов не ранее чем через одни сутки с
                момента их зачисления на электронный кошелек CAPSULA.
                <br />
                <span className="select">3.4.</span>Пользователь обязуется:
                <br />
                <span className="select">3.4.1.</span> Использовать Программу
                только тем способом, который указан в настоящем Соглашении
                <br />
                <span className="select">3.4.2.</span> не передавать третьим
                лицам права, предоставленные ему Компанией по настоящему
                Соглашению.
                <br />
                <span className="select">3.4.3.</span> Самостоятельно
                обеспечивать неразглашение (тайну) данных, хранящихся в учетной
                записи, в том числе пароля, и несет ответственность за их
                разглашение любым способом, в том числе за все риски и убытки,
                возникшие в связи с этим. Разглашение данных учетной записи
                является существенным нарушением настоящего Соглашения.
                Пользователь несет ответственность за все действия, совершенные
                под его учетной записью, и с использованием его логина и пароля,
                а также за все действия, совершенные с его Мобильного
                устройства, на котором используется Приложение. Пользователь
                обязуется незамедлительно уведомлять Нас обо всех случаях
                нарушения безопасности и несанкционированного доступа к учетной
                записи. При невыполнении Пользователем данной обязанности
                Пользователь самостоятельно и в полном объеме несет
                ответственность за действия, совершенные под его учетной
                записью. Для проведения проверки учетная запись может
                подвергнуться Компанией технической блокировке.
                <br />
                <span className="select">3.4.4.</span> Не вводить в заблуждение
                других Пользователей и третьих лиц.
                <br />
                <span className="select">3.4.4.</span> не распространять
                посредством Приложения спам, сообщения-цепочки (сообщения,
                требующие их передачи одному или нескольким пользователям),
                схемы финансовых пирамид или призывы в них участвовать, а также
                любую другую навязчивую информацию;
                <br />
                <span className="select">3.4.5.</span> При регистрации и
                создании учетной записи Пользователь обязуется предоставить
                Компании данные (реквизиты, номер) принадлежащего ему
                электронного кошелька, зарегистрированного с использованием
                сторонних сервисов, неконтролируемых Компанией, в целях
                обеспечения возможности возврата криптовалюты в предусмотренных
                соглашением случаях.
                <br />
                <span className="select">3.5.</span>Пользователь несет
                персональную ответственность за ознакомление с применимыми
                законами и нормативными правовыми актами в их соответствующей
                юрисдикции для подтверждения, что использование программы
                ведется в соответствие с ними.
                <br />
                <span className="select">3.6.</span> Пользователям запрещено
                распространять о проекте CAPSULA информацию следующего характера
                (включающую следующие высказывания/суждения):
                <br />
                - «обещание третьим лицам получения высокой доходности (прибыли,
                денежных средств, заработка) при использовании проекта CAPSULA»;
                <br />
                - «призывы третьих лиц к вложению инвестиций в проект CAPSULA, а
                также гарантии о возврате инвестиций/вложений»;
                <br />
                - «призывы к внесению денег/денежных средств»;
                <br />
                - «обещание гарантированных реферальных вознаграждений»;
                <br />
                - а также иной информации схожей по содержанию с указанными в
                настоящем пункте положениями.
                <br />
                <span className="select">3.6.1.</span> За каждый факт нарушения
                п. 3.6. Компания вправе удержать с Пользователя в одностороннем
                прядке штраф в размере одной тысячи евро. Компания вправе
                удержать монеты (критовалюту) в соответствующем размере с
                личного кошелька Пользователя,зарегистрированного в проекте
                CAPSULA а также заблокировать доступ к аккаунту и ограничить
                использование программы.
                <br />
                Пользователь проекта CAPSULA подтверждает и гарантирует, что:
                <br />
                <span className="select">3.7.1.</span> достиг совершеннолетия
                (не менее 18 лет) и являетесь дееспособными физическими лицами;
                обладает необходимой право- и дееспособностью, а равно всеми
                правами и полномочиями, необходимыми и достаточными для
                заключения и исполнения настоящего документа в соответствии с
                его условиями.
                <br />
                <span className="select">3.7.2. </span>ранее не был удален из
                числа пользователей программы;
                <br />
                <span className="select">3.7.3. </span>имеет необходимые права,
                полномочия и разрешения для заключения настоящего соглашения и
                при этом не нарушаете (не будете нарушать) какое-либо другое
                соглашение, участником которого вы являетесь;
                <br />
                <span className="select">3.7.4.</span> не будет использовать
                программу, если какое-либо применимое к его правовому статусу
                законодательство запрещает ее применение.
                <br />
                - не имеете выгодоприобретателей и бенефициарных владельцев и
                действует только в своих личных интересах.
                <br />
                «CAPSULA» и соответствующие этому наименованию значки и логотипы
                являются зарегистрированными товарными знаками.
                Несанкционированное копирование, изменение, использование или
                публикация этих марок строго запрещены.Пользователи не могут
                использовать торговые марки CAPSULA без явного указания на
                разрешение и без получения письменного согласия CAPSULA.
                <br />
                Настоящее соглашение устанавливает штраф (денежное взыскание) в
                размере 3 000 (три тысячи) евро за каждый факт неправомерного
                использования товарного знака CAPSULA.
                <br />
                Неправомерным использование является любое использование слова
                «CAPSULA» и соответствующих этому наименованию значков и
                логотипов проекта CAPSULA.
                <br />
                Проект оставляет за собой право на удержание с личного аккаунта
                Пользователя, зарегистрированного в проекте CAPSULA в
                одностороннем порядке суммы штрафа за каждый факт неправомерного
                использования товарного знака CAPSULA на основании уведомления,
                направляемого на электронную почту Пользователя и содержащего
                доказательства неправомерного использования (фото/видео фиксация
                нарушения и иные доказательства). Стороны признают допустимость
                и достаточность таких доказательств. <br />
                Пользователи несут ответственность за условия оплаты
                страхования, сборов, налогов и иных обязательных платежей,
                подлежащих оплате в результате использования Проекта CAPSULA, в
                том числе пользователи несут обязанность по уплате налогов на
                сделки с цифровыми активами (криптовалютами) в соответствии с их
                личным законом и с учетом статуса резидента соответствующего
                государства.
                <br />
            </p>
            <p className="agreement__wrap-middle">4. ОГРАНИЧЕНИЯ</p>
            <p>
                <span className="select">4.1.</span> За исключением
                использования в объемах и способами, прямо предусмотренными
                настоящим Соглашением, Пользователь не имеет права изменять,
                декомпилировать, дизассемблировать, дешифровать и производить
                иные действия с объектным кодом Проекта, имеющие целью получение
                информации о реализации алгоритмов, используемых в Проекте,
                создавать производные произведения с использованием Проекта, а
                также осуществлять (разрешать осуществлять) иное использование
                Программы, без письменного согласия Проекта.
                <br />
                <span className="select">4.2.</span> Пользователь не имеет право
                воспроизводить и распространять Проект в коммерческих целях (за
                плату), в том числе в составе сборников программных продуктов,
                без письменного согласия Компании.
                <br />
                <span className="select">4.3.</span> Пользователь не имеет права
                распространять Проект в виде, отличном от того, в котором он ее
                получил, без письменного согласия Компании.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                5. КОНФИДЕНЦИАЛЬНОСТЬ И ПЕРСОНАЛЬНЫЕ ДАННЫЕ
            </p>
            <p>
                <span className="select">5.1.</span>Пользователь обязуется не
                разглашать Конфиденциальную информацию, защищать и предотвращать
                несанкционированное разглашение Конфиденциальной информации и
                предпринимать соответствующие меры по охране Конфиденциальной
                информации.
                <br />
                <span className="select">5.2.</span>Стороны гарантируют
                конфиденциальность всей информации (устной или письменной),
                касающейся бизнеса другой стороны, за исключением информации,
                которая уже доступна общественности.
                <br />
                <span className="select">5.3.</span>В случае если
                Конфиденциальная информация станет доступна третьим лицам по
                вине Пользователя без письменного согласия Компании,
                Пользователь обязуется компенсировать все понесенные Проектом в
                результате этого убытки.
                <br />
                <span className="select">5.4.</span>Проект гарантирует, что
                персональные данные и иная частная информация, предоставленная
                Пользователем во исполнение раздела 1 настоящего Соглашения,
                будет использоваться Компанией исключительно в целях исполнения
                настоящего Соглашения. Заключая данное Соглашение, Пользователь
                предоставляет Компании право на обработку и поручение сбора и
                обработки персональных данных третьему лицу, с которым Компания
                заключает договор об обработке персональных данных (в том числе,
                но не исключительно: сбор, систематизацию, накопление, хранение,
                уточнение, обновление, изменение, использование,
                внутрироссийскую передачу) своих персональных данных и иной
                частной информации любым не противоречащим действующему
                российскому законодательству способом (в том числе, без
                использования средств автоматизации) в целях исполнения ими
                взятых на себя договорных обязательств.
                <br />
            </p>
            <p className="agreement__wrap-middle">6. ГАРАНТИИ</p>
            <p>
                <span className="select">6.1.</span> Веб-приложение
                предоставляется на условиях "как есть" (as is) со всеми
                возможными неисправностями и в том виде, в котором она
                существует на момент принятия настоящего Соглашения. Проект не
                предоставляет никаких гарантий в отношении безошибочной и
                бесперебойной работы Проекта или отдельных его компонентов,
                соответствия Проекта конкретным целям Пользователя, а также не
                предоставляет никаких иных гарантий, прямо не указанных в
                настоящим Соглашением
                <br />
                <span className="select">6.2.</span> В максимальной степени,
                допустимой действующим законодательством, Проект, равно как и ее
                партнеры, не несут никакой ответственности за какие-либо прямые
                или косвенные последствия какого-либо использования или
                невозможности использования Проекта и/или ущерб, причиненный
                Пользователю и/или третьим лицам в результате какого-либо
                использования или неиспользования Проекта, или отдельных его
                компонентов, в том числе из-за возможных ошибок или сбоев в их
                работе.
                <br />
                <span className="select">6.3.</span>Проект ни при каких
                обстоятельствах не предоставляет никаких гарантий в отношении
                безошибочной и бесперебойной работы Веб-приложения, а также не
                гарантирует, что Проект достаточным образом удовлетворит
                потребности Пользователя и отказывается от любых иных гарантий в
                той мере, в какой это допускает действующее законодательство.
                <br />
                <span className="select">6.4.</span>Все претензии, связанные с
                использованием/невозможностью использования Проекта, а также
                возможными фактами нарушения в результате использования Проекта
                законодательства и/или прав третьих лиц, должны направляться нам
                по адресуслужбы поддержки Пользователей.
                <br />
            </p>
            <p className="agreement__wrap-middle">7. ОТВЕТСТВЕННОСТЬ</p>
            <p>
                <span className="select">7.1.</span>Весь риск, возникающий в
                связи с Использованием Проекта или работой с ней, ложится на
                Пользователя, в том числе риск неполучения ожидаемой прибыли от
                Использования Проекта, риск сбоя работы программного обеспечения
                после установки Веб-приложения и т.д.
                <br />
                <span className="select">7.2.</span>Проект не гарантирует, что
                Приложение и его отдельные элементы не содержат ошибок и будут
                функционировать в соответствии с ожиданиями Пользователя.
                <br />
                <span className="select">7.3.</span>Ни при каких обстоятельствах
                Проект не несет перед Пользователем ответственность за любые
                убытки (включая, но не ограничиваясь только перечисленным,
                упущенную выгоду, утрату конфиденциальной или иной информации,
                убытки, вызванные прерыванием коммерческой деятельности потерю
                прибыли, деловой репутации или данных, упущенные коммерческие
                возможности, потерю оборота, особые, случайно возникшие,
                непрямые, косвенные или штрафные потери и убытки), возникшие в
                связи с Использованием Проекта или невозможностью Использования
                Проекта.
                <br />
                <span className="select">7.4.</span>Компания не несет
                ответственности за любые ошибки, упущения, прерывания, удаление,
                дефекты, задержку в обработке или передаче данных, сбое линий
                связи, кражу, уничтожение или неправомерный доступ к материалам
                пользователей, размещенным в Приложении или в любом другом
                месте.
                <br />
                <span className="select">7.5.</span>В случае если
                законодательство страны Пользователя не допускает ограничения
                ответственности или ответственность Проекта будет установлена
                соответствующим решением компетентного суда, Проект будет нести
                ответственность только за реальный ущерб, понесенный
                Пользователем от Использования Программы, если причинение такого
                ущерба связано с виновными действиями Проекта, или если ущерб
                возник вследствие причин, о которых Проект знал или должна была
                знать. При этом максимальный размер ответственности Компании
                ограничивается одной тысячей евро.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                8. СРОК СОГЛАШЕНИЯ И ПРЕКРАЩЕНИЕ ЕГО ДЕЙСТВИЯ
            </p>
            <p>
                <span className="select">8.1.</span> Соглашение вступает в силу
                с момента выполнения всех условий, предусмотренных разделом 1
                настоящего Соглашения, то есть с момента установки (скачивания)
                Программы CAPSULA и действует до момента его расторжения.
                Соглашение расторгается немедленно в момент нарушения
                Пользователем любого из условий настоящего Соглашения без
                дополнительного уведомления со стороны Компании, при этом
                Пользователь обязуется незамедлительно удалить Программу, все ее
                копии, модификации, модернизации и Производные от Программы
                материалы.
                <br />
                <span className="select">8.2.</span>Настоящее Соглашение
                действует на территории всего мира. Пользователь имеет право в
                любое время отказаться от использования Программы CAPSULA,
                расторгнув Соглашение и удалив Программу, все ее копии,
                модификации, модернизации и Производные от Программы материалы,
                и направив соответствующее уведомление Проекту. Соглашение будет
                считаться расторгнутым с момента получения Компанией уведомления
                Пользователя.
                <br />
                <span className="select">8.3.</span>Во всех случаях при
                расторжении Соглашения и прекращения действия Соглашения
                Пользователь не вправе требовать любой компенсации.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                9. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА
            </p>
            <p>
                <span className="select">9.1.</span>Ни одна из Сторон не несет
                ответственность за нарушение обязательств, являющихся
                результатом непредвиденных обстоятельств, таких как пожар,
                забастовки, восстание или массовые беспорядки, эмбарго,
                катастрофы, задержка в транспортировке, директивы гражданской
                или военной власти.
                <br />
                <span className="select">9.2.</span>Каждая из Сторон соглашается
                незамедлительно сообщить о форс-мажорных обстоятельствах другой
                стороне. Такое сообщение должно содержать детальную информацию о
                том, что послужило их причиной.
                <br />
                <span className="select">9.3.</span>Если несоблюдение правил
                одной стороной по причине форс-мажорных обстоятельств
                продолжается более 4 (четырех) недель, тогда другая сторона
                имеет право расторгнуть данное Соглашение путем отправления
                другой стороне на адрес электронной почты уведомления о
                расторжении настоящего Соглашения. Ни одна из Сторон не имеет
                каких-либо обязательств перед другой стороной при расторжении
                Соглашения в результате форс-мажорных обстоятельств.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                10. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
            </p>
            <p>
                <span className="select">10.1.</span>Стороны признают
                обязательный досудебный порядок разрешения споров. Пользователь
                соглашается, что любой спор, возникающий в связи с
                использованием программы будет рассматриваться в соответствии
                настоящим Соглашением, и что Проект будет обладать всеми правами
                и полномочиями в отношении разрешения такого спора. <br />
                Пользователь должен уведомить Компанию о наличии спора.
                <br />
                По получении уведомления о споре, которое направляется в службу
                поддержки.
                <br />
                Проект вправе затребовать от Пользователя представления
                подтверждающих документов.
                <br />
                Настоящим Пользователь соглашается с тем, что Проект будет
                вправе по своему исключительному усмотрению отклонить или
                принять любой подтверждающий документ.
                <br />
                Настоящим Пользователь также признает, что Проект не является
                судебным или арбитражным учреждением и выносит решение
                исключительно как непрофессиональное лицо. Настоящим
                Пользователь соглашается не возлагать ответственность на Проект
                и ее аффилированных лиц за какие-либо материалы, являющиеся
                неверными или вводящими в заблуждение.
                <br />
                Срок рассмотрения уведомления 30 календарных дней.
                <br />
                Срок вынесения Проектом решения по спору 60 календарных дней.
                <br />
                Мы информируем пользователей, что использование программы
                регулируется законодательством Англии и Уэльса.
                <br />
                <span className="select">10.2.</span>Пользователь признает, что
                Проект может понести ущерб, если условия данного Соглашения не
                соблюдаются, и потому Пользователь соглашается с тем, что Проект
                наделён правом использовать любые формы защиты своих нарушенных
                прав и законных интересов, в том числе, и такую форму как
                непосредственное обращение в соответствующий суд без соблюдения
                претензионного порядка.
                <br />
                <span className="select">10.3.</span>Все споры, возникающие из
                существа настоящего Соглашения или связанные с ним, его
                толкованием, исполнением, расторжением, прекращением и
                утверждениями о его недействительности во всех случаях
                рассматриваются в соответствии с материальным правом Англии и
                Уэльса.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
            </p>
            <p>
                <span className="select">11.1.</span>Данное Соглашение
                составлено с учетом действующего законодательства Англии и
                Уэльса. Правом, применимым к толкованию настоящего Соглашения, а
                также всем отношениям, вытекающим из существа настоящего
                Соглашения, является материальное право Англии и Уэльса.
                <br />
                <span className="select">11.2.</span>Если любое условие данного
                Соглашения по той или иной причине теряет юридическую силу или
                становится неосуществимым или признается недействительным по
                решению компетентного суда, то оно рассматривается как
                невключенное в текст настоящего Соглашения, что, однако, никоим
                образом не влияет на законность и обязательность выполнения
                других условий.
                <br />
                <span className="select">11.3.</span> Заголовки разделов по
                тексту Соглашения приведены исключительно для удобства и не
                имеют самостоятельной юридической силы, не могут толковаться
                применительно к условиям Соглашения.
                <br />
                <span className="select">11.4.</span>Пользователь подтверждает,
                что до начала использования программы CAPSULA он прочитал
                настоящее Соглашение, понимает его полностью и согласен
                выполнять его условия.
                <br />
                <span className="select">11.5.</span>Стороны признают
                юридическую силу документов, отправленных посредством
                электронной связи. Документы, исходящие от Проекта CAPSULA,
                будут считаться совершенными в надлежащей письменной форме, если
                они отправлены с электронных адресов, указанных в приложении
                CAPSULA.Корреспонденция будет считаться направленной по
                надлежащему адресу, если она направлена по указанным в
                приложении CAPSULA электронным адресам Проекта.
                <br />
                Приложение №1.Описание программы CAPSULA.
                <br />
                Приложение №2.Условия участия в реферальной программе CAPSULA.
                <br />
                Приложение №3 Особенности функционирования криптовалют (монет)
                при использовании программы CAPSULA.
            </p>
            <p className="agreement__wrap-middle">
                Приложение №1.
                <br />
                Описание программы CAPSULA.
            </p>
            <p className="select">Общие принципы работы программы CAPSULA.</p>
            <p>
                CAPSULA — это программный продукт, который представляет из себя
                веб-приложение, адаптированное под мобильные устройства и
                персональные компьютеры (ПК). CAPSULA является
                многофункциональным программным обеспечением, способна
                предоставить широкий спектр возможностей для ее пользователя и
                состоит из следующих продуктов:
                <br />
                — сервис по заработку криптовалюты на играх разных форматов и
                жанров;
                <br />
                — сервис по использованию криптовалюты (возможность траты на
                различные активы, которые могут приносить новые активы);
                <br />
                — кастодиальный кошелек для хранения и отправки криптовалюты, в
                том числе NFT;
                <br />
                — сервис для проведения турниров;
                <br />
                — Airdrop-сервис;
                <br />— маркетплейс CANYON (обмен токенов/NFT/бустеров).
            </p>
            <p className="select">1.2.Идеология</p>
            <p>
                Как идеологически объединены вышеперечисленные продукты и почему
                их можно считать единой экосистемой? Название проекта произошло
                от слова “капсула”, которую можно сравнить с контейнером,
                хранящим в себе различные предметы. Подобно капсуле, которая
                хранит в себе различные предметы, веб-приложение CAPSULA
                объединяет в себе различные IT-продукты и предоставляет
                пользователям одновременный доступ ко всем компонентам CAPSULA.
                В рамках программного обеспечения CAPSULA возможно добывать
                токен CRYG, который впоследствии будет использоваться
                практически во всех представленных продуктах, а также с
                возможностью построить личный отдел продаж с целью получения
                вознаграждения за привлечение новых пользователей продукта.
                Таким образом, CAPSULA представляет из себя веб-приложение,
                которое выполняет роль ядра экосистемы и позволяет добывать
                токен CRYG, хранить его, пользоваться приложениями и играми
                внутри экосистемы, приятно проводить своё время и зарабатывать
                на продвижении проекта.
                <br />
            </p>
            <p className="select">2.1.Из чего состоит проект</p>
            <p className="select">Токенты</p>
            <p>
                Токен CRYG — утилитарный токен веб-приложения CAPSULA, созданный
                на блокчейне Decimal. Данный токен является ключевой цифровой
                единицей и позволяет получить доступ к внутриигровым и иным
                сервисам веб-приложения CAPSULA. Токены CRT, BRT, ART, SRT —
                утилитарные токены веб-приложения CAPSULA, позволяющие поднимать
                уровень бустеров.
            </p>
            <p className="select">Бустер-ферма</p>
            <p>
                Бустер-ферма — сервис в веб-приложении CAPSULA, позволяющий
                пользователю участвовать в добыче токена CRYG. Для этого
                необходимо внести на свой баланс кошелька в веб-приложении
                <br />
                CAPSULA любое количество токенов CRYG и иметь минимум один
                бустер на балансе своей бустер-фермы. Процент добычи токенов
                CRYG определяется мощностью фермы. На мощность фермы влияют
                бустеры, находящиеся в личной коллекции пользователя. В
                бустер-ферме пользователя, где устанавливаются бустеры,
                находится специальная ячейка для основного бустера. Показатели
                мощности бустера, установленного в основную ячейку, умножаются
                на два.
                <br />
                Формула расчета мощности фермы:
                <br />
                Мощность фермы = Сумма мощности всех бустеров + Мощность
                основного бустера * 2<br />
                Формула расчета процента добычи токена CRYG в сутки:
                <br />
                Скорость добычи токена = 0,00015 * Мощность фермы, где 1 единица
                мощности = 0,00015% в сутки.
                <br />
                Максимального значения мощности нет, но максимальный процент
                добычи токена равняется 1,5% в сутки.
                <br />
                Минимальное значение добычи токена является 0,3% в сутки +
                Мощность бустера. Данный процент начинает функционировать при
                наличии 1 бустера любого ранга в основной ячейке.
                <br />
                Бустеры
                <br />
                Бустеры имеют разные ранги. Чем выше ранг бустера у
                пользователя, тем больше мощности имеет его ферма. 
                <br />
                Существуют следующие ранги бустеров:
                <br />
                — Ранг C — Младший;
                <br />
                — Ранг B — Средний;
                <br />
                — Ранг A — Старший;
                <br />
                — Ранг S — Высший.
                <br />
            </p>
            <p>
                Для увеличения мощности фермы необходимо поднимать уровень
                бустеров. Текущий уровень определяется количеством звёзд,
                принадлежащих бустеру. Количество звёзд может варьироваться в
                значении от 1 до 5 штук, следовательно, каждый бустер может быть
                не выше 5 уровня.
                <br />
                Для увеличения количества звёзд с целью поднятия уровня бустера,
                необходимо использовать токены "Элементы", соответствующие
                каждому рангу бустеров.
                <br />
                Существуют следующие виды токенов "Элементов":
                <br />
                — СRT — токен для поднятия уровня бустеров С-ранга;
                <br />
                — BRT — токен для поднятия уровня бустеров B-ранга;
                <br />
                — ART — токен для поднятия уровня бустеров А-ранга;
                <br />
                — SRT — токен для поднятия уровня бустеров S-ранга.
                <br />
            </p>
            <p>
                Для увеличения мощности фермы необходимо поднимать уровень
                бустеров. Текущий уровень определяется количеством звёзд,
                принадлежащих бустеру. Количество звёзд может варьироваться в
                значении от 1 до 5 штук, следовательно, каждый бустер может быть
                не выше 5 уровня.
                <br />
                Для увеличения количества звёзд с целью поднятия уровня бустера,
                необходимо использовать токены "Элементы", соответствующие
                каждому рангу бустеров.
                <br />
                Существуют следующие виды токенов "Элементов":
                <br />
                — СRT — токен для поднятия уровня бустеров С-ранга;
                <br />
                — BRT — токен для поднятия уровня бустеров B-ранга;
                <br />
                — ART — токен для поднятия уровня бустеров А-ранга;
                <br />
                — SRT — токен для поднятия уровня бустеров S-ранга.
                <br />
            </p>
            <p>
                Данный вид токенов можно получить, открывая новые бустер-паки.
                <br />
                Бустер-пак Бустер-пак предназначен для открытия и получения
                новых токенов “Элементов”, позволяющих поднимать уровень
                бустеров. Внутри веб-приложения CAPSULA можно приобрести и
                открыть любое количество бустер-паков, используя токены CRYG.
                Стоимость открытия бустер-пака списывается с доступного баланса
                в веб-приложении CAPSULA, где хранятся токены CRYG,
                принадлежащие пользователю.
                <br />
                Из  бустер-пака, помимо "Элементов", могут выпадать токены CRYG,
                а также различные ценные подарки.
                <br />
                Получение ценных подарков будет возможно во время проведения
                различных акций и промо от проекта.
                <br />
                Изменения параметров добычи токена CRYG На момент запуска
                веб-приложение CAPSULA будет находиться в тестовом режиме и
                иметь максимальный диапазон добычи монет от 0,3 до 1,5%.
                Перераспределение процента скорости добычи токенов CRYG
                произойдет с выпуском первого игрового продукта.
                <br />
                Далее процент добычи будет функционировать следующим образом:
                <br />
                Когда на рынке станет доступно 200 000 CRYG, значения
                максимального и минимального процента добычи токена CRYG
                снизятся на 20% и составит минимум — 0,24%, максимум — 1,2%.
                <br />
                Также 1 единица мощности в бустер-ферме снизится на 20%.
                <br />
                Было: 1 единица мощности = 0,00015% в сутки.
                <br />
                Станет: 1 единица мощности = 0,00012% в сутки.
                <br />
                Когда на рынке станет доступно 500 000 CRYG, значения
                максимального и минимального процента добычи токена CRYG
                снизится на 20% и составит минимум — 0,19%, максимум — 0,96%.
                <br />
                Также 1 единица мощности бустер-фермы снизится на 20%.
                <br />
                Было: 1 единица мощности = 0,00012% в сутки.
                <br />
                Станет: 1 единица = 0,000096% в сутки.
                <br />
                Функционирование системы изменения параметров добычи токена CRYG
                может быть пересмотрено командой проекта в зависимости от
                рыночной ситуации и выпущенных продуктов.
                <br />
                Валидатор CRYGAME
                <br />
                В сети Decimal функционирует валидатор CRYGAME с неизменным
                параметром “комиссия” в 6%. Это означает, что валидатор CRYGAME
                получает 6% от вознаграждений, которые получают делегирующие
                кошельки. 
                <br />
                Делегирование монет пользователями в валидатор обеспечивает
                построение внутренней игровой инфраструктуры и бонусной системы
                в приложении CAPSULA.
                <br />
            </p>
            <p>
                <span className="select">Реферальная система</span> —
                продвижение платформы среди лояльных пользователей с помощью
                вознаграждения в токенах CRYG
            </p>
            <p className="agreement__wrap-middle">
                Приложение № 2.
                <br />
                Условия участия в реферальной (маркетинговой) программе CAPSULA.
            </p>
            <p className="select">
                {' '}
                Проект CAPSULA – не участвует в маркетинговой программе и не
                получает вознаграждения от привлечения новых участников –
                пользователей программы CAPSULA. Подробнее вы можете ознакомится
                с правилами маркетинговой программы в приложении CAPSULA.
                <br />
                Целью реферальных (маркетинговых программ) является
                дополнительная реклама программы, продвижение различных
                продуктов, созданных проектом CAPSULA).
                <br />
                Доход проекта CAPSULA образует исключительно комиссия от
                вознаграждения за farmin криптовалют, взимаемая Программой за
                поддержание работы системы и её техническое обслуживание.
                <br />
                Проект CAPSULA осуществляет законную экономическую деятельность
                в сфере информационных технологий.
                <br />
                Пользователи программы CAPSULA информированы о целях
                деятельности компании. Принципы работы программы CAPSULA
                прозрачно и полно раскрыты в условиях настоящего Соглашения.
                Если Вы не понимаете порядок функционирования программы и
                условия ее использования – пожалуйста, не используйте данную
                программу.
                <br />
                Реферальное вознаграждение работает периодично и имеет
                возможность отключаться и включаться по усмотрению руководства
                проекта CAPSULA в виде Акций по привлечению новых пользователей
                <br />
                <span className="select">1. </span>Общие положения
            </p>
            <p>
                <span className="select">1.1.</span> Настоящие Условия (далее –
                Условия) устанавливают правила участия Пользователя в
                реферальной (партнерской) программе CAPSULA (далее – Программа).
                <br />
                <span className="select">1.2.</span> Реферальная программа
                CAPSULA является стимулирующей акцией, представляющей собой
                комплекс мероприятий, целями проведения которых
                является стимулирование потребительского спроса, обеспечение
                эффективного сбыта, привлечение внимания, повышение узнаваемости
                и лояльности, поддержание и увеличение интереса потребителей к
                товарным знакам, услугам Проекта и программы CAPSULA и ее
                партнеров с целью их дальнейшего продвижения на рынке.
                <br />
                <span className="select">1.3.</span> Организатор Реферальной
                Программы – Проект CAPSULA.
                <br />
            </p>
            Участие в реферальной программе.
            <br />
            <p>
                <span className="select">2.1. </span>Возможность участия в
                Программе доступна дееспособным физическим лицам, достигшим
                возраста не менее 18 (восемнадцати) лет одновременно
                удовлетворяющим следующим условиям (далее – Менеджер):
                <br />
                <span className="select">2.1.1.</span> В личном кабинете
                Пользователь указал достоверную информацию о себе и принял
                Условия путем нажатия на кнопки регистрации (далее –
                «Регистрация в Программе»);
                <br />
                <span className="select">2.1.2. </span>Менеджер соблюдает
                условия получения поощрений, указанные в бизнес кабинете
                (аккаунте),в том числе достигает показателей по результатам
                выполненных заданий Менеджера и/или привлеченных им третьих лиц,
                использующих Программу CAPSULA.
                <br />
                <span className="select">2.3.</span> Организатор Программы
                вправе отказать в участии в Программе или исключить Менеджера из
                Программы в одностороннем порядке, в том числе, но не
                ограничиваясь, в случае несоблюдения п.п. 2.1.1.-2.1.2. Условий
                и/или злоупотребления правом. В случае обнаружения факта
                нарушения Условий и/или злоупотребления правом Организатор
                вправе приостановить участие Менеджера в Программе, отказать
                Менеджеру в предоставлении поощрения, списать монеты,
                предоставленные ранее Менеджеру в качестве поощрения, а также
                взыскать в одностороннем порядке с Менеджера причиненные убытки
                в полном объеме в случае обнаружения факта нарушения Условий
                и/или злоупотребления правом.
                <br />
                <span className="select">2.4.</span> Менеджер является
                независимым предпринимателем. Он не является сотрудником или
                юридическим представителем ни одного из предприятий, действующих
                под брендом CAPSULA.
                <br />
                <span className="select">2.5.</span> Менеджер несет личную
                ответственность за уплату налогов,соблюдение
                законов,регламентирующих предпринимательскую или иную
                экономическую деятельность в стране и регионе, где он
                осуществляет свою деятельность.
                <br />
                <span className="select">2.6.</span> Проект сохраняет за собой
                право:
                <br />
                - определять маркетинговую стратегию и диктовать Менеджерам свою
                политику;
                <br />
                - вносить изменения в стандарты, регулирующие деятельность
                Проекта и Менеджера;
                <br />
                - расторгнуть в одностороннем порядке настоящее Соглашение, а
                также отказать кандидату в регистрации в Программы.
                <br />
                <span className="select">2.7.</span> Заключая Соглашение
                Менеджер берет на себя обязательства:
                <br />
                - поддерживать репутацию, разделять идеологию, политику и
                стратегию Проекта;
                <br />
                - не производить рекламных материалов, не создавать сайтов – с
                использованием торговых марок, логотипов и иной интеллектуальной
                собственности, принадлежащей CAPSULA, без согласования с
                Компанией;
                <br />
                - не использовать сеть, построенную с помощью продукта -
                программы CAPSULA.
                <br />
            </p>
            <span className="select">3.</span> Порядок участия в Реферальной
            программе.
            <br />
            <p>
                <span className="select">3.1. </span>После Регистрации в
                Программе Менеджер получает возможность доступа в бизнес
                кабинет, содержащий инструменты, необходимые для привлечения
                третьих лиц к использованию Программы, отслеживания статистики
                по привлеченным им третьим лицам, получения и использования
                Поощрений, предусмотренных Программой (далее – Бизнес кабинет).
                <br />
                <span className="select">3.2.</span> Для получения поощрений
                Менеджеру необходимо самостоятельно, мотивировать третьих лиц
                (далее – «Рефералы»)использовать Программу, создавая при этом
                сеть из Рефералов (далее – «Cеть»). Подробные условия участия в
                реферальной программе установлены в приложении CAPSULA в разделе
                «Партнерская программа».
                <br />
                <span className="select">3.3.</span> С целью идентификации
                Пользователя – Реферала в качестве привлеченного Менеджером
                последнему в бизнес кабинете реферальной программы
                доступны: уникальные буквенно-цифровые коды (далее –
                «Промокоды»), уникальные интернет-ссылки (далее – «Реферальная
                ссылка»).
                <br />
                <span className="select">3.4.</span> Реферал считается
                привлеченным Менеджером в случае, если при использовании
                Программы CAPSULA Реферал активировал реферальный Промокод
                Менеджера.
                <br />
                В случае если Реферал оказался привлеченным одновременно
                несколькими Менеджерами, факт использования Программы CAPSULA
                данным Рефералом учитывается в статистике того Менеджера, чей
                реферальный промокод был использован последним.
                <br />
                В случае если Реферал, не использовавший ранее Программу
                CAPSULA.оказался привлеченным одновременно несколькими
                Менеджерами, факт использования Программы CAPSULA учитывается в
                статистике того Менеджера, чей реферальный промокод был
                использован первым.
                <br />
                <span className="select">3.5.</span> При соблюдении Менеджером
                критериев получения поощрений, указанных в Бизнес кабинете и в
                разделе «партнёрская программа», а также других разделов,
                содержащихся в приложении CAPSULA, в том числе показателей по
                количеству приглашенныхРефералов, Сети, Организатор в вправе
                зачислить на счёт Менеджера монеты призм и/или иные поощрения,
                указанные в бизнес кабинете Менеджера.  Количество поощрений,
                соответствующее конкретному критерию, указывается в бизнес
                кабинете Менеджера. Неотъемлемой частью настоящих Условий
                являются Правила акций, определяющие порядок получения
                поощрений, актуальная редакция которых размещена в бизнес
                кабинете Менеджера.
                <br />
                <span className="select">3.7. </span>Организатор вправе
                заключить с Менеджером договор возмездного оказания услуг и
                выплатить Менеджеру вознаграждение за привлеченных им в рамках
                Программы Рефералов. Решение о заключении договора принимается в
                индивидуальном порядке на основании показателей Сети Менеджера.
                Договор может быть заключен с лицом, являющимся индивидуальным
                предпринимателем или плательщиком налога на профессиональный
                доход («самозанятые»).
                <br />
                <span className="select">3.10.</span> Реферал, который стал
                Менеджером, не может быть Менеджером своего Менеджера.
                <br />
                <span className="select">3.11. </span>Совершая действия в рамках
                настоящей Программы, Менеджер обязан действовать добросовестно,
                соблюдать требования действующего законодательства, нормы
                морали, а также права Организатора и третьих лиц. Категорически
                не допускается привлекать Рефералов и выполнять следующие
                действия в рамках Программы:
                <br />
                с использованием интернет-ресурсов, которые содержат информацию
                порнографического характера, пропагандируют насилие, расовое,
                половое, религиозное и другие формы неравенства, содержат
                информацию, прямо запрещенную законодательством. Менеджер
                самостоятельно несет ответственность за соблюдение
                законодательства о рекламе, поскольку без участия Организатора
                формирует рекламные материалы и определяет способы их
                распространения в целях привлечения Рефералов. Менеджер обязан
                по требованию Организатора возместить убытки, причиненные в
                связи с нарушением норм Федерального закона "О рекламе" при
                создании и/или распространении рекламно-информационных
                материалов.
                <br />
                с использованием методов, которые дискредитируют Организатора,
                его сотрудников, иных лиц, в том числе конкурентов Организатора.
                <br />
                <span className="select">3.12.</span> На период участия в
                Программе Организатор предоставляет Менеджеру право доводить до
                всеобщего сведения предоставленные Организатором текстовые и
                графические материалы исключительно в целях исполнения своих
                обязательств в рамках Программы (указывая в обязательном порядке
                источник заимствования), с целью повышения эффективности участия
                Менеджера в Программе. Данное право распространяется на
                следующие Материалы:
                <br />
                описания принципов работы программы;
                <br />
                фотографии;
                <br />
                рецензии;
                <br />
                обзоры;
                <br />
                новости;
                <br />
                графические изображения и баннеры с символикой CAPSULA и
                брендов, участвующих в данных активностях (далее «Материалы»).
                <br />
                Менеджер не вправе изменять, интерпретировать иным образом
                содержание предоставленных Компанией Материалов. За каждый факт
                нарушения данного положения Организатор вправе наложить на
                Менеджера штраф в размере одной тысячи евро и в одностороннем
                порядке удержать монеты в соответствующем размере из личного
                кабинета Менеджера в приложении CAPSULA.
                <br />
                Менеджер не вправе использовать в доменном имени, коммерческом
                обозначении, товарный знак CAPSULA, на основании договора об
                оказании маркетинговых услуг.
                <br />
                <span className="select">3.12.2.</span> Не допускается
                использование Менеджером товарного знака CAPSULA и обозначений,
                сходных с ним до степени смешения, в названиях групп и учетных
                записей в социальных сетях.
                <br />
                <span className="select">3.12.3.</span>Не допускается
                использование Менеджером товарного знака CAPSULA и обозначений,
                сходных с ним до степени смешения, в названиях групп и учетных
                записей в социальных сетях, если такое использование будет
                негативно влиять на деловую репутацию Компаниии/или если не
                будет прямо указано, что группа (учетная запись) создана
                участником реферальной программы Компании.
                <br />
                <span className="select">3.12.4. </span>Все расходы на
                продвижении CAPSULA, в том числе расходы на проведение
                презентаций, обучающих семинаров, конференций и пр.
                осуществляются за личный счет Менеджера и не подлежат
                компенсации со стороны Компании за исключением случаев,
                предусмотренным отдельным Соглашением сторон, заключаемым в
                письменной форме.
                <br />
                <span className="select">3.13.</span> Запрещается привлечение
                Рефералов следующими способами:
                <br />
                посредством использования приложений к браузерам;
                <br />
                посредством рассылки спама любым техническим способом (т.е.
                рассылки электронных сообщений без предварительного получения
                согласия абонента или адресата такой рассылки);
                <br />
                с использованием контекстной, тизерной, таргетированной и
                медийной рекламы;
                <br />
                купонные сайты;
                <br />
                посредством интернет-сайтов или интернет-страниц, специально
                оптимизированных под поисковые запросы по слову CAPSULA и всех
                его производных, исключительно с целью перенаправления
                посетителей на другой сайт или страницу;
                <br />
                <span className="select">3.15.</span> При размещении ссылок на
                использование Программы Менеджер обязан сопровождать Материалы
                знаком информационной продукции в виде цифры «18» и знака «плюс»
                и (или) текстового предупреждения в виде словосочетания
                «запрещено для детей». Демонстрация указанных Материалов и
                ссылок допускается лишь при условии, что Менеджер применил
                административные и организационные меры, технические,
                программно-аппаратные средства защиты детей от информации,
                причиняющей вред их здоровью и (или) развитию, в том числе
                получил подтверждение пользователя, которому адресована
                информация, о том, что он старше 18 лет.
                <br />
                <span className="select">3.16. </span>Привлекая Рефералов,
                Менеджер может оперировать лишь реальными фактами и
                предоставлять достоверную и полную информацию. Менеджер не имеет
                права вводить в заблуждение Реферала, преувеличивая возможности
                получения поощрения или преуменьшая усилия по достижению успеха.
                <br />
                <span className="select">3.17.</span> Менеджеру запрещено для
                привлечения Рефералов использовать массовые объявления, в том
                числе: размещать объявления с предложением трудоустройства – как
                в интернете, так и на бумажных носителях; звонить по объявлениям
                с предложением вакансий людям, ищущим работу.
                <br />
                <span className="select">3.18.</span> Менеджеру запрещено
                нечестными способами добывать контакты, в том числе:
                использовать базы резюме и вакансий, размещенные на различных
                сайтах по поиску работы; проводить так называемые социальные
                опросы в местах скопления людей или по телефону.
                <br />
                <span className="select">3.17. </span>Сведения о Сети Менеджера,
                построенной с помощью инструментов Организатора, является
                конфиденциальной информацией и не подлежит разглашению третьим
                лицам. Менеджер обязуется использовать Сеть исключительно в
                целях выполнения обязательств в рамках Программы.
                <br />
                <span className="select">3.18.</span> В случае установления
                факта нарушения Соглашения и настоящих Условия, Менеджер может
                быть исключен из Программы. После обнаружения факта нарушения
                начисление монет в рамках Программы не производится, а также
                подлежат аннуляции монеты, предоставленные Менеджеру ранее в
                рамках Программы. 
                <span className="select">3.19.</span> Организатор оказывает
                Менеджерам техническую, информационную, обучающую поддержку в
                целях обеспечения эффективного участия Менеджера в Программе.
                Обучающие материалы и иная информация, полученная от
                Организатора в соответствии с настоящим пунктом, носит
                конфиденциальный характер и не подлежит разглашению третьим
                лицам. В случае установления факта разглашения Менеджером
                конфиденциальной информации или использования этой информации
                для целей, не связанных с участием в Программе, Организатор
                вправе взыскать с Менеджера причиненные убытки.
                <br />
                <span className="select">3.20.</span> Менеджер самостоятельно
                несет обязанность по уплате налогов и иных обязательных сборов
                (платежей).
                <br />
                <span className="select">3.21.</span> За несоблюдение Менеджером
                условий настоящего Соглашения Проект оставляет за собой право в
                одностороннем порядке накладывать Штрафы и удерживать
                (списывать) с личного счете Менеджера) монеты в следующем
                количестве за каждый факт выявленного нарушения, предварительно
                уведомив Менеджера о факте выявленного нарушения.
                <br />
                <span className="select">3.22.</span> За несоблюдение Менеджером
                условий настоящего Соглашения Проект оставляет за собой право в
                одностороннем порядке заблокировать аккаунты и расторгнуть
                договор в одностороннем порядке.
                <br />
            </p>
            <span className="select">4. </span>Заключительные положения
            <p>
                <span className="select">4.1.</span> Менеджер обязуется
                соблюдать требования законодательствао персональных данных в
                отношении любых персональных данных, ставших ему известными в
                результате исполнения своих обязательств, а также обеспечить
                безопасность таких персональных данных при их обработке.
                <br />
                <span className="select">4.2.</span> Менеджер обязуется не
                сообщать третьим лицам данные, необходимые для доступа в Бизнес
                кабинет. В случае возникновения у Менеджера подозрений
                относительно безопасности указанных данных или возможности их
                несанкционированного использования третьими лицами, Менеджер
                обязуется незамедлительно уведомить об этом Организатора.
                <br />
                <span className="select">4.3.</span> Регистрируясь в Программе,
                Менеджер соглашается на получение от Проекта сообщений,
                касающихся условий Программы, новостях Программы, а также на
                возможность получения сообщений о маркетинговых
                активностях CAPSULA.Указанные сообщения будут отправляться на
                адрес электронной почты и/или по номеру телефона, которые
                Менеджер указал при Регистрации в Программе/в личном
                кабинете CAPSULA
                <br />
                <span className="select">4.4.</span> Регистрируясь в Программе,
                Менеджер (физическое лицо, индивидуальный предприниматель)
                предоставляет Организатору согласие на обработку его
                персональных данных, в соответствии с Политикой Организатора в
                отношении обработки персональных данных.
                <br />
                <span className="select">4.5.</span> В случае возникновения
                вопросов или претензий, Менеджер должен обратиться в Службу по
                работе с клиентами. Ответы Организатора на обращения Менеджера
                признаются направленными в надлежащей форме в случае их отправки
                на электронный адрес Менеджера, указанный им при регистрации.
                Все возникающее споры стороны будут стараться решить путём
                переговоров.
                <br />
                <span className="select">4.6.</span> Менеджер не вправе
                передавать свои права и/или обязанности в рамках Программы
                третьим лицам.
                <br />
                <span className="select">4.7.</span> Факт участия в Программе не
                означает заключения между Менеджером и Организатором трудового,
                гражданского-правового или какого-либо иного договора.
                <br />
                <span className="select">4.8. </span>Организатор вправе без
                какого-либо специального уведомления вносить изменения в
                Соглашение и настоящие Условия, в связи с чем Менеджер обязуется
                регулярно отслеживать изменения в Условиях. Совершение
                Менеджером действий, направленных на получение поощрений в
                рамках Программы, после изменения Условий является
                подтверждением согласия Менеджера с новой редакцией Условий.
                <br />
            </p>
            <p className="agreement__wrap-middle">
                Приложение № 3.
                <br />
                Особенности функционирования криптовалют (монет) при
                использовании программы CAPSULA.
                <br />
                Использование монеты DEL и блокчейна Decimal
                <br />
            </p>
            Нативная монета DEL
            <p>
                DEL — это нативная монета блокчейна Decimal. Она является
                условием выполнения программной логики блокчейна. При работе с
                приложением CAPSULA, в личном кабинете на кошельке пользователя
                производится обмен монеты DEL на токен CRYG, с помощью которого
                осуществляются все операции внутри приложения. Обменный курс
                токена CRYG рассчитывается с помощью математических алгоритмов и
                зависит от баланса спроса и предложения на токен. Вывод монет
                DEL происходит также в личном кабинете пользователя на его
                кошельке через "обратную" конвертацию в нативную монету DEL по
                текущему курсу CRYG.
                <br />
                Внутри программного продукта CAPSULA используются токены с
                Обеспечением (резервом), созданные в сети Decimal. Функционально
                эти монеты будут отвечать за совершение операций на маркетплейсе
                приложения CAPSULA, стейкинг в сети Decimal, инвестиции, а также
                за пользование функционала сервисов внутри программного
                обеспечения CAPSULA.
                <br />
                Таким образом, монета DEL является ключевым звеном в работе с
                приложением CAPSULA.
                <br />
                - в отношении монеты DEL общая информация изложена на сайте
                https://decimalchain.com/.
                <br />
            </p>
            Курс криптовалюты не имеет устойчивого значения, постоянно меняется
            и не контролируется Проектом CAPSULA.
            <br />
            <p>
                Ценообразование на криптовалютных площадках является свободным и
                определяется самими участниками рынка.
            </p>
            Учитывая риски изменения курса криптовалюты, Пользователь несет риск
            потери средств, потраченных на приобретение данной криптовалюты.{' '}
            <br />
            Разработчиками программы могут быть установлены различные акции.
            <br />
            <p>
                <span className="select">Акция </span>– маркетинговое
                стимулирующее мероприятие, в результате выполнения условий
                которого Пользователь вправе получить поощрение, в том числе, в
                виде монет. Условия каждой Акции индивидуальны и размещаются в
                мобильном приложении.
                <br />
            </p>
            <p className="agreement__wrap-right">ПРОЕКТ CAPSULA</p>
        </div>
    )
}
