import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import svgInfo from './svgInfo'
import svgError from './svgError'
import svgSuccess from './svgSuccess'
import './styles.css'

const Context = React.createContext()

const useAlert = () => {
    const { setList } = useContext(Context)

    return ({ status = '', title = '', message = '' }) => {
        const id = uuid()
        setList((prev) => [
            ...prev,
            {
                id,
                status,
                title,
                message,
            },
        ])
    }
}

const AlertItem = ({ item, onDeletListItem }) => {
    useEffect(() => {
        const timeoutID = setTimeout(() => {
            onDeletListItem(item.id)
        }, 3000)
        return () => {
            clearTimeout(timeoutID)
        }
    }, [])
    return (
        <div key={item.id} style={{ paddingTop: 10 }}>
            <div className={`alert-notification-item ${item.status}`}>
                <div className="alert-notification-item__wrap-icon">
                    {item.status === 'success' && svgSuccess('#ffffff')}
                    {item.status === 'info' && svgInfo('#ffffff')}
                    {item.status === 'error' && svgError('#ffffff')}
                    {item.status === 'warning' && svgInfo('#ffffff')}
                </div>
                <div className="alert-notification-item__wrap-icon">
                    <p className="alert-notification-item__title">
                        {item.title}
                    </p>
                    <p className="alert-notification-item__text">
                        {item.message}
                    </p>
                </div>
            </div>
        </div>
    )
}

const AlertProvider = ({ children }) => {
    const [list, setList] = useState([])

    const onDeletListItem = (id) => {
        setList((prev) => prev.filter((item) => item.id !== id))
    }

    return (
        <Context.Provider value={{ setList }}>
            <div className="alert-notification">
                {list.map((item) => (
                    <AlertItem
                        key={item.id}
                        item={item}
                        onDeletListItem={onDeletListItem}
                    />
                ))}
            </div>
            {children}
        </Context.Provider>
    )
}

export { AlertProvider, useAlert }
