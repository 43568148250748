import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    boosterMain: null,
    infoStaking: {},
}

const stakingSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        setStakingRedux: (state, action) => ({ ...state, ...action.payload }),
        clearStakingRedux: (state, action) => ({ ...initialState }),
    },
})

export const {
    reducer: stakingReducer,
    actions: { setStakingRedux, clearStakingRedux },
} = stakingSlice
