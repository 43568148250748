import './styles.css'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import Loader from '../../components/Loader'
import getEnvVars from '../../api/environment'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/auth'

export default function Verification() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [paramData, setParamData] = useState(null)
    const [verificated, setVerificated] = useState(false)

    const navigate = useNavigate()
    const host = getEnvVars()
    const { referalLink } = useParams()
    console.log('refferalLink', referalLink)

    const verificateData = async () => {
        setLoading(true)
        setVerificated(false)
        //console.log('VERIFICATION :',`${host.apiUrl}/auth/verification?${paramData.referralLink?`referralLink=${paramData.referralLink}&`:''}code=${paramData.code}&email=${paramData.email}`)
        await axios
            .post(
                `${host.apiUrl}/auth/verification?${
                    paramData.referralLink
                        ? `referralLink=${paramData.referralLink}&`
                        : ''
                }code=${paramData.code}&email=${paramData.email}`
            )
            .then((data) => {
                if (data.data.status === 'success') {
                    setVerificated(true)
                }
            })
            .catch((e) => {
                console.log('ERROR', e)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const url = new URL(window.location.href)
        const code = url.searchParams.get('code')
        const email = url.searchParams.get('email')
        const ref = url.searchParams.get('referralLink')
        setParamData({ referralLink: ref, code, email })
    }, [])

    useEffect(() => {
        console.log('PARAMS DATA', paramData)
        dispatch(logout())
        if (paramData) {
            if (!paramData.code || !paramData.email) navigate('/introduction')
            verificateData()
        }
    }, [paramData])

    return (
        <div className="verification">
            {loading && <Loader />}
            {!loading && (
                <>
                    <div
                        className={`verification__message-wrap verification__message-${
                            verificated ? 'success' : 'fail'
                        }`}
                    >
                        <div className="verification__message">
                            {/* <img src={process.env.PUBLIC_URL + `/svg/${verificated ? 'success' : 'fail'}.svg`} alt='success' style={{ color: 'green' }} width={60} height={60} /> */}
                            <p>
                                {verificated
                                    ? 'Верификация почты прошла успешно!'
                                    : 'Ошибка верификации почты'}
                            </p>
                        </div>
                    </div>
                    <ButtonFilledMin
                        text="Далее"
                        onClick={() =>
                            navigate(
                                verificated ? '/auth/sign-in' : '/auth/sign-up'
                            )
                        }
                    />
                </>
            )}
        </div>
    )
}

const VerificationMessage = () => {
    const navigate = useNavigate()
    return (
        <div className="verification">
            <div className="verification__message-wrap">
                <p className="verification__message-text">
                    {' '}
                    На указанную Вами email почту было выслано письмо для
                    верификации. Перейдите по ссылке в письме, чтобы
                    подтвердить, что вы владеете указанной почтой.
                </p>
            </div>
            <ButtonFilledMin
                text={'Далее'}
                onClick={() => navigate('/auth/sign-in')}
            />
        </div>
    )
}

export { VerificationMessage }
