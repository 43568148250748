export default (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
        <path
            d="M35 20C35 11.7188 28.2812 5 20 5C11.7188 5 5 11.7188 5 20C5 28.2812 11.7188 35 20 35C28.2812 35 35 28.2812 35 20Z"
            stroke={color}
            strokeWidth="2.5"
            strokeMiterlimit="10"
        />
        <path
            d="M28.75 15L20.0102 25L16.2641 21.25M14.9961 25L11.25 21.25M23.8836 15L19.8563 19.6094"
            stroke={color}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
