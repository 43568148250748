import { Navigate } from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import MainLayout from '../layouts/MainLayout'
import ErrorLayout from '../layouts/ErrorLayout'
import Introduction from '../modules/Introduction'
import SignIn from '../modules/SignIn'
import SignUp from '../modules/SignUp'
import PasswordRecovery from '../modules/PasswordRecovery'
import PasswordReset from '../modules/PasswordReset'
import PasswordResetSuccess from '../modules/PasswordResetSuccess'
import Main from '../modules/Main'
import Profile from '../modules/Profile'
import WalletReplenish from '../modules/WalletReplenish'
import Boosterpack from '../modules/Boosterpack'
import Referrals from '../modules/Referrals'
import AccrualHistory from '../modules/AccrualHistory'
import ReferralsLine from '../modules/ReferralsLine'
import NotFound from '../modules/NotFound'
import Verification, { VerificationMessage } from '../modules/Verification'
import VerificationRepeat from '../modules/Verification/repeat'
import Agreement from '../modules/SignIn/agreement'
import Games from '../modules/Games'
import Marketplace from '../modules/Marketplace/Marketplace'
import History from '../modules/History/History'
import Collection from '../modules/Collection/Collection'
import BoosterFerma from '../modules/BoosterFerma/BoosterFerma'
import Staking from '../modules/Rent'

const navigation = [
    {
        path: 'auth',
        element: <AuthLayout />,
        children: [
            { path: 'introduction', element: <Introduction /> },
            { path: 'sign-in', element: <SignIn /> },
            { path: 'sign-up', element: <SignUp /> },

            {
                path: 'signup_verification-message',
                element: <VerificationMessage />,
            },
            {
                path: 'signup_verification-message/:referalLink',
                element: <VerificationMessage />,
            },
            {
                path: 'signup_verification-repeat',
                element: <VerificationRepeat />,
            },

            { path: 'agreement', element: <Agreement /> },

            { path: 'signup/', element: <Verification /> },
            { path: 'signup/:referalLink', element: <Verification /> },

            { path: 'sign-up/:key', element: <SignUp /> },
            { path: 'password-recovery', element: <PasswordRecovery /> },
            { path: 'password-reset/:key', element: <PasswordReset /> },
            {
                path: 'password-reset-success',
                element: <PasswordResetSuccess />,
            },
            { path: '*', element: <Navigate to="/error/404" /> },
        ],
    },
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: 'main', element: <Main /> },
            { path: 'profile', element: <Profile /> },
            // { path: 'wallet', element: <Wallet /> },
            { path: 'wallet-replenish/:key', element: <WalletReplenish /> },
            { path: 'booster', element: <BoosterFerma /> },
            { path: 'collection', element: <Collection /> },
            { path: 'staking/:number', element: <Staking /> },
            { path: 'boosterpack', element: <Boosterpack /> },
            { path: 'referrals', element: <Referrals /> },
            { path: 'accrual-history', element: <AccrualHistory /> },
            { path: 'referrals-line/:key', element: <ReferralsLine /> },
            { path: 'games', element: <Games /> },
            { path: 'marketplace', element: <Marketplace /> },
            { path: 'marketplace/history', element: <History /> },

            { path: '/', element: <Navigate to="/main" /> },
            { path: '*', element: <Navigate to="/error/404" /> },
        ],
    },
    {
        path: 'error',
        element: <ErrorLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/error/404" /> },
        ],
    },
]

export default navigation
