import './styles.css'
import d2 from './design2_svg'

const WalletStyler = ({
    state,
    children,
    showReplenish,
    showWithdrawal,
    styleState,
}) => (
    <>
        {styleState === 'design1' && (
            <>
                <img
                    src="/img/Cardlogo.png"
                    className={
                        showReplenish
                            ? 'wallet-card__logo_repl'
                            : 'wallet-card__logo'
                    }
                    alt="walletlogo"
                />

                <img
                    src="/img/Cardright.png"
                    className="wallet-card__side"
                    alt="walletside"
                />
                {children}
            </>
        )}

        {styleState === 'design2' && (
            <>
                {d2}
                {children}
            </>
        )}

        {styleState === 'design1__action' && (
            <>
                <img
                    src="/img/walletlogo2.png"
                    className="wallet-card__logo2"
                    alt="walletlogo"
                />
                <img
                    src="/img/cardside2.png"
                    className="wallet-card__side"
                    alt="walletside"
                />
                {children}
            </>
        )}
    </>
)

export default WalletStyler
