import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    boosterInfo: {
        id: null,
        name: null,
        price_buy: null,
        price_open: null,
        pool_booster_pack: null,
        coin_price_id: null,
    },
    boosterCount: null,
}

const boosterPackSlice = createSlice({
    name: 'boosterPack',
    initialState,
    reducers: {
        clearBoosterPackRedux: (state) => ({ ...initialState }),
        setBoosterInfoRedux: (state, action) => {
            state.boosterInfo = action.payload
        },
        setBoosterCountRedux: (state, action) => {
            state.boosterCount = action.payload
        },
    },
})

export const {
    reducer: boosterPackReducer,
    actions: {
        clearBoosterPackRedux,
        setBoosterInfoRedux,
        setBoosterCountRedux,
    },
} = boosterPackSlice
