import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    boosterFarms: [],
    mainBooster: null,
    collection: [],
    allBooster: [],
    selectedBooster: null,
    infoPower: { main: null, collection: null, powerAll: null },
    tokenBalance: [],
    selectedForBuyBooster: null,
    slideNumber: 0,
    collectionNumber: 0,
    chooseBoosterOrder: 0,
    layouts: {
        createBooster: false,
        confirmDisableMain: false,
        confirmActiveBooster: false,
        confirmUpdateBooster: false,
        chooseBooster: false,
        confirmBuyFarm: false,
        confirmSellBooster: false,
    },
}

const boosterSlice = createSlice({
    name: 'booster',
    initialState,
    reducers: {
        setReduxMain: (state, action) => {
            state.mainBooster = action.payload
        },
        setReduxInfoPower: (state, action) => {
            state.infoPower = action.payload
        },
        setReduxCollection: (state, action) => {
            state.collection = action.payload
        },
        selectBoosterRedux: (state, action) => {
            state.selectedBooster = action.payload
        },
        setTokenBalanceRedux: (state, action) => {
            state.tokenBalance = action.payload
        },
        setSlideNumberRedux: (state, action) => {
            state.slideNumber = action.payload
        },
        setCollectionNumberRedux: (state, action) => {
            state.collectionNumber = action.payload
        },
        confirmMainRedux: (state, action) => {
            state.layouts.confirmDisableMain = action.payload
        },
        confirmActiveBoosterRedux: (state, action) => {
            state.layouts.confirmActiveBooster = action.payload
        },
        confirmUpdateBoosterRedux: (state, action) => {
            state.layouts.confirmUpdateBooster = action.payload
        },
        createBoosterRedux: (state, action) => {
            state.layouts.createBooster = action.payload
        },
        chooseBoosterRedux: (state, action) => {
            state.layouts.chooseBooster = action.payload.val
            state.chooseBoosterOrder = action.payload.order
        },
        chooseBoosterOrderRedux: (state, action) => {
            state.chooseBoosterOrder = action.payload
        },
        setAllBoosterRedux: (state, action) => {
            state.allBooster = action.payload
        },
        selectedForBuyBoosterRedux: (state, action) => {
            state.selectedForBuyBooster = action.payload
        },
        clearBoosterRedux: (state) => ({ ...initialState }),
        setBoosterFarm: (state, action) => {
            state.boosterFarms = action.payload
        },
        confirmBuyFarm: (state, action) => {
            state.layouts.confirmBuyFarm = action.payload
        },
        confirmSellBooster: (state, action) => {
            state.layouts.confirmSellBooster = action.payload
        },
    },
})

export const {
    actions: {
        setReduxCollection,
        setReduxMain,
        selectBoosterRedux,
        confirmMainRedux,
        confirmActiveBoosterRedux,
        setReduxInfoPower,
        chooseBoosterRedux,
        chooseBoosterOrderRedux,
        createBoosterRedux,
        setAllBoosterRedux,
        setTokenBalanceRedux,
        selectedForBuyBoosterRedux,
        confirmUpdateBoosterRedux,
        setSlideNumberRedux,
        setCollectionNumberRedux,
        clearBoosterRedux,
        setBoosterFarm,
        confirmBuyFarm,
        confirmSellBooster,
    },
    reducer: boosterReducer,
} = boosterSlice
