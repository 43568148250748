import { useEffect, useState } from 'react'
import './styles.css'
import { useGet } from '../../api/request'
import Modal from '../Modal/modal'
import { StarWrapper } from './star-wrapper'
import bb from './buybutton'
import Alert from '../Alert'

const RangCard = ({ img, rang, stars, price, setIsOpen }) => {
    return (
        <div className="marketplace__card-wrapper">
            <img
                className="marketplace__rang-img"
                src={`/img/rang_${rang?.toLowerCase()}.png`}
                alt="booster"
            />

            <div className="marketplace__stars-wrapper">
                <StarWrapper starAmmouth={stars} />
            </div>

            <div className="marketplace__card-price">
                {price}
                {' BOOST'}
            </div>

            <div className="marketplace__cardbutton-wrapper">
                <button type="button" onClick={setIsOpen}>
                    Купить
                    {bb}
                </button>
            </div>
        </div>
    )
}

export default RangCard
