export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
    >
        <path
            d="M17.1 14.5001C16.9 14.4001 15.6 13.8001 15.4 13.7001C15.2 13.6001 15 13.6001 14.8 13.8001C14.6 14.0001 14.2 14.6001 14 14.8001C13.9 15.0001 13.7 15.0001 13.5 14.9001C12.8 14.6001 12.1 14.2001 11.5 13.7001C11 13.2001 10.5 12.6001 10.1 12.0001C10 11.8001 10.1 11.6001 10.2 11.5001C10.3 11.4001 10.4 11.2001 10.6 11.1001C10.7 11.0001 10.8 10.8001 10.8 10.7001C10.9 10.6001 10.9 10.4001 10.8 10.3001C10.7 10.2001 10.2 9.0001 10 8.5001C9.9 7.8001 9.7 7.8001 9.5 7.8001C9.4 7.8001 9.2 7.8001 9 7.8001C8.8 7.8001 8.5 8.0001 8.4 8.1001C7.8 8.7001 7.5 9.4001 7.5 10.2001C7.6 11.1001 7.9 12.0001 8.5 12.8001C9.6 14.4001 11 15.7001 12.7 16.5001C13.2 16.7001 13.6 16.9001 14.1 17.0001C14.6 17.2001 15.1 17.2001 15.7 17.1001C16.4 17.0001 17 16.5001 17.4 15.9001C17.6 15.5001 17.6 15.1001 17.5 14.7001C17.5 14.7001 17.3 14.6001 17.1 14.5001ZM19.6 5.4001C15.7 1.5001 9.4 1.5001 5.5 5.4001C2.3 8.6001 1.7 13.5001 3.9 17.4001L2.5 22.5001L7.8 21.1001C9.3 21.9001 10.9 22.3001 12.5 22.3001C18 22.3001 22.4 17.9001 22.4 12.4001C22.5 9.8001 21.4 7.3001 19.6 5.4001ZM16.9 19.4001C15.6 20.2001 14.1 20.7001 12.5 20.7001C11 20.7001 9.6 20.3001 8.3 19.6001L8 19.4001L4.9 20.2001L5.7 17.2001L5.5 16.9001C3.1 12.9001 4.3 7.9001 8.2 5.4001C12.1 2.9001 17.1 4.2001 19.5 8.0001C21.9 11.9001 20.8 17.0001 16.9 19.4001Z"
            fill="white"
        />
    </svg>
)
