import { useEffect, useState } from 'react'
import Balance from '../../components/Balance'
import Header from '../../components/Header'
import './styles.css'
import HistoryItem from '../../components/HistoryItem/HistoryItem'
import { useGet } from '../../api/request'
import { useAlert } from '../../components/AlertNotification'

const History = () => {
    const get = useGet()
    const alert = useAlert()

    const [historyList, setHistoryList] = useState([
        {
            rang: 'A',
            stars: 5,
            price: 500,
            date: '18.10.2022',
            time: '12:36',
            action: 'Продажа',
        },
        {
            rang: 'A',
            stars: 5,
            price: 234,
            date: '1.1.1970',
            time: '00:00',
            action: 'Продажа',
        },
        {
            rang: 'B',
            stars: 4,
            price: 511111,
            date: '1.12.2012',
            time: '9:04',
            action: 'Продажа',
        },
    ])

    useEffect(() => {
        get('marketplace/history')
            .then((res) => {
                if (res?.status === 'success') {
                    setHistoryList(res.history)
                }
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
    }, [])
    return (
        <>
            <div className="main__wrap-balance">
                <Balance />
            </div>
            <div className="history__main">
                <Header text="История" to="/marketplace" />

                <div className="history__list-wrapper">
                    {historyList.map((elem, i) => (
                        <HistoryItem
                            key={i}
                            rang={elem.rang}
                            stars={elem.stars}
                            price={elem.price}
                            date={elem.date}
                            time={elem.time}
                            action={elem.action}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default History
