import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/auth'
import Input from '../../components/Input'
import Header from '../../components/Header'
import ProfileBlock from '../../components/ProfileBlock'
import ButtonOutline from '../../components/ButtonOutline'
import ButtonFilled from '../../components/ButtonFilled'
import Loader from '../../components/Loader'
import Alert from '../../components/Alert'
import { useFetchUser } from '../../controllers/ControllerAuth'
import { setUser } from '../../redux/auth'
import { useGet, usePut } from '../../api/request'
import './styles.css'
import { clearStakingRedux, setStakingRedux } from '../../redux/staking'
import { clearBoosterRedux, setAllBoosterRedux } from '../../redux/booster'
import { clearBoosterPackRedux } from '../../redux/boosterPack'
import { useNavigate } from 'react-router-dom'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import LinkText from '../../components/LinkText'

const Profile = () => {
    const user = useSelector((state) => state.auth.user)
    const [isLoading, setIsLoading] = useState(false)
    const [imageFile, setImageFile] = useState(null)

    const [image, setImage] = useState(user.avatar || '')

    const [firstname, setFirstname] = useState(user.name || '')
    const [lastname, setLastname] = useState(user.surname || '')
    const [login, setLogin] = useState(user.login || '')
    const [errors, setErrors] = useState({
        firstname: '',
        lastname: '',
        login: '',
    })

    const [isShowAlert, setIsShowAlert] = useState(false)
    const [alert] = useState({
        title: 'Изменения внесены',
        text: 'Теперь вы можете использовать новые сведения для вашей учетной записи',
    })

    const navigate = useNavigate()

    const fetchUser = useFetchUser()
    const dispatch = useDispatch()

    const get = useGet()
    const put = usePut()

    useEffect(() => {
        setTimeout(() => {
            fetchUser()
        })
    }, [])

    const validation = () => {
        let valid = true
        if (!firstname) {
            setErrors((prev) => ({
                ...prev,
                firstname: 'Неверное имя',
            }))
            valid = false
        }
        if (!lastname) {
            setErrors((prev) => ({
                ...prev,
                lastname: 'Неверная фамилия',
            }))
            valid = false
        }
        return valid
    }

    const handleChangeImage = (evt) => {
        let file = evt.target?.files[0]
        if (!file) return
        setImageFile(() => file)
        const fr = new FileReader()
        fr.readAsDataURL(file)
        setIsLoading(true)
        fr.onload = () => {
            setIsLoading(false)
            setImage(() => fr.result)
        }
    }

    const handleChangeFirstname = (evt) => {
        setFirstname(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            firstname: '',
        }))
    }

    const handleChangeLastname = (evt) => {
        setLastname(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            lastname: '',
        }))
    }

    const submit = () => {
        if (!validation()) return
        setIsLoading(true)

        const requestData = new FormData()

        requestData.append('name', firstname)
        requestData.append('surname', lastname)
        if (imageFile) {
            requestData.append('file', imageFile)
        }

        const headers = {
            'Content-Type': 'multipart/form-data',
        }

        put('users', requestData, { headers })
            .then((response) => {
                setIsShowAlert(true)
                fetchUser()
            })
            .catch((e) => {
                console.log(e)
                setErrors((prev) => ({
                    ...prev,
                    ...(e.response.data.validation || {}),
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleLogout = () => {
        dispatch(clearStakingRedux())
        dispatch(clearBoosterRedux())
        dispatch(clearBoosterPackRedux())

        dispatch(logout())
    }

    return (
        <div className="profile">
            <Header text="Профиль" />
            <div className="profile__wrap-profile-block">
                {!isLoading && (
                    <ProfileBlock img={image} onChange={handleChangeImage} />
                )}
            </div>
            <div className="profile__wrap-input">
                <p className="profile__label">Имя:</p>
                <Input
                    value={firstname}
                    onChange={handleChangeFirstname}
                    rightIcon="pen"
                    placeholder="Имя"
                    error={errors.firstname}
                />
            </div>
            <div className="profile__wrap-input">
                <p className="profile__label">Фамилия:</p>
                <Input
                    value={lastname}
                    onChange={handleChangeLastname}
                    rightIcon="pen"
                    placeholder="Фамилия"
                    error={errors.lastname}
                />
            </div>
            <div className="profile__wrap-input">
                <p className="profile__label">Логин:</p>
                <Input
                    value={login}
                    onChange={() => {}}
                    disabled={true}
                    placeholder="Логин"
                    error={errors.login}
                />
            </div>

            {/* <ButtonFilledMin text={'Пользовательское соглашение'} onClick={()=>navigate('/auth/agreement')} style={{padding:'15px 20px',justifyContent:'left',borderRadius:12,marginTop:30}}/> */}

            <div className="profile__wrap-btn">
                <ButtonFilled text="Сохранить" onClick={submit} />
            </div>
            <ButtonOutline text="Выйти из аккаунта" onClick={handleLogout} />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 20,
                }}
            >
                <LinkText
                    text="Пользовательское соглашение"
                    to="/auth/agreement"
                />
            </div>

            {isShowAlert && (
                <Alert onClose={() => setIsShowAlert(false)} {...alert} />
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default Profile
