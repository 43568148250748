import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/Header'
import Rang from '../../components/Rang'
import { v4 } from 'uuid'
import {
    chooseBoosterRedux,
    createBoosterRedux,
    selectBoosterRedux,
    setCollectionNumberRedux,
    setReduxCollection,
} from '../../redux/booster'
import './styles.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCallback, useEffect, useState } from 'react'
import { Pagination, Virtual } from 'swiper'
import ButtonFilled from '../../components/ButtonFilled'
import { useGet } from '../../api/request'
import Loader from '../../components/Loader'
import { useAlert } from '../../components/AlertNotification'
import { useNavigate } from 'react-router-dom'
import Alert from '../../components/Alert'

const Collection = ({ choose = false, cb, title = 'Коллекция' }) => {
    const [loading, setLoading] = useState(false)
    const {
        collection,
        collectionNumber: slideNumber,
        chooseBoosterOrder,
    } = useSelector((state) => state.booster)
    const [slides, setSlides] = useState([])
    const [isSoon, setIsSoon] = useState(false)
    const dispatch = useDispatch()
    const get = useGet()
    const alert = useAlert()
    const navigate = useNavigate()

    const getBoosterCollection = useCallback(async () => {
        setLoading(true)
        const {
            data: { boosters },
        } = await get('boosters/myCollection')
            .then((data) => data)
            .catch((e) => {
                setLoading(false)
                console.log('SERVER ERROR :', e)
                alert({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
            })
            .finally()
        setLoading(false)
        dispatch(setReduxCollection(boosters))
    }, [])

    const getFreeBoosters = async () => {
        setLoading(true)
        const { boosters } = await get('farm/free')
            .then((data) => data)
            .catch((e) => {
                setLoading(false)
                console.log('SERVER ERROR :', e)
                alert({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
            })
            .finally()
        setLoading(false)
        dispatch(setReduxCollection(boosters))
    }

    const handleClick = (b) => {
        dispatch(selectBoosterRedux(b))
        navigate('/booster')
    }

    useEffect(() => {
        getFreeBoosters()
    }, [])

    useEffect(() => {
        if (collection.length) {
            createSlides(collection)
            return
        }
    }, [collection])

    useEffect(() => {
        setTimeout(() => {
            bulletStyle()
        }, 50)
    })

    const createSlides = (arr) => {
        if (arr.length > 0) {
            const length = arr.length
            let temp = []
            let slidesTemp = []
            let slide = 1
            for (let i = 0; i < length; i++) {
                temp.push(
                    <Rang
                        key={v4()}
                        {...{
                            rangS: arr[i]?.rang.split('')[0].toLowerCase(),
                            stars: arr[i]?.star,
                            small: true,
                            isSelling: arr[i]?.isSelling,
                            onClick: () =>
                                cb
                                    ? cb(arr[i].id, chooseBoosterOrder)
                                    : handleClick(arr[i]),
                        }}
                    />
                )
                if ((i !== 0 && (i + 1) % 12 === 0) || i === length - 1) {
                    slidesTemp = [
                        ...slidesTemp,
                        <SwiperSlide key={v4()} virtualIndex={slide}>
                            <div className="slide__block" key={v4()}>
                                {[...temp]}
                            </div>
                        </SwiperSlide>,
                    ]
                    slide += 1
                    temp = []
                }
            }
            setSlides(slidesTemp)
        }
    }

    const bulletStyle = () => {
        const active = document.querySelector(
            '.swiper-pagination-bullet.swiper-pagination-bullet-active'
        )
        document
            .querySelectorAll('.near-active')
            .forEach((el) => el?.classList?.remove('near-active'))
        active?.previousElementSibling?.classList.add('near-active')
        active?.nextElementSibling?.classList.add('near-active')
    }

    const slideChange = (data) => {
        bulletStyle()
        dispatch(setCollectionNumberRedux(data.activeIndex))
    }

    return (
        <>
            {loading && <Loader />}
            <div className="collection">
                <Header
                    text={title}
                    cb={
                        choose
                            ? () => {
                                  dispatch(chooseBoosterRedux({ val: false }))
                              }
                            : undefined
                    }
                    to={'/booster'}
                />
                <div className="collection__card-wrapper">
                    <div className="collection__slider">
                        {slides.length ? (
                            <div className="collection__slider__block">
                                <Swiper
                                    modules={[Virtual, Pagination]}
                                    pagination={{ clickable: true }}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    onSlideChange={slideChange}
                                    virtual
                                    initialSlide={slideNumber}
                                    className="boosters_swiper"
                                >
                                    {slides}
                                </Swiper>
                            </div>
                        ) : (
                            <div className="collection__slider__block">
                                У вас нет бустеров
                            </div>
                        )}
                        {!choose && (
                            <>
                                <ButtonFilled
                                    text={'Купить бустер'}
                                    onClick={() => setIsSoon(true)}
                                />
                                <div style={{ height: '11px' }} />
                                <ButtonFilled
                                    text={'Сформировать бустер'}
                                    onClick={() => {
                                        dispatch(createBoosterRedux(true))
                                        navigate(-1)
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
                {isSoon && (
                    <Alert
                        onClose={() => setIsSoon(false)}
                        title="Скоро будет"
                    />
                )}
            </div>
        </>
    )
}

export default Collection
