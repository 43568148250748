import { useState } from 'react'
import Balance from '../../components/Balance'
import Header from '../../components/Header'
import './styles.css'
import Loader from '../../components/Loader'
import RangButton from '../../components/RangButton/RangButton'
import RangCard from '../../components/RangCard/RangCard'
import Modal from '../../components/Modal/modal'
import { StarWrapper } from '../../components/RangCard/star-wrapper'
import { useSelector } from 'react-redux'
import Alert from '../../components/Alert'
import { Link } from 'react-router-dom'
import { usePost } from '../../api/request'

const Marketplace = () => {
    const [loading /*  setLoading */] = useState(false)
    const [reqResult, setReqResult] = useState(0)
    const [choosenRank, setChoosenRank] = useState('')
    const [choosenStars, setChoosenStars] = useState(0)
    const [open, setOpen] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [cardToBuy, setCardToBuy] = useState({})
    const [power /* setPower */] = useState(0)
    //const [page, setPage] = useState(0);

    const user = useSelector((state) => state.auth.user)

    //const get = useGet();
    const post = usePost()

    const [cards /* setCards */] = useState([
        {
            rang: 'A',
            stars: 5,
            price: 500,
        },
        {
            rang: 'B',
            stars: 4,
            price: 50000,
        },
        {
            rang: 'S',
            stars: 3,
            price: 10,
        },
        {
            rang: 'C',
            stars: 5,
            price: 120,
        },
        {
            rang: 'A',
            stars: 5,
            price: 500,
        },
        {
            rang: 'A',
            stars: 5,
            price: 7000000,
        },
    ])

    // Это закомментировал Марат, так как это пока блокирует отображение маркетплейса

    /* useEffect(() => {
		if (choosenRank | choosenStars) {
			get(
				`marketplace?page=${page}&star=${choosenStars}&token=${choosenRank}`
			)
				.then((res) => {
					if (res?.status === "success") {
						setCards(res.boosters);
					}
				})
				.catch((e) => {
					console.log("ERROR", e);
					alert({
						status: "warning",
						title: "Внимание!",
						message:
							e.response?.data?.message ||
							"Попробуйте обновить через 5 минут или позже",
					});
				});
		} else {
			get(`marketplace?page=${page}`)
				.then((res) => {
					if (res?.status === "success") {
						setCards(res.boosters);
					}
				})
				.catch((e) => {
					console.log("ERROR", e);
					alert({
						status: "warning",
						title: "Внимание!",
						message:
							e.response?.data?.message ||
							"Попробуйте обновить через 5 минут или позже",
					});
				});
		}
	}, [choosenRank, choosenStars, page]); */

    const changeOpenButton = (val) => {
        return () => {
            if (open === val) {
                setOpen(0)
                return
            }
            setOpen(val)
        }
    }

    const setRank = (rank) => {
        return (val) => {
            console.log(val)
            setChoosenStars(val)
            setChoosenRank(rank)
            setOpen(0)
        }
    }

    const openBuyModal = (card) => {
        setCardToBuy(card)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setCardToBuy({})
        setIsModalOpen(false)
    }

    const closeReqResult = () => {
        setReqResult(0)
    }

    const buyCard = () => {
        post('marketplace/buy')
            .then((res) => {
                if (res?.status === 'success') {
                    setReqResult(1)
                } else {
                    setReqResult(-1)
                }
                setChoosenRank('')
                setChoosenStars(0)
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message:
                        e.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
    }

    return (
        <>
            {loading && <Loader />}

            {reqResult < 0 && (
                <Alert title="Произошла ошибка!" onClose={closeReqResult} />
            )}

            {reqResult > 0 && (
                <Alert
                    title="Успех! Поздравляем с покупкой."
                    text="Бустер отправлен вам в коллекцию."
                    onClose={closeReqResult}
                    btns={[
                        { name: 'Перейти в коллекцию', cb: buyCard },
                        { name: 'Продолжить покупки', cb: closeReqResult },
                    ]}
                />
            )}

            {isModalOpen &&
                (user?.wallet?.balance?.amount > cardToBuy.price ? (
                    <Modal
                        btns={[
                            { name: 'Купить', cb: buyCard },
                            { name: 'Отмена', cb: closeModal },
                        ]}
                        bspan={
                            <p>
                                {`С вашего баланса будет`}
                                {<br />}
                                {`списанно ${cardToBuy.price} BOOST`}
                            </p>
                        }
                        style={{ padding: '10px' }}
                    >
                        <div className="marketplace__modal-contentwrapper">
                            <img
                                className="marketplace__modal-img"
                                src={`/img/rang_${cardToBuy.rang?.toLowerCase()}.png`}
                                alt="booster"
                            />

                            <div className="marketplace__modal-rang">
                                {'rang ' + cardToBuy.rang}
                            </div>

                            <div className="profile-bar__wrap-balance">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="16"
                                    viewBox="0 0 15 16"
                                    fill="none"
                                >
                                    <path
                                        d="M7.28963 0.0229263C7.19401 0.0581551 7.10678 0.15042 7.03129 0.296368C6.99438 0.366826 6.81992 0.740921 6.6421 1.12844C6.46427 1.51595 6.27136 1.9387 6.21264 2.06787C6.15225 2.19704 6.06837 2.38157 6.02308 2.47887C5.88216 2.78587 5.74963 3.07608 5.49465 3.628C5.35876 3.92325 5.21114 4.24366 5.16752 4.34096C5.04841 4.60434 4.74813 5.25691 4.32035 6.18628C3.3524 8.29162 3.38931 8.2027 3.41447 8.31342C3.4195 8.34026 3.44299 8.37885 3.4648 8.40066L3.50338 8.43756L4.77833 8.41743C6.1187 8.3973 6.1187 8.3973 6.26297 8.46944C6.34349 8.51138 6.38711 8.59525 6.36194 8.66236C6.35188 8.6892 6.20761 9.07336 6.03985 9.51623C5.8721 9.95911 5.70937 10.3886 5.67918 10.4724C5.64898 10.5546 5.498 10.9556 5.34367 11.3615C5.04841 12.145 4.59212 13.3528 4.46295 13.7017C4.41933 13.8175 4.28009 14.1866 4.15428 14.5237C3.76844 15.5454 3.67114 15.8188 3.67114 15.8926C3.66946 15.9631 3.68288 16 3.70804 16C3.72314 16 4.84207 14.5103 6.92728 11.7138C7.16885 11.3901 7.40203 11.078 7.449 11.0176C7.4943 10.9573 7.69225 10.6939 7.88852 10.4305C8.0848 10.1671 8.76924 9.2495 9.41175 8.39227C10.9937 6.27854 10.8779 6.44127 10.8679 6.3339L10.8628 6.27854L10.6867 6.26848C10.5894 6.26177 9.89153 6.25674 9.13495 6.25506C7.66876 6.25338 7.43223 6.24667 7.43223 6.20809C7.43223 6.18125 7.43894 6.17118 7.77109 5.66623C7.91369 5.44983 8.10996 5.15122 8.20726 5.0036C8.37166 4.75197 8.60819 4.39297 9.02255 3.76221C9.11985 3.61458 9.31612 3.31598 9.45872 3.09957C9.60299 2.88316 9.78081 2.6114 9.8563 2.49565C9.93179 2.3799 10.1432 2.05948 10.326 1.78269C11.0893 0.626847 11.299 0.303079 11.3191 0.247719C11.3493 0.162164 11.346 0.0900288 11.3091 0.044734L11.2772 0.00447273L9.31612 0.00111771C7.61508 -0.00223732 7.34835 0.00111771 7.28963 0.0229263Z"
                                        fill="url(#paint0_linear_57_1274)"
                                    />
                                    <path
                                        d="M5.337 1.11155C4.46299 1.33634 3.44975 1.85806 2.71833 2.4603C1.35615 3.58595 0.453625 5.05213 0.133211 6.6542C0.0895945 6.87732 0.0694638 7.02494 0.0174595 7.51479C-0.0278346 7.95095 0.0174595 8.71089 0.124823 9.28965C0.45027 11.041 1.38132 12.6246 2.71833 13.7C2.93138 13.8727 3.16959 14.0304 3.2367 14.0455C3.3038 14.0589 3.39271 14.0187 3.44471 13.9499C3.4682 13.9197 3.51853 13.8291 3.55375 13.7503C3.75003 13.3242 3.82049 12.9266 3.74332 12.68C3.69299 12.5189 3.63428 12.4384 3.45981 12.2858C2.78543 11.702 2.29726 11.0276 1.93826 10.1871C1.58094 9.35172 1.42661 8.33679 1.52559 7.4695C1.69502 5.99324 2.4667 4.59919 3.62421 3.67821C3.71144 3.60943 3.93624 3.45006 4.12245 3.32592C4.70288 2.93673 5.02162 2.59115 5.30009 2.04259C5.41752 1.81277 5.46953 1.67017 5.53495 1.40847C5.5987 1.14845 5.61212 1.05283 5.5836 1.05451C5.57186 1.05451 5.46114 1.07967 5.337 1.11155Z"
                                        fill="url(#paint1_linear_57_1274)"
                                    />
                                    <path
                                        d="M11.1129 2.24237C11.0458 2.28263 10.982 2.36148 10.7656 2.67183C10.5593 2.96875 10.4972 3.07612 10.462 3.19858C10.4033 3.39821 10.4385 3.47706 10.665 3.6532C10.9988 3.91322 11.4081 4.34435 11.6799 4.72013C11.9986 5.16636 12.2905 5.73505 12.4717 6.27019C12.8693 7.45119 12.8861 8.7362 12.5153 9.84675C12.1647 10.8952 11.4903 11.8212 10.571 12.5174C9.96879 12.9737 9.33971 13.2891 8.61836 13.4988C8.18051 13.6263 7.793 13.6833 7.24779 13.7018C6.87202 13.7135 6.82672 13.7186 6.716 13.7555C6.46269 13.8444 6.22112 14.0574 5.98962 14.398C5.84703 14.6093 5.68766 14.9079 5.68766 14.9683C5.68766 15.0153 5.73631 15.0707 5.7967 15.0942C5.98123 15.1646 6.54322 15.2183 7.09681 15.22C7.83661 15.22 8.34324 15.1562 9.01762 14.9734C10.6465 14.5355 12.0238 13.5877 12.98 12.2457C13.693 11.2442 14.1342 10.1001 14.2969 8.8184C14.3254 8.58522 14.3271 7.49817 14.2969 7.27673C14.1795 6.39601 13.9731 5.69479 13.6141 4.95163C13.2853 4.26886 12.9247 3.7354 12.4096 3.17006C12.1144 2.84461 11.5138 2.31786 11.3461 2.23566C11.2756 2.20211 11.1766 2.20379 11.1129 2.24237Z"
                                        fill="url(#paint2_linear_57_1274)"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_57_1274"
                                            x1="3.566"
                                            y1="20.6928"
                                            x2="29.253"
                                            y2="-11.0732"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#EAD411" />
                                            <stop
                                                offset="1"
                                                stopColor="#B58C24"
                                                stopOpacity="0"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_57_1274"
                                            x1="0.113158"
                                            y1="17.8591"
                                            x2="21.3911"
                                            y2="-5.00228"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#EAD411" />
                                            <stop
                                                offset="1"
                                                stopColor="#B58C24"
                                                stopOpacity="0"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint2_linear_57_1274"
                                            x1="5.86214"
                                            y1="19.0352"
                                            x2="24.7693"
                                            y2="-12.2522"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#EAD411" />
                                            <stop
                                                offset="1"
                                                stopColor="#B58C24"
                                                stopOpacity="0"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <p className="profile-bar__balance">
                                    {power || 0}
                                </p>
                            </div>

                            <div className="marketplace__stars-wrapper">
                                <StarWrapper
                                    starAmmouth={cardToBuy.stars}
                                    styles={{ width: '32px', height: '32px' }}
                                />
                            </div>

                            <div className="marketplace__modal-cardprice">
                                <p>{`Цена: ${cardToBuy.price} BOOST`}</p>
                            </div>
                        </div>
                    </Modal>
                ) : (
                    <Alert
                        title="На вашем балансе недостаточно средств!"
                        onClose={closeModal}
                    />
                ))}

            <div className="main__wrap-balance">
                <Balance />
            </div>
            <div className="marketplace">
                <Header text="Canyon" to="/" />

                <div className="marketplace__filter-wrapper">
                    <RangButton
                        title="rang C"
                        isOpen={open === 1 ? true : false}
                        changeCardButton={() => changeOpenButton(1)()}
                        setStars={setRank('C')}
                        choosen={choosenRank === 'C' ? true : false}
                        stars={choosenStars}
                    />
                    <RangButton
                        title="rang B"
                        isOpen={open === 2 ? true : false}
                        changeCardButton={() => changeOpenButton(2)()}
                        setStars={setRank('B')}
                        choosen={choosenRank === 'B' ? true : false}
                        stars={choosenStars}
                    />
                    <RangButton
                        title="rang A"
                        isOpen={open === 3 ? true : false}
                        changeCardButton={() => changeOpenButton(3)()}
                        setStars={setRank('A')}
                        choosen={choosenRank === 'A' ? true : false}
                        stars={choosenStars}
                    />
                    <RangButton
                        title="rang S"
                        isOpen={open === 4 ? true : false}
                        changeCardButton={() => changeOpenButton(4)()}
                        setStars={setRank('S')}
                        choosen={choosenRank === 'S' ? true : false}
                        stars={choosenStars}
                    />
                </div>

                <hr className="main__hr"></hr>

                <div className="marketplace__main">
                    <div className="marketplace__cards-wrapper">
                        {[].filter}
                        {cards
                            .filter((elem) => {
                                if (choosenRank) {
                                    return choosenRank === elem.rang
                                }
                                return true
                            })
                            .filter((elem) => {
                                if (choosenStars) {
                                    return choosenStars === elem.stars
                                }
                                return true
                            })
                            .map((elem) => (
                                <RangCard
                                    rang={elem.rang}
                                    stars={elem.stars}
                                    price={elem.price}
                                    setIsOpen={() => openBuyModal(elem)}
                                />
                            ))}
                    </div>
                </div>
            </div>
            <Link
                to="/marketplace/history"
                className="marketplace__history-link"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.3384 12.2834C24.3384 19.0043 18.89 24.4526 12.1692 24.4526C5.44833 24.4526 0 19.0043 0 12.2834C0 5.56259 5.44833 0.114258 12.1692 0.114258C18.89 0.114258 24.3384 5.56259 24.3384 12.2834ZM2.03979 12.2834C2.03979 17.8778 6.57487 22.4128 12.1692 22.4128C17.7635 22.4128 22.2986 17.8778 22.2986 12.2834C22.2986 6.68913 17.7635 2.15404 12.1692 2.15404C6.57487 2.15404 2.03979 6.68913 2.03979 12.2834ZM13.1469 11.4998H20.2482V13.4551H13.1469H11.1915V11.4998V6.28247H13.1469V11.4998Z"
                        fill="#3B81E4"
                    />
                </svg>
            </Link>
        </>
    )
}

export default Marketplace
