import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/auth'
import { useGet } from '../../api/request'
import { configureTransport } from '../../api/transport'

export const useFetchUser = () => {
    const accessToken = useSelector((state) => state.auth.accessToken)
    const dispatch = useDispatch()
    const get = useGet()

    return (callback = () => {}) => {
        if (!accessToken) {
            dispatch(setUser())
            return
        }

        get('users')
            .then((response) => {
                dispatch(setUser(response.data.user))
                callback(response.data.user)
            })
            .catch(console.log)
    }
}

const ControllerAuth = ({ children }) => {
    const accessToken = useSelector((state) => state.auth.accessToken)
    const navigate = useNavigate()
    const fetchUser = useFetchUser()

    useEffect(() => {
        process.env.REACT_APP_VER === 'dev' &&
            console.log('accessToken', accessToken)
        if (!accessToken) {
            navigate('/auth/introduction')
            return
        }
        configureTransport(accessToken)
        fetchUser()
    }, [accessToken])

    return children
}

export default ControllerAuth
