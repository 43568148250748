import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import Header from '../../components/Header'
import ButtonFilled from '../../components/ButtonFilled'
import Loader from '../../components/Loader'
import { shortenEmail } from '../../helpers'
import { post } from '../../api/request'
import './styles.css'

const PasswordRecovery = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isSended, setIsSended] = useState(false)
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({
        email: '',
    })
    const navigate = useNavigate()

    const validation = () => {
        let valid = true
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setErrors((prev) => ({
                ...prev,
                email: 'Неверно указан адрес электронной почты',
            }))
            valid = false
        }
        return valid
    }

    const handleChangeEmail = (evt) => {
        setEmail(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            email: '',
        }))
    }

    const submit = () => {
        if (!validation()) return
        setIsLoading(true)

        const requestData = {
            email,
            http: `${window.location.origin}/auth/password-reset/`,
        }

        post('users/recover', requestData)
            .then((response) => {
                setIsSended(true)
            })
            .catch((e) => {
                console.log(e)
                setErrors((prev) => ({
                    ...prev,
                    ...(e.response.data.validation || {}),
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="password-recovery">
            <Header text="Ввостановление пароля" />
            {isSended ? (
                <div className="password-recovery__wrap">
                    <p className="password-recovery__title">
                        Электронное письмо отправлено
                    </p>
                    <p className="password-recovery__text" translate="no">
                        Мы отправили ссылку для ввостановления доступа к вашему
                        аккаунту на адрес {shortenEmail(email)}
                    </p>
                </div>
            ) : (
                <div className="password-recovery__wrap">
                    <p className="password-recovery__title">Укажите email</p>
                    <p className="password-recovery__text" translate="no">
                        Введите свой электронный адрес и мы отправим вам ссылку
                        для восстановления доступа к аккаунту.
                    </p>
                    <div className="password-recovery__wrap-input">
                        <Input
                            value={email}
                            onChange={handleChangeEmail}
                            leftIcon="mail"
                            placeholder="Email"
                            error={errors.email}
                            type="email"
                        />
                    </div>
                    <ButtonFilled text="Получить ссылку" onClick={submit} />
                </div>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default PasswordRecovery
