import React, { useEffect, useMemo, useState } from 'react'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import Header from '../../components/Header'
import { v4 as uuid } from 'uuid'
import './styles.css'
import ButtonFilled from '../../components/ButtonFilled'
import Modal from '../../components/Modal/modal'
import { useDispatch, useSelector } from 'react-redux'
import { useGet, usePost, usePut } from '../../api/request'
import {
    setBoosterCountRedux,
    setBoosterInfoRedux,
} from '../../redux/boosterPack'
import Loader from '../../components/Loader'
import { useAlert } from '../../components/AlertNotification'
import box from './box'
import { setBalance } from '../../redux/auth'
import { useBalance } from '../../Hooks/Hooks'
const Boosterpack = () => {
    const [buy, setBuy] = useState(false)
    const [caseArr, setCaseArr] = useState(1)
    const [numberCards, setNumberCards] = useState(0)
    const [confirmBuy, setConfirmBuy] = useState(false)
    const [countCards, setCountCards] = useState(10)
    const [loading, setLoading] = useState(false)
    const [prizeArr, setPrizeArr] = useState([])
    const dispatch = useDispatch()
    const get = useGet()
    const put = usePut()
    const post = usePost()
    const alert = useAlert()
    const { getBalance } = useBalance()

    const {
        id: boosterId,
        price_buy,
        price_open,
    } = useSelector((state) => state.boosterPack.boosterInfo) //GET BOOSTER INFO
    const { boosterCount } = useSelector((state) => state.boosterPack)
    const { amount = 0 } = useSelector(
        (state) => state.auth.user.wallet?.balance
    )

    const addCount = () => setCaseArr((state) => state + 1)
    const minCount = () =>
        setCaseArr((state) => (state - 1 >= 1 ? state - 1 : state))

    const handleBuy = async () => {
        setLoading(true)
        await buyBoosterPacks(caseArr)
        await updateData()
        setConfirmBuy(false)
        setCaseArr(10)
        setBuy(false)
    }

    const propopsal = useMemo(
        () => (
            <div className="boosterpack__block__btns">
                <ButtonFilledMin
                    text="Открыть х1"
                    onClick={() => {
                        setNumberCards(1)
                        console.log('PRESS')
                    }}
                    bg="bpack"
                />
                <ButtonFilledMin
                    text={`Открыть х${countCards}`}
                    onClick={() => {
                        setNumberCards(countCards)
                    }}
                    bg="bpack"
                />
            </div>
        ),
        [countCards]
    )

    //--------------------------------------------------------------------BUY BOOSTERPACKS-----------------------------------------
    const buyMenu = useMemo(
        () => (
            <div className="boosterpack__block__buymenu">
                <p className="boosterpack__block__buymenu-price">
                    Цена: {caseArr * price_buy} CRYG
                </p>
                <div className="boosterpack__block__buymenu__count">
                    <p
                        onClick={minCount}
                        style={{ textDecorationLine: 'underline' }}
                    >
                        {' '}
                        -1
                    </p>
                    <img
                        src={process.env.PUBLIC_URL + '/svg/toleft.svg'}
                        alt="left"
                        onClick={minCount}
                        width={16.5}
                        height={21}
                        style={{ cursor: 'pointer' }}
                    />
                    <p> {caseArr}</p>
                    <img
                        src={process.env.PUBLIC_URL + '/svg/toright.svg'}
                        alt="right"
                        onClick={addCount}
                        width={16.5}
                        height={21}
                        style={{ cursor: 'pointer' }}
                    />
                    <p
                        onClick={addCount}
                        style={{ textDecorationLine: 'underline' }}
                    >
                        {' '}
                        +1
                    </p>
                </div>
                <div className="boosterpack__block__buymenu__buybtns">
                    {/* <ButtonFilledMin text="Отмена" onClick={() => setBuy(false)} bg='m' /> */}
                    <ButtonFilledMin
                        text="Купить"
                        onClick={() => setConfirmBuy(true)}
                        bg="m"
                    />
                </div>
            </div>
        ),
        [caseArr]
    )

    const ShowCaseAnimation = () => {
        const [nextAnimationReady, setNextAnimationReady] = useState(false)
        useEffect(() => {
            setTimeout(() => {
                setNextAnimationReady(true)
            }, 6000)
        }, [])

        if (!nextAnimationReady) {
            const helpObj = {
                'G rang': 1,
                'C rang': 2,
                'B rang': 3,
                'A rang': 4,
                'S rang': 5,
            }
            const token = prizeArr.reduce((acc, prev) => {
                if (helpObj[prev.tokenName] > helpObj[acc.tokenName])
                    return prev
                return acc
            })
            return (
                <div className="boosterpack__case-div">
                    <img
                        className="boosterpack__case-cap"
                        src="/img/case_cap.png"
                        alt="casecap"
                    />
                    <img
                        className="boosterpack__case-opened"
                        src="/img/case_opened.png"
                        alt="case"
                    />
                    <img
                        className="boosterpack__case-light"
                        src={`/img/case_light_${
                            token.tokenName?.split(' ')[0]
                        }.png`}
                        alt="case_light"
                    />
                    <img
                        className="boosterpack__case-token"
                        src={
                            process.env.PUBLIC_URL +
                            `/img/token ${token.tokenName?.split(' ')[0]}.png`
                        }
                        alt="card"
                    />
                    <audio
                        src="/media/case_open.mp3"
                        autoPlay
                        className="boosterpack__audio"
                    ></audio>
                </div>
            )
        }
        return (
            <div className="card__wrap">
                {nextAnimationReady &&
                    prizeArr.map((el) => {
                        return (
                            <div key={uuid()} className="card_block">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        `/img/token ${
                                            el.tokenName?.split(' ')[0]
                                        }.png`
                                    }
                                    alt="card"
                                    onClick={() => {}}
                                />
                                <p>x{~~el.amount}</p>
                            </div>
                        )
                    })}
                <ButtonFilled
                    text={'Забрать приз'}
                    onClick={() => {
                        setPrizeArr([])
                        setNumberCards(0)
                        getBossterPackCount(boosterId)
                    }}
                />
            </div>
        )
    }

    const BuyMenu = useMemo(
        () => (
            <div className="boosterpack__wrap">
                <div className="boosterpack__balance">
                    <p>Баланс: {boosterCount || 0} pack</p>
                    <p>Баланс: {Number(amount).toFixed(2)} GRYG</p>
                </div>

                <div className="boosterpack__block">
                    <img
                        src="/img/BOX.png"
                        alt="box"
                        width={230}
                        height={213}
                    />
                    {/* {
				!buy&&
				<div className='boosterpack__block__buymenu__count'>
				<p onClick={()=>countCards>1&&setCountCards(state=>state-1)} style={{ textDecorationLine: 'underline' }}> -1</p>
				<img src={process.env.PUBLIC_URL + '/svg/toleft.svg'} alt="left" onClick={()=>countCards>2&&setCountCards(state=>state-1)} width={16.5} height={21} style={{ cursor: 'pointer' }} />
				<p> {countCards}</p>
				<img src={process.env.PUBLIC_URL + '/svg/toright.svg'} alt="right" onClick={()=>countCards<100&&setCountCards(state=>state+1)} width={16.5} height={21} style={{ cursor: 'pointer' }} />
				<p onClick={()=>countCards<100&&setCountCards(state=>state+1)} style={{ textDecorationLine: 'underline' }}> +1</p>
				</div>				
				}			 */}
                    {buy ? buyMenu : propopsal}
                    {!buy && (
                        <div className="boosterpack__block__btns-info">
                            <p>{price_open || 0} CRYG</p>
                            <p>{price_open * countCards} CRYG</p>
                        </div>
                    )}
                </div>
                {!buy && (
                    <div
                        className="boosterpack__block__buypackbtn"
                        onClick={() => setBuy(true)}
                    >
                        Купить pack
                    </div>
                )}
            </div>
        ),
        [boosterCount, amount, buy, caseArr, countCards]
    )

    //BACKEND

    const getBossterPackInfo = async () => {
        get('./boosterPack/')
            .then((data) => {
                if (data.status === 'success') {
                    dispatch(setBoosterInfoRedux(data.data.boostersPacks[0]))
                } else {
                    alert({
                        status: 'error',
                        title: 'Ошибка',
                        message:
                            data?.message ||
                            'Попробуйте обновить через 5 минут или позже',
                    })
                }
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
            })
    }

    const getBossterPackCount = async (id) => {
        console.log('bpcount')
        get(`./boosterPack/${id}`)
            .then((data) => {
                if (data.status === 'success') {
                    dispatch(setBoosterCountRedux(data.data.count))
                } else {
                    console.log('ERROR\n', data)
                    alert({
                        status: 'error',
                        title: 'Ошибка',
                        message:
                            data?.message ||
                            'Попробуйте обновить через 5 минут или позже',
                    })
                }
            })
            .catch((e) => {
                console.log('ERROR', e)
                alert({
                    status: 'error',
                    title: 'Ошибка сервера',
                    message: 'Попробуйте обновить через 5 минут или позже',
                })
            })
    }

    const buyBoosterPacks = async (quantity) => {
        post(`./boosterPack/${boosterId}`, { quantity })
            .then((data) => {
                if (data.status === 'success') {
                    alert({ status: 'success', title: data.message })
                    getBossterPackCount(boosterId)
                }
            })
            .catch((e) => {
                alert({
                    status: 'warning',
                    title: 'Внимание!',
                    message: e.response?.data?.message,
                })
                console.log('ERROR', e.response?.data?.message)
            })
    }

    const openBoosterpacks = async (quantity) => {
        put(`./boosterPack/${boosterId}`, { quantity })
            .then((data) => {
                if (data.status === 'success') {
                    setPrizeArr(data.data.drops) // СОХРАНЯЕМ ПРИЗЫ в СТЭЙТ И НАДО ЗАПУСТИТЬ АНИМАЦИЮ
                } else {
                    alert({
                        status: 'error',
                        title: 'Ошибка',
                        message:
                            data?.message ||
                            'Попробуйте обновить через 5 минут или позже',
                    })
                }
            })
            .catch((e) => {
                console.log('ERROR\n', e)
                alert({
                    status: 'warning',
                    title: 'Внимание',
                    message:
                        e?.response?.data?.message ||
                        'Попробуйте обновить через 5 минут или позже',
                })
            })
    }

    const updateData = async () => {
        setTimeout(async () => {
            setLoading(true)
            await getBossterPackInfo()
            await getBalance()
            setLoading(false)
        })
    }

    useEffect(() => {
        setTimeout(async () => {
            updateData()
        })
    }, [])

    useEffect(() => {
        setTimeout(async () => {
            boosterId && getBossterPackCount(boosterId)
        })
    }, [boosterId])

    useEffect(() => {
        if (numberCards) {
            console.log(numberCards)
            openBoosterpacks(numberCards) //открываем
        }
    }, [numberCards])

    return (
        <>
            {loading && <Loader />}
            <div className="boosterpack">
                <Header
                    text={'Boosterpack'}
                    cb={
                        numberCards || buy
                            ? () => {
                                  setNumberCards(0)
                                  setBuy(false)
                                  setPrizeArr([])
                                  getBossterPackCount(boosterId)
                              }
                            : null
                    }
                />

                {confirmBuy && (
                    <Modal
                        btns={[
                            { name: 'Подтвердить', cb: handleBuy },
                            {
                                name: 'Отменить',
                                cb: () => {
                                    setConfirmBuy(false)
                                },
                            },
                        ]}
                    >
                        <div className="booster__message">
                            <p>
                                С вашего баланса будет списанно{' '}
                                {caseArr * price_buy} CRYG
                            </p>
                        </div>
                    </Modal>
                )}
                {prizeArr?.length ? (
                    <>
                        <ShowCaseAnimation />
                        {console.log('animation')}
                    </>
                ) : (
                    BuyMenu
                )}
            </div>
        </>
    )
}

export default Boosterpack
