export default (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
        <path
            d="M30.6066 9.39214C29.215 7.99755 27.5617 6.89163 25.7415 6.13789C23.9213 5.38415 21.9701 4.99746 20 5.00001C11.7157 5.00001 5 11.7157 5 20C5 24.1422 6.67986 27.8931 9.39463 30.6079C10.7862 32.0024 12.4395 33.1084 14.2597 33.8621C16.08 34.6158 18.0311 35.0025 20.0012 35C28.2855 35 35.0012 28.2843 35.0012 20C35.0012 15.8578 33.3214 12.1069 30.6066 9.39214V9.39214ZM28.6793 28.6743C27.5409 29.8156 26.1882 30.7207 24.6989 31.3376C23.2097 31.9545 21.6132 32.271 20.0012 32.269C13.2231 32.269 7.72852 26.7744 7.72852 19.9963C7.7265 18.3843 8.04303 16.7878 8.65993 15.2985C9.27682 13.8093 10.1819 12.4566 11.3232 11.3182C12.4613 10.1769 13.8138 9.2718 15.3028 8.65489C16.7919 8.03799 18.3882 7.72147 20 7.72353C26.7769 7.72353 32.2715 13.2181 32.2715 19.995C32.2736 21.6068 31.9571 23.2031 31.3402 24.6922C30.7233 26.1813 29.8181 27.5337 28.6768 28.6718L28.6793 28.6743Z"
            fill={color}
        />
        <path
            d="M21.6531 19.966L25.6992 15.9198C25.8988 15.7022 26.0067 15.416 26.0003 15.1208C25.9939 14.8257 25.8737 14.5444 25.6649 14.3357C25.4561 14.127 25.1747 14.007 24.8795 14.0008C24.5843 13.9946 24.2982 14.1026 24.0808 14.3024L24.0818 14.3013L20.0356 18.3475L15.9894 14.3013C15.7719 14.1017 15.4857 13.9939 15.1905 14.0003C14.8953 14.0067 14.614 14.1268 14.4053 14.3357C14.1966 14.5445 14.0767 14.8259 14.0705 15.121C14.0643 15.4162 14.1723 15.7024 14.372 15.9198L14.371 15.9187L18.4172 19.9649L14.371 24.0111C14.2571 24.1155 14.1656 24.2419 14.1019 24.3827C14.0382 24.5234 14.0036 24.6756 14.0003 24.8301C13.9969 24.9845 14.0249 25.1381 14.0824 25.2814C14.14 25.4248 14.2259 25.5551 14.3351 25.6643C14.4443 25.7736 14.5745 25.8597 14.7179 25.9173C14.8612 25.975 15.0147 26.003 15.1692 25.9997C15.3236 25.9965 15.4759 25.962 15.6166 25.8984C15.7574 25.8348 15.8839 25.7433 15.9884 25.6296L15.9894 25.6285L20.0356 21.5823L24.0818 25.6285C24.1863 25.7424 24.3127 25.8339 24.4534 25.8976C24.5941 25.9613 24.7463 25.9959 24.9008 25.9992C25.0552 26.0026 25.2088 25.9746 25.3522 25.9171C25.4955 25.8595 25.6258 25.7736 25.7351 25.6644C25.8443 25.5552 25.9304 25.425 25.988 25.2816C26.0457 25.1383 26.0737 24.9848 26.0705 24.8303C26.0672 24.6759 26.0327 24.5236 25.9691 24.3829C25.9055 24.2421 25.8141 24.1156 25.7003 24.0111L25.6992 24.01L21.6531 19.966Z"
            fill={color}
        />
    </svg>
)
