export const StarWrapper = ({ starAmmouth, styles = {} }) => {
    return (
        <>
            {new Array(starAmmouth).fill(1).map((el) => (
                <div style={styles}>
                    <img src="/img/star_modal.png" alt="star" />
                </div>
            ))}
        </>
    )
}
