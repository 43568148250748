import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import List from '../../components/List'
import { useGet } from '../../api/request'
import './styles.css'

const AccrualHistory = () => {
    const user = useSelector((state) => state.auth.user)
    const [info, setInfo] = useState({})
    const [listAccrual, setListAccrual] = useState([])
    const [limit, setLimit] = useState(20)
    const get = useGet()
    const listRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            fetchHistory()
        })

        const scrollView = () => {
            if (
                document.documentElement.scrollTop +
                    document.documentElement.clientHeight +
                    10 >=
                document.documentElement.scrollHeight
            ) {
                setLimit((state) => state + 9)
                console.log('END', limit)
            }
        }
        window.addEventListener('scroll', scrollView)
        return () => {
            window.removeEventListener('scroll', scrollView)
        }
    }, [])

    useEffect(() => {
        console.log('LIMIT', limit)
        fetchHistory()
    }, [limit])

    const fetchHistory = () => {
        get(`users/referralHistory?user_id=${user.id}&limit=${limit}`)
            .then((response) => {
                setInfo({
                    count: response.data.count,
                    allCount: response.data.allCount,
                })
                setListAccrual(
                    response.data.history.map((item) => {
                        return {
                            left: `#${item.from_user_login}`,
                            center: `${item.amount} GRYG`,
                            right: `${new Date(
                                item.date_create
                            ).toLocaleTimeString()} ${new Date(
                                item.date_create
                            ).toLocaleDateString()}`,
                        }
                    })
                )
            })
            .catch(console.log)
    }

    return (
        <div className="accrual-history">
            <Header text="История начислений" />
            <div className="accrual-history__wrap-list" ref={listRef}>
                <List data={listAccrual} />
            </div>
        </div>
    )
}

export default AccrualHistory
