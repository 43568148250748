import { useEffect, useState } from 'react'
import ButtonFilledMin from '../ButtonFilledMin'
import { v4 as uuid } from 'uuid'
import './styles.css'

export default function Modal({
    children,
    showCb,
    blackClick = false,
    closeBtn = false,
    btns = false,
    z = 3,
    style = {},
    bspan = '',
}) {
    const blackClose = (event) =>
        event.target.classList[0] === 'modal__black' && showCb(false)
    const showBtns = () => {
        if (btns.length) {
            return btns.map((btn) => (
                <ButtonFilledMin
                    key={uuid()}
                    text={btn.name}
                    onClick={btn.cb}
                    zIndex={z + 3}
                    {...{ disabled: btn.disabled }}
                    bg="m"
                />
            ))
        }
    }
    return (
        <>
            <div
                className="modal"
                onClick={() => blackClick && blackClose()}
                style={{ zIndex: z }}
            >
                <div className="modal__black" />
                <div className="modal__message" {...{ style }}>
                    {closeBtn && (
                        <button
                            className="modal__closebtn"
                            onClick={() => showCb(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                            >
                                <path
                                    d="M10.3017 9.03539L5.48029 4.21395L5.48039 4.21385L5.47549 4.20936C5.32847 4.07483 5.13522 4.0022 4.936 4.00662C4.73677 4.01103 4.54692 4.09214 4.40601 4.23305C4.2651 4.37396 4.18399 4.56381 4.17958 4.76304C4.17516 4.96227 4.24778 5.15552 4.38231 5.30253L4.38221 5.30263L4.38688 5.30731L9.20587 10.1287L4.38688 14.9477C4.31286 15.0186 4.25365 15.1035 4.21271 15.1975L4.35001 15.2573L4.21271 15.1975C4.17151 15.292 4.14963 15.3939 4.14835 15.497C4.14708 15.6001 4.16643 15.7024 4.20527 15.798C4.24411 15.8935 4.30166 15.9803 4.37453 16.0533C4.44741 16.1263 4.53413 16.1839 4.62961 16.2229L4.6863 16.084L4.62961 16.2229C4.72509 16.2619 4.82741 16.2814 4.93053 16.2803C5.03365 16.2791 5.13551 16.2574 5.23012 16.2163C5.32417 16.1755 5.40916 16.1164 5.48016 16.0425L10.3017 11.2234L15.1232 16.0448L15.1231 16.0449L15.128 16.0494C15.275 16.184 15.4682 16.2566 15.6675 16.2522C15.8667 16.2478 16.0565 16.1666 16.1974 16.0257C16.3383 15.8848 16.4195 15.695 16.4239 15.4957C16.4283 15.2965 16.3557 15.1033 16.2211 14.9563L16.2212 14.9562L16.2165 14.9515L11.3951 10.13L16.2153 5.30853C16.2894 5.23765 16.3486 5.15277 16.3895 5.0588C16.4307 4.96426 16.4526 4.86243 16.4538 4.75931C16.4551 4.65619 16.4358 4.55385 16.3969 4.45831C16.3581 4.36278 16.3005 4.27597 16.2277 4.20299L16.1215 4.30898L16.2277 4.20299C16.1548 4.13001 16.0681 4.07234 15.9726 4.03337L15.916 4.17204L15.9726 4.03337C15.8771 3.99439 15.7748 3.97489 15.6717 3.97602C15.5685 3.97715 15.4667 3.99889 15.3721 4.03995C15.2775 4.08101 15.192 4.14057 15.1208 4.21513L15.095 4.24213L10.3017 9.03539Z"
                                    fill="white"
                                    stroke="white"
                                    stroke-width="0.3"
                                />
                            </svg>
                        </button>
                    )}
                    {children}
                </div>
                <div className="modal__btns-block">{showBtns()}</div>
                <div className="modal__bottomspan">{bspan}</div>
            </div>
        </>
    )
}
