import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setRememberData, setTokens, setUser } from '../../redux/auth'
import TitleAuth from '../../components/TitleAuth'
import Input from '../../components/Input'
import CheckBox from '../../components/CheckBox'
import ButtonFilled from '../../components/ButtonFilled'
import Link from '../../components/Link'
import Loader from '../../components/Loader'
import { post } from '../../api/request'
import './styles.css'
import { useAlert } from '../../components/AlertNotification'

const SignIn = () => {
    const rememberData = useSelector((state) => state.auth.rememberData)
    const [isLoading, setIsLoading] = useState(false)
    const [login, setLogin] = useState(rememberData?.login)
    const [password, setPassword] = useState(rememberData?.password)
    const [isViewPassword, setIsViewPassword] = useState(false)
    const [remember, setRemember] = useState(false)
    const [errors, setErrors] = useState({
        login: '',
        password: '',
        validation: '',
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const alert = useAlert()

    const validation = () => {
        let valid = true
        setErrors({})
        if (!login) {
            setErrors((prev) => ({
                ...prev,
                login: 'Неверный логин',
            }))
            valid = false
        }
        if (!password) {
            setErrors((prev) => ({
                ...prev,
                password: 'Неверный пароль',
            }))
            valid = false
        }
        return valid
    }

    const handleChangeLogin = (evt) => {
        setLogin(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            login: '',
        }))
    }

    const handleChangePassword = (evt) => {
        setPassword(evt.target.value)
        setErrors((prev) => ({
            ...prev,
            password: '',
        }))
    }

    const submit = () => {
        if (!validation()) return
        setIsLoading(true)

        if (remember) {
            dispatch(setRememberData({ login, password }))
        } else {
            dispatch(setRememberData({ login: '', password: '' }))
        }

        const requestData = {
            login,
            password,
        }

        post('auth/login', requestData)
            .then((response) => {
                if (!Object.keys(response.data).length) {
                    alert({
                        status: 'error',
                        title: 'Ошибка сервера',
                        message: 'Попробуйте еще раз через 5 минут или позже',
                    })
                    return
                }
                dispatch(
                    setTokens({
                        accessToken: response.data.user.access_token,
                        refreshToken: response.data.user.refresh_token,
                    })
                )
                dispatch(setUser(response.data.user))
                navigate('/main')
            })
            .catch((e) => {
                console.log('ERROR :', e)

                if (e.code === 'ERR_NETWORK') {
                    alert({
                        status: 'error',
                        title: 'Отсутствует соединение c сервером',
                        message: 'Попробуйте еще раз через 5 минут или позже',
                    })
                }

                if (
                    e.response.data.status === 'fail' &&
                    !e.response.data.validation
                ) {
                    console.log('errors', !!Object.values(errors).join(), e)
                    alert({
                        status: 'error',
                        title: 'Ошибка сервера',
                    })
                }

                setErrors((prev) => ({
                    ...prev,
                    ...(e.response.data?.validation || {}),
                }))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (errors.verification) {
            alert({
                status: 'warning',
                title: 'Аккаунт не верифицирован',
                message:
                    'Вам выслано письмо с верификацией, перейдите по ссылке',
            })
            setErrors({})
        }
    }, [errors])

    return (
        <div className="sign-in">
            <div className="sign-in__wrap-title">
                <TitleAuth text="Вход в аккаунт" />
            </div>

            <div className="sign-in__wrap-input">
                <Input
                    value={login}
                    onChange={handleChangeLogin}
                    leftIcon="profile"
                    placeholder="Логин"
                    error={errors.login}
                />
            </div>
            <div className="sign-in__wrap-input">
                <Input
                    value={password}
                    onChange={handleChangePassword}
                    leftIcon="lock"
                    rightIcon={isViewPassword ? 'eye' : 'eye-lock'}
                    onClickRight={() => setIsViewPassword((prev) => !prev)}
                    type={isViewPassword ? 'text' : 'password'}
                    placeholder="Пароль"
                    error={errors.password}
                />
            </div>
            <div className="sign-in__wrap-checkbox">
                <CheckBox
                    text="Запомнить"
                    value={remember}
                    onChange={setRemember}
                />
            </div>
            <div className="sign-in__wrap-btn">
                <ButtonFilled text="Войти" onClick={submit} />
            </div>
            <div className="sign-in__wrap-recovery">
                <Link
                    text="Забыли пароль?"
                    to="/auth/password-recovery"
                    translate="no"
                />
            </div>
            <div className="sign-in__wrap-recovery">
                <Link
                    text="Не пришло письмо для входа??"
                    to="/auth/signup_verification-repeat"
                    translate="no"
                />
            </div>
            <div className="sign-in__wrap-sign-up">
                <p className="sign-in__sign-up">
                    У вас нет аккаунта?{' '}
                    <Link text="Зарегистрироваться" to="/auth/sign-up" />
                </p>
            </div>
            <p
                className="agreement"
                onClick={() => navigate('/auth/agreement')}
                style={{ cursor: 'pointer' }}
            >
                Пользовательское соглашение
            </p>

            {isLoading && <Loader />}
        </div>
    )
}

export default SignIn
