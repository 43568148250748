import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import ReferralsProfileBar from '../../components/ReferralsProfileBar'
import ButtonReferral from '../../components/ButtonReferral'
import ButtonShare from '../../components/ButtonShare'
import ButtonFilledMin from '../../components/ButtonFilledMin'
import { useAlert } from '../../components/AlertNotification'
import { useGet } from '../../api/request'
import './styles.css'
import Modal from '../../components/Modal/modal'
import ShareButton from '../../components/ShareButton/ShareButton'

const Referrals = () => {
    const user = useSelector((state) => state.auth.user)
    const [referalInfo, setReferalInfo] = useState({})
    const [referalLines, setReferalLines] = useState([])
    const [showShareModal, setShowShareModal] = useState(false)
    const navigate = useNavigate()
    const link = `${window.location.origin}/auth/sign-up/${user?.referrals_link}`
    const alert = useAlert()
    const get = useGet()

    useEffect(() => {
        setTimeout(async () => {
            fetchReferalInfo()
            fetchReferalLines()
        })
    }, [])

    const fetchReferalInfo = () => {
        get('users/referralInfo')
            .then((response) => {
                setReferalInfo(response.data)
            })
            .catch(console.log)
    }

    const fetchReferalLines = () => {
        get('users/referral')
            .then((response) => {
                setReferalLines(response.data.lines)
            })
            .catch(console.log)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(link)
        alert({
            status: 'success',
            title: 'Скопировано',
            message: 'Адресс скопирован',
        })
    }

    const handleShare = () => {
        setShowShareModal(true)
    }

    const closeShareModal = () => {
        setShowShareModal(false)
    }

    return (
        <div className="referrals">
            <Header text="Referrals" />
            <div className="referrals__wrap-referrals-profile-bar">
                <ReferralsProfileBar />
            </div>
            <div className="referrals__wrap">
                <p className="referrals__title">Реферальная ссылка</p>
                <div className="referrals__wrap-link">
                    <div className="referrals__wrap-link-copy">
                        <ButtonReferral text={link} onClick={handleCopy} />
                    </div>
                    <div className="referrals__wrap-link-share">
                        <ButtonShare onClick={handleShare} />
                    </div>
                </div>
            </div>
            <div className="referrals__wrap">
                <p className="referrals__title">Структура</p>
                <div className="referrals__info">
                    <div className="referrals__info-row">
                        <p className="referrals__info-label">
                            Количество приглашенных:
                        </p>
                        <p className="referrals__info-text">
                            {referalInfo.commonInvited || 0} человек
                        </p>
                    </div>
                    <div className="referrals__info-row">
                        <p className="referrals__info-label">Заработано:</p>
                        <p className="referrals__info-text">
                            {referalInfo.commonEarned || 0} CRYG
                        </p>
                    </div>
                </div>
                <div className="referrals__line" />
                <div className="referrals__lines">
                    {referalLines.map((lineId) => (
                        <div key={lineId} className="referrals__wrap-btn">
                            <ButtonFilledMin
                                text={`${lineId} линия`}
                                onClick={() =>
                                    navigate(`/referrals-line/${lineId}`)
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className="referrals__wrap-history">
                    <RouterLink
                        className="referrals__history"
                        to="/accrual-history"
                    >
                        История начислений
                    </RouterLink>
                </div>
            </div>
            {showShareModal && (
                <Modal
                    blackClick={true}
                    showCb={closeShareModal}
                    closeBtn={true}
                >
                    <p className="sharebuttons__label"> Поделиться</p>
                    <div className="sharebuttons__wrapper">
                        <ShareButton src="sms" text={'SMS'} />
                        <ShareButton src="tg" text={'Telegram'} />
                        <ShareButton src="tw" text={'Twitter'} />
                        <ShareButton src="wu" text={'Whatsapp'} />
                        <ShareButton src="e" text={'E-mail'} last={true} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Referrals
