import './styles.css'

export default function Stats({
    power = 0,
    perday = 0,
    extracted = 0,
    active = false,
}) {
    return (
        <div className="stats">
            <div className="stats__wrap">
                <p className="stats__text">Мощность фермы</p>
                <div className="stats__value-wrap">
                    {active && (
                        <>
                            <img
                                src={process.env.PUBLIC_URL + '/svg/light.svg'}
                                alt="light"
                            />
                            <p className="stats__text-value">{power}</p>
                        </>
                    )}
                </div>
            </div>
            <div className="stats__wrap">
                <p className="stats__text"> В сутки</p>
                <div className="stats__value-wrap">
                    {active && (
                        <>
                            <img
                                src={
                                    process.env.PUBLIC_URL + '/svg/percent.svg'
                                }
                                alt="percent"
                            />
                            <p className="stats__text-value">
                                {perday?.toFixed(2)}
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="stats__wrap" style={{ display: 'none' }}>
                <p className="stats__text"> Добыто</p>
                {active && (
                    <>
                        <p className="stats__text-value">GRYG {extracted}</p>
                    </>
                )}
            </div>
        </div>
    )
}

const BoosterStats = ({ collection, main, powerAll, percent }) => {
    return (
        <div className="stats">
            <div className="stats__wrap">
                <p className="stats__text">Основа</p>
                <div className="stats__value-wrap">
                    <img
                        src={process.env.PUBLIC_URL + '/svg/light.svg'}
                        alt="light"
                    />
                    <p className="stats__text-value">{main || 0}</p>
                </div>
            </div>
            <div className="stats__wrap">
                <p className="stats__text"> Коллекция</p>
                <div className="stats__value-wrap">
                    <img
                        src={process.env.PUBLIC_URL + '/svg/light.svg'}
                        alt="light"
                    />
                    <p className="stats__text-value">{collection || 0}</p>
                </div>
            </div>
            <div className="stats__wrap">
                <p className="stats__text"> Мощность фермы</p>
                <div className="stats__value-wrap">
                    <img
                        src={process.env.PUBLIC_URL + '/svg/light.svg'}
                        alt="light"
                    />
                    <p className="stats__text-value">{powerAll || 0}</p>
                </div>
            </div>
            <div className="stats__wrap">
                <p className="stats__text"> В сутки</p>
                <div className="stats__value-wrap">
                    <p className="stats__text-value">% {percent || 0}</p>
                </div>
            </div>
        </div>
    )
}

export { BoosterStats }
