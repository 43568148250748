export default (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="170"
        height="47"
        viewBox="0 0 170 47"
        fill="none"
    >
        <g filter="url(#filter0_d_2019_5342)">
            <path
                d="M0 6.79785C0 3.48414 2.68629 0.797852 6 0.797852H143.744C147.058 0.797852 149.744 3.48414 149.744 6.79785V26.2585C149.744 29.5722 147.058 32.2585 143.744 32.2585H5.99999C2.68628 32.2585 0 29.5722 0 26.2585V6.79785Z"
                fill="url(#paint0_linear_2019_5342)"
                shape-rendering="crispEdges"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2019_5342"
                x="0"
                y="0.797852"
                width="169.744"
                height="51.4604"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="10" dy="10" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2019_5342"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2019_5342"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_2019_5342"
                x1="175.269"
                y1="36.7529"
                x2="-123.726"
                y2="-100.862"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#EB3761" />
                <stop offset="1" stop-color="#AA0028" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
)
