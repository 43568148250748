import React from 'react'
import './styles.css'
import { v4 as uuid } from 'uuid'

const Rang = ({
    rangS,
    showRang = false,
    stars = 0,
    power = false,
    small = false,
    onClick,
    icoSize = 126,
    price = null,
    cursor,
    main = false,
    isSelling = false,
}) => {
    const showStar = (num) =>
        new Array(num).fill('').map(() => (
            <div key={uuid()} className="rang__star">
                <img
                    src={process.env.PUBLIC_URL + '/svg/star.svg'}
                    alt="star"
                    width={small ? 12 : 20}
                    height={small ? 13 : 20}
                    style={{ margin: small ? 2 : 5 }}
                />
            </div>
        ))
    return (
        <div
            className="rang"
            onClick={onClick}
            style={
                small
                    ? {
                          height: 100,
                          padding: '0',
                          margin: 0,
                          cursor,
                          width: '100px',
                      }
                    : {}
            }
        >
            {rangS ? (
                <>
                    <img
                        src={process.env.PUBLIC_URL + `/img/rang_${rangS}.png`}
                        alt="rang"
                        width={small ? 65 : icoSize}
                        height={small ? 65 : icoSize}
                        style={
                            small
                                ? { 'margin-top': '10px', cursor: 'pointer' }
                                : {}
                        }
                    />
                    {showRang && (
                        <p
                            className="rang__text"
                            style={small ? { fontSize: 14 } : {}}
                        >
                            rang {rangS.toUpperCase()}
                        </p>
                    )}
                </>
            ) : small ? (
                <img
                    src="/img/empty_booster.png"
                    alt="emptybooster"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {}}
                />
            ) : (
                <div className="rang__start">
                    <button className="rang__startbutton">
                        <div className="rang__img-wrapper">
                            <img src="/img/Round.png" alt="round" />
                            <img src="/img/Cross.png" alt="cross" />
                        </div>
                        <h5>x2</h5>
                    </button>
                </div>
            )}
            {price && (
                <p
                    className="rang__text"
                    style={{ fontSize: 14, marginTop: 12 }}
                >
                    {price} {rangS.toUpperCase()}RT
                </p>
            )}
            {power && (
                <div className="rang__power">
                    <img
                        src={process.env.PUBLIC_URL + '/svg/light.svg'}
                        alt="light"
                    />
                    <p className="rang__power-text">{power.count}</p>
                    {main && <p>x{power.x}</p>}
                </div>
            )}
            <div
                className="rang__stars"
                style={small ? { paddingBottom: 10 } : {}}
            >
                {rangS && showStar(stars)}
            </div>
            {isSelling &&
                (small ? (
                    <div className="rang__sale">
                        <img className="sale" src="/img/Sale.png" alt="sale" />
                        <div className="sale__text">Sale</div>
                    </div>
                ) : (
                    <div className="rang__sale">
                        <img
                            className="sale"
                            src="/img/Sale_big.png"
                            alt="sale"
                        />
                        <div className="sale__text-big">Sale</div>
                    </div>
                ))}
        </div>
    )
}
export default Rang
