import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import './styles.css'

const LinkText = ({ text, style, ...props }) => (
    <RouterLink className="link-text" {...props} style={style}>
        {text}
    </RouterLink>
)

export default LinkText
